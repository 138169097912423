import React from 'react';
import '../../css/fulldiv.css'
import AdminDashboardClass from '../../hooks/AdminDashboardClass'


class AllApplicants extends AdminDashboardClass {
    // constructor(props) {
    //     super(props);
    //     this.state={
    //         pages:{

    //         }
    //     }

    // }

    render() {
        // this.paginateData()
        // this.setState({paginated:this.allPages})
        // console.log("history",this.props.history,this.props)
        const {filteredApplicants, loading} = this.props
        console.log("applicantsFilter", filteredApplicants)
        // const paginatedApplicants = JSON.parse(sessionStorage.getItem("paginatedApplicants"))
        // const header = ["Id","First Name", "Last Name", "Phone Number", "Gender", "App. Status", "Age Range"]
        const isLoading = () => {
            if (loading) {
                return (
                    <div>
                        <h6>Loading data</h6>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>

                )
            }
        }
        return (
            <div className=" appH">
                {/* <TablePagination 
                    className="trContent"
                    headers={ header }
                    data={ datum }
                    columns="id.firstName.lastName.phone.gender.applicationStatus.ageRange"
                    perPageItemCount={ 3 }
                    totalCount={ datum.length }
                    // arrayOption={ [["size", 'all', ' ']] }
                /> */}

                <table id="tabular" className="table bg-white  table-hover">
                    <thead style={{color: "rgba(43, 28, 28, 0.5)"}} className="bg-white">
                    <tr>
                        <th>Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone Number</th>
                        <th>Gender</th>
                        <th>App.Status</th>
                        <th>Age Range</th>
                    </tr>
                    </thead>
                    {isLoading()}
                    {this.renderAllApplicants(filteredApplicants)}

                </table>
            </div>
        );
    }
}

export default AllApplicants;

// const AllApplicants= ({datum,history})=> {
//     const styling = {
//         // paddingRight:"70px"
//         minWidth:"100px",
//         maxWidth:"200px"
//     }
//     console.log("history",this.props)
//         return (
//             <div  className=" appH">

//             </div>
//         );
// };

// export default AllApplicants;