import React, {Component} from 'react';
import axios from "axios";
import * as EmailValidator from 'email-validator'
import {ELDER_URL, loginUserAPI, wakeUpBackendServer} from "../components/routes/Api's";
import {submitAnyPreviousTest} from "./FunctionalHooks";
// import Redirect from "react-router/modules/Redirect";

const jwtDecode = require('jwt-decode')


class LoginHook extends Component {
    authData;

    constructor(props) {
        super(props);
        this.state = {
            isEnabled: true,
            isAuth: false,
            user: {},
            headers: {
                ContentType: "application/json",
                Authorization: ""
            },
            email: "",
            password: "",
        }
    }

    controlFlag = {
        showDashboard: true,
        showForm: false,
        showTest: false,
    }

    changeEvent = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    // disableButtonPress = () => {
    //     this.btn.setAttribute("disabled", "disabled");
    // };
    //
    // enableButtonPress = () => {
    //     this.btn.removeAttribute("disabled");
    //
    // };

    componentDidMount() {
        this.wakeUpServer();
    }

    wakeUpServer = () => {
        axios.get(wakeUpBackendServer);
    }

    mailValidation = () => {
        const mailErr = document.querySelector("#mailErr");
        const passwordErr = document.querySelector("#passwordErr");
        if (!EmailValidator.validate(this.state.email) || !this.state.email) {
            mailErr.textContent = `Please enter a valid email address`;
            this.enableButtonPress();

            function mailErrs() {
                mailErr.textContent = ""
            }

            setTimeout(mailErrs, 4000)
            return false
        } else if (!this.state.password) {
            passwordErr.textContent = "Password is empty"
            this.enableButtonPress();

            function emptyPassword() {
                passwordErr.textContent = ""
            }

            setTimeout(emptyPassword, 4000)
            return false
        } else {
            return true
        }
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.logIn();
        }
    };

    redirect = (token) => {

        window.location.href = ELDER_URL + '/redirect/' + token;

    }

    logIn = (e) => {

        const {email, password} = this.state;
        const passwordErr = document.querySelector("#passwordErr");

        passwordErr.textContent = "";
        e.preventDefault();
        this.setState({isEnabled: false})
        // this.disableButtonPress();
        if (this.mailValidation()) {
            axios.post(loginUserAPI, {
                email: email,
                password: password
            })
                .then(res => {
                    const decodeJwt = jwtDecode(res.data.accessToken)
                    const headers = {
                        ContentType: "application/json",
                        Authorization: `Bearer ${res.data.accessToken}`,
                    }
                    this.setState({user: decodeJwt.userData});
                    sessionStorage.setItem("userData", JSON.stringify(this.state.user));
                    sessionStorage.setItem("token", JSON.stringify(res.data.accessToken));
                    sessionStorage.setItem("headers", JSON.stringify(headers));

                    switch (decodeJwt.roles.toLowerCase()) {
                        case "applicant":
                            sessionStorage.setItem("userData", JSON.stringify(this.state.user));
                            submitAnyPreviousTest();
                            sessionStorage.setItem("controlFlag", JSON.stringify(this.controlFlag));
                            this.props.history.push(`/dashboard/`);
                            break;
                        case "sponsor":
                        case "elder":
                            this.setState({isAuth: true});
                            const admin = this.state;
                            sessionStorage.setItem("admin", JSON.stringify(admin));
                            this.redirect(res.data.accessToken);

                            break;
                        case "admin":
                            sessionStorage.setItem("userData", JSON.stringify(this.state.user));
                            this.props.history.push(`/systemAdmin/${this.state.user.firstName.toLowerCase()}`);
                            break;
                        default:
                            passwordErr.textContent = "Account Suspended";
                            break;


                    }

                    // if(decodeJwt.roles.toLowerCase() === 'applicant'){
                    //     sessionStorage.setItem("userData", JSON.stringify(this.state.user));
                    //     this.props.history.push(`/online/${this.state.user.firstName.toLowerCase()}`);
                    //
                    // }else{
                    //     this.setState({isAuth:true})
                    //     const admin = this.state
                    //     sessionStorage.setItem("admin", JSON.stringify(admin));
                    //     this.props.history.push(ADMIN_DASHBOARD);
                    // }

                })
                .catch(err => {
                    this.setState({isEnabled: true})
                    if (err.response === undefined) {
                        passwordErr.textContent = "Connect to the internet and try again";

                    } else if (err.response.data.message === "Password Incorrect") {
                        passwordErr.textContent = "Invalid details";


                    } else if (err.response.data.message === "Email does not exist") {
                        passwordErr.textContent = "Invalid details";

                    } else {
                        passwordErr.textContent = err.response.data.message;

                    }

                });
        }
    };
}


export default LoginHook;