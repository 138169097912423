import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    overrides: {
        typography: {
            h4: {
                // background: 'red',
            },
        },
    }
})

export default theme;