const UniversityPage = [
    {
        question: "What higher institution did you attend?",
        type: "text",
        options: null,
        response: null
    }, {
        question: "Course Studied",
        type: "Text",
        options: null,
        response: null
    }, {
        question: "Year of Graduation",
        type: "drop down",
        options: [],
        response: null
    }, {
        question: "Graduation Grade",
        type: "drop down",
        options: ["First Class", "Second Class Upper", "Second Class Lower", "Third Class",
            "Distinction", "Upper Credit", "Lower Credit", "Pass"
        ],
        response: null
    }, {
        question: "NYSC status",
        type: "drop down",
        options: ["Completed", "Ongoing", "Exempted", "Not Applicable"],
        response: null
    }

]
const currentYear = new Date().getFullYear()
for (let year = currentYear + 5; year > 1995; year--) {
    UniversityPage[2].options.push(year.toString())
}
export default UniversityPage