import React, {Component} from 'react';
import '../../css/modal.css'
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import {enableLoanFormFor, stopTestAPI, UndoApplicantTest} from "../routes/Api's";
import {toast, ToastContainer} from "react-toastify";

class multiButtonCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            table: [],
            kind:"",
        };
    };

    enableLoanForm = () => {

        axios.patch(enableLoanFormFor(this.state.email), "", {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success("Loan Form Enabled successfully")
            toast.success(res.data.message);
        })

            .catch(err => {
                toast.error('Loan form enabling failed')
                toast.error(err.response.data.message)
            })
        ;

    };
    purgeUser = () => {

        axios.delete(stopTestAPI(this.state.email), {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success('Test stopped Successfully')
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error('Test stop Error')
                toast.error(err.response.data.message)
            })
        ;

    };
    undoTest = () => {
        axios.post(UndoApplicantTest(this.state.email), "", {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success('Applicant can retake test')
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error('Undo test failed')
                toast.error(err.response.data.message)
            })
        ;

    };

    render() {


        return (

            <div className="col-xl-3 col-md-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Applicant Related Object</h6>
                    </div>
                    <div className="card-body">

                        <div className="form-group mt-3">

                            <TextField onChange={this.setOnChange}
                                       margin="normal" fullWidth type="text" label="Email "
                                       variant="outlined" name='email'
                                       color={"secondary"} className="newField" required
                            />

                        </div>
                        <button type="submit" className="btn w-30  p-2 bt border-dark "
                                onClick={this.enableLoanForm}>Enable Loan
                        </button>
                        <button type="submit" className="btn w-30  p-2 bt border-dark" onClick={this.purgeUser}>Stop
                            Test
                        </button>
                        <button type="submit" className="btn w-30 p-2 bt border-dark" onClick={this.undoTest}>Undo Test</button>
                        <ToastContainer/>

                    </div>
                </div>


            </div>

        );
    };

    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
}

export default multiButtonCard;
