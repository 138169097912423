import React, {Component} from 'react';
import SplashHeader from "./SplashHeader";
import '../../../css/splash.css'
import {ReactComponent as Thumb} from '../../../images/thumbs-up-black.svg'

class InviteSuccessful extends Component {

    render() {
        return (
            <div className="bac vh-100">
                <SplashHeader/>
                <div className="container  d-flex justify-content-around align-items-center pa3 mt-md-5 ">
                    <div className="box align-self-md-start  col-md-4 br4  border border-danger">
                        <div className=" col-12 col-md-12 d-flex ">
                            <div className=" thumb ">
                                <Thumb/>
                            </div>
                        </div>

                        <div className="text-area">
                            <h1 className="submit-text">InfoSession Invite Sent Successfully </h1>
                            <p className="p-text">Your invite
                                for <b>{this.props.location.state.infoSession} </b> session has been sent
                                to <b>{this.props.location.state.email}</b> successfully.
                                <br/>Kindly check your mail for ticket.
                            </p>
                            <a href="https://semicolon.africa">
                                <button className="next-button">Back to Homepage</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InviteSuccessful;