import React, {Component} from 'react';
import '../../css/modal.css'
import ButtonCard from "./ButtonCard";
import axios from "axios";
import {createBatch, startApplicationsAPI, stopApplicationsAPI} from "../routes/Api's";
import {toast} from "react-toastify";


class ButtonCardsArray extends Component {
    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.applyForm();
        }
    };

    disableButtonPress = () => {
        this.btn.setAttribute("disabled", "disabled");
    };
    enableButtonPress = () => {
        this.btn.removeAttribute("disabled");

    };
    action1 = () => {
        axios.post(startApplicationsAPI, "", {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success('Successfully started application')
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error('upload fail')
                toast.error(err.data)
            })
        ;

    };
    action2 = () => {
        axios.post(stopApplicationsAPI, "", {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success('Successfully stopped application')
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error('upload fail')
                toast.error(err.data)
            })
        ;

    };
    action3 = () => {
        axios.post(createBatch, "", {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success('Successfully Created new Batch')
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error('Batch creation failed')
                toast.error(err.data)
            })
        ;

    };


    render() {
        let buttonOneHeading = "Start Application";
        let buttonTwoHeading = "Stop Application";
        let buttonThreeHeading = "Start New Batch";
        let redButton = "btn btn-danger"
        let blueButton = "btn btn-primary"
        let greenButton = "btn btn-success"


        return (
            <div className="card border-left-primary mt-5 pt-5 shadow h-100 py-2">
                <div className="card-body">

                    <div className="container-fluid">

                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        </div>


                        <div className="row">
                            <ButtonCard heading={buttonOneHeading} buttonStyle={greenButton}
                                        buttonAction={this.action1}/>
                            <ButtonCard heading={buttonTwoHeading} buttonStyle={redButton} buttonAction={this.action2}/>
                            <ButtonCard heading={buttonThreeHeading} buttonStyle={blueButton}
                                        buttonAction={this.action3}/>

                        </div>
                        <div className="form-group">

                        </div>
                    </div>
                </div>
            </div>


        );
    };
}


export default ButtonCardsArray;
