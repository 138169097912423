
import React, {Component} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import '../../../../css/forms.css'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import firstPage from "../formData/firstPage";

class Form1 extends Component {

    render() {
        const {
            handleChange,

            nationality,
            stateOfOrigin,
            countryOfResidence,
            stateOfResidence,
            whereWorkCurrently,
            dateOfBirth,
            maritalStatus,
            relocateForProgram,
            commitToProgram,
            formData,
            isNationalityNigeria,
            nyscStatus,
            iAmCurrently,
            isCountryOfResidenceNigeria
        } = this.props


        return (
            <form onSubmit={this.onNext} className="row w-100">
                <div className="col-md-6">
                    <Autocomplete
                        id="firstField"
                        autoComplete={false}
                        // freeSolo
                        value={nationality}
                        autoHighlight={true}
                        autoSelect={true}
                       // onInputChange={handleChange('nationality')}
                        onChange={handleChange('nationality')}
                        options={formData.countries}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label="Nationality" margin="normal" variant="outlined"
                                       color="secondary"
                                       name="nationality"

                                       required={true}
                                                   autoComplete='new-password'
                            />
                        )}
                    />
                    <p id='nation'></p>
                </div>
                <div className="col-md-6">
                    <Autocomplete
                        id="anotherField"
                        autoComplete={false}
                        disabled={!isNationalityNigeria}
                        autoHighlight={true}
                        autoSelect={true}
                      //  freeSolo
                      //   className="sets"
                        value={isNationalityNigeria ? stateOfOrigin : ""}
                        onChange={handleChange("stateOfOrigin")}
                        // onInputChange={handleChange('stateOfOrigin')}
                        options={formData.states}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label={nationality === "Nigeria" || nationality === null || nationality==="nigeria" ||
                            nationality === "" ? "State of origin" : "Not Applicable"}
                                       margin="normal" variant="outlined"
                                       color="secondary"     autoComplete='new-password' />


                        )}
                    />
                </div>
                <div className="col-md-6">
                    <Autocomplete
                        autoComplete={false}
                        // freeSolo
                        id="textField2"
                        autoHighlight={true}
                        autoSelect={true}

                        value={countryOfResidence}
                       // onInputChange={handleChange('countryOfResidence')}
                        onChange={handleChange("countryOfResidence")}


                        options={formData.countries}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label="Country Of Residence"
                                       margin="normal" variant="outlined"
                                       color="secondary"
                                       required
                                       autoComplete='new-password'
                            />
                        )}
                    />
                </div>
                <div className="col-md-6">
                    <Autocomplete
                        // freeSolo
                        autoComplete={false}
                        disabled={!isCountryOfResidenceNigeria}
                        autoHighlight={true}
                        autoSelect={true}

                        onChange={handleChange("stateOfResidence")}

                        value={isCountryOfResidenceNigeria ? stateOfResidence : ""}
                        options={formData.states}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params}
                                       label={countryOfResidence === "Nigeria" || countryOfResidence === "nigeria"
                                       || countryOfResidence === "" || countryOfResidence === null ? "State of residence" : "Not Applicable"}
                                       margin="normal" variant="outlined"
                                       color="secondary"
                                       autoComplete='new-password'
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 ">
                    <i className="calender "> </i>
                    <TextField
                        type="date"
                        label="Date Of Birth"
                        className=""
                        margin="normal" variant="outlined"
                        value={dateOfBirth}
                        color="secondary"
                        // defaultValue={``}
                        onChange={handleChange("dateOfBirth")}
                        inputProps={{
                            max: "2013-12-31", format: "DD MM YYYY",
                            onKeyDown: (event) => {
                                event.preventDefault();
                            },
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <TextField
                        margin="normal"
                        select
                        label={firstPage[6].question}
                        value={maritalStatus}
                        color="secondary"
                        onChange={handleChange("maritalStatus")}
                        variant="outlined"
                    >
                        {firstPage[6].options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}

                    </TextField>
                </div>




                <div className="col-md-6">
                    <TextField
                        margin="normal"
                        select
                        label={firstPage[9].question}
                        value={iAmCurrently}
                        color="secondary"
                        defaultValue={firstPage[9].options[0]}
                        onChange={handleChange("iAmCurrently")}
                        variant="outlined"
                        required
                    >
                        {firstPage[9].options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}

                    </TextField>
                </div>

                <div className="col-md-6">
                    <TextField
                        margin="normal"
                        select
                        label={firstPage[10].question}
                        value={nyscStatus}
                        color="secondary"
                        defaultValue={firstPage[10].options[0]}
                        onChange={handleChange("nyscStatus")}
                        variant="outlined"
                        required
                    >
                        {firstPage[10].options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}

                    </TextField>
                </div>







                <div className="col-md-12">
                    <TextField
                        // id="work"
                        label={firstPage[8].question}
                        color="secondary"
                        className="newText"
                        value={whereWorkCurrently}
                        onChange={handleChange('whereWorkCurrently')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>
                <div className="col-md-12 d-flex">
                    <RadioGroup className="align-self-end radioButton radioText mb-2" aria-label="gender" name="commit"
                                value={commitToProgram} onChange={handleChange("commitToProgram")}>
                        <span className="small">{firstPage[4].question}
                            <div className="alignRadio firstRad">
                            <FormControlLabel value={firstPage[4].options[0]} control={<Radio/>}
                                              label={firstPage[4].options[0]} labelPlacement={"end"}/>
                            <FormControlLabel value={firstPage[4].options[1]} control={<Radio/>}
                                              label={firstPage[4].options[1]} labelPlacement={"end"}/>
                        </div>
                        </span>

                    </RadioGroup>
                </div>

                <div className="col-md-12 d-flex">
                    {stateOfResidence === 'Lagos' && countryOfResidence === 'Nigeria' ? <input className="d-none"/> :
                        <RadioGroup className="align-self-end radioButton radioText" aria-label="gender" name="commit"
                                    value={relocateForProgram} onChange={handleChange("relocateForProgram")}>
                        <span className="small">
                            <div style={{width: "70%"}} className="-4">{firstPage[5].question}</div>
                            <div className="alignRadio secondRad mt-3">
                            <FormControlLabel value={firstPage[5].options[0]} control={<Radio/>}
                                              label={firstPage[5].options[0]} labelPlacement={"end"}/>
                            <FormControlLabel value={firstPage[5].options[1]} control={<Radio/>}
                                              label={firstPage[5].options[1]} labelPlacement={"end"}/>
                        </div>
                        </span>

                        </RadioGroup>}
                </div>

            </form>
        );
    }
}

export default Form1;