import React, {Component} from 'react';
import '../../css/range.css'
import '../../css/profile.css'


class Summary extends Component {
    render() {
        // let slider = new Slider('#ex1', {
        //     formatter: function(value) {
        //         return 'Current value: ' + value;
        //     }
        // });
        return (
            <div className="container">
                <div className="summary-first-div col-12">
                    <div className="ml-5 mt-2 text-danger pt-4">
                        <p className="mt-2 summary-head">Progress</p>
                    </div>
                    <div className="d-flex pl-4 mt-5">
                        <form>
                            <div className="form-group pr-4">
                                <label htmlFor="formControlRange" className="app-status">Application Status:
                                    Applicant</label>
                                <input className="form-control-range range-form" id="ex1 formControlRange"
                                       data-slider-id='ex1Slider' type="range" data-slider-min="0"
                                       data-slider-max="100" data-slider-step="1" data-slider-value="14"/>
                            </div>
                        </form>
                        <form>
                            <div className="form-group pl-4">
                                <label htmlFor="formControlRange" className="app-status">Tuition Paid: NGN
                                    50,000</label>
                                <input className="form-control-range range-form" id="ex1 formControlRange"
                                       data-slider-id='ex1Slider' type="range" data-slider-min="0"
                                       data-slider-max="100" data-slider-step="1" data-slider-value="14"/>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="summary-second-div mt-4 d-flex">
                    <div className="summary-second-div1 col-md-5 ">
                        <div className="col-md-12">
                            <p className="mt-5 text-danger summary-head">Bio</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-around mt-4 text-center">
                            <div>
                                <label className="my-label">First Name</label>
                                <h6 className="summary-status">Elwinscumshi</h6>
                            </div>
                            <div className="float-right">
                                <label className="my-label">Last Name</label>
                                <h6 className="summary-status">Zimbatinosy</h6>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <label className="mt-4 my-label">Email</label>
                            <h6 className="summary-status">somethingsome@semicolon.com</h6>
                        </div>
                        <div className="col-md-12 d-flex justify-content-around mt-4 text-center">
                            <div>
                                <label className="my-label">First Name</label>
                                <h6 className="summary-status">Elwinscumshi</h6>
                            </div>
                            <div className="float-right">
                                <label className="my-label">Last Name</label>
                                <h6 className="summary-status">Zimbatinosy</h6>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-1 vh-50">

                    </div>
                    <div className="summary-second-div1 col-md-5">
                        <div className="col-md-12">
                            <p className="mt-5 text-danger summary-head">More Info</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-around mt-4 text-center">
                            <div>
                                <label className="my-label">State of origin</label>
                                <h6>Elwinscumshi</h6>
                            </div>
                            <div className="float-right">
                                <label className="my-label">State of Residence</label>
                                <h6>Zimbatinosy</h6>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-around mt-4 text-center">
                            <div>
                                <label className="my-label">Higher Institution</label>
                                <h6>University of jos</h6>
                            </div>
                            <div className="float-right">
                                <label className="my-label">Course Of Study</label>
                                <h6>Medicine</h6>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-around mt-4 text-center">
                            <div>
                                <label className="my-label">University Grade</label>
                                <h6>Elwinscumshi</h6>
                            </div>
                            <div className="float-right">
                                <label className="my-label">Marital Status</label>
                                <h6>Zimbatinosy</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Summary;