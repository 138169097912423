import React, {Component} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import UniversityPage from "../formData/UniversityPage";
import '../../../../css/forms.css'
import MenuItem from "@material-ui/core/MenuItem";


class FormDoctorate extends Component {
    render() {
        const {
            doctorateInstitutionField,
            doctorateCourseField,
            doctorateGradGrade,
            doctorateGradYear,
            handleChange,
            // onData,
            formData
        } = this.props;


        const style = {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "26px",
            /* identical to box height */

            display: "flex",
            alignItems: "center",

            color: "#960C0C"
        }
        return (
            <div className="p-0 shad">
                <h5 style={style}>Doctorate</h5>
                <div>
                    <Autocomplete
                        freeSolo
                        autoSelect={true}
                        onChange={handleChange("doctorateInstitutionField")}
                        options={formData.higherInstitutions}
                        value={doctorateInstitutionField}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params}
                                       label={UniversityPage[0].question}
                                       margin="normal" variant="outlined"
                                       color="secondary"
                            />
                        )}
                    />


                    <div className="col-md-12 p-0">
                        <Autocomplete
                            freeSolo
                            autoSelect={true}
                            onChange={handleChange("doctorateCourseField")}
                            value={doctorateCourseField}
                            options={formData.courses}
                            getOptionLabel={option => option}
                            renderInput={params => (
                                <TextField {...params}
                                           label={UniversityPage[1].question}
                                           margin="normal" variant="outlined"
                                           color="secondary"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col-md-6 pl-0">
                        <TextField
                            margin="normal"
                            select
                            label={UniversityPage[3].question}
                            color="secondary"
                            value={doctorateGradGrade}
                            onChange={handleChange("doctorateGradGrade")}
                            variant="outlined"
                        >
                            {UniversityPage[3].options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}

                        </TextField>
                    </div>
                    <div className="col-md-6 pr-0">
                        <TextField
                            margin="normal"
                            select
                            label={UniversityPage[2].question}
                            color="secondary"
                            value={doctorateGradYear}
                            onChange={handleChange("doctorateGradYear")}
                            variant="outlined"
                        >
                            {UniversityPage[2].options
                                .filter(option => !(option > new Date().getFullYear() + 4))
                                .map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}

                        </TextField>
                    </div>

                </div>
            </div>
        );
    }
}

export default FormDoctorate;