import axios from 'axios'
import {stopTestAPI, submitTestAPI} from "../components/routes/Api's";

export function sendDataToDatabase(dataBank, data, apiLink) {
    const search = dataBank.includes(data);

    if (!search) axios.post(apiLink, data)
}

export function logToConsole(logText) {
    console.log(logText)
}



export function getTestObjectFromLocalStorage() {
    return JSON.parse(localStorage.getItem("userTest"))

}


export function submitAnyPreviousTest() {
    const userData = JSON.parse(sessionStorage.getItem("userData"));

    if (localStorage.getItem("userTest")) {
        attemptToResubmitTest()


    } else if (userData.applicationStatus === 'TESTING') {
        stopTest(userData.email)
    }

}

export function attemptToResubmitTest() {

    let {testObj, score, timeRemaining, email, completed} = getTestObjectFromLocalStorage();
    score = remarkTest(testObj);
    submitTestToBackendServer(score, timeRemaining, testObj, email, completed)

}

export function remarkTest(testObj) {
    let scores = 0
    testObj.userTest.forEach(data => {
        for (let [key, value] of Object.entries(testObj.response)) {
            if (data.id === key && data.answer === value) {
                scores = scores + 1
            }
        }
    })
    return scores;
}

export function submitTestToBackendServer(score, timeRemaining, testObj, email, completed) {
    const headers = JSON.parse(sessionStorage.getItem("headers"));

    axios.patch(submitTestAPI,
        {
            score,
            timeRemaining,
            testObj,
            email,
            completed
        },
        {
            headers: headers
        }
    ).then(res => {
        if (res.data.success === true) {
            stopTest(email);
            localStorage.removeItem("userTest");

        }
    })
        .catch(err => {
            saveTestToLocalStorage(score, timeRemaining, testObj, email, completed);
            handleError(err)

        });
}


export function saveTestToLocalStorage(score, timeRemaining, testObj, email, completed) {
    const userTest = {
        score,
        timeRemaining,
        testObj,
        email,
        completed

    }
    localStorage.setItem("userTest", JSON.stringify(userTest));

}

export function stopTest(email) {
    const headers = JSON.parse(sessionStorage.getItem("headers"));
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    axios.post(stopTestAPI(email), {}, {
        headers: headers
    })
        .then(res => {
            if (userData.email === email) {
                sessionStorage.setItem("userData", JSON.stringify(res.data))
            }
        })
        .catch(err => {
            handleError(err)
        })

}

//
export function handleError(err) {


    switch (err.status) {
        case 401:
        case 403:
            break;
        case 400:


            break;
        default:
            break;
    }
}

