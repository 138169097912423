import React, {Component} from 'react';
import axios from "axios";
import * as EmailValidator from 'email-validator'
// import * as PhoneValidator from 'phonevalidator'
import {registerUserAPI, wakeUpBackendServer} from "../components/routes/Api's";

class RegisterHooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phone: "",
            dialCode: "+234",
            email: "",
            gender: "",
            ageRange: "",
            tAndC: false


        };
    }


    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    handleChange = (phone, value) => {
        let state = this.state;

        state[phone] = value;

    };


    dialCodeChange = (dialCode, value) => {
        let state = this.state
        state[dialCode] = "+".concat(value);

    };
    setOnCheck = (e) => {
        this.setState({[e.target.name]: e.target.checked});
    };

    handlePhoneChange(evt) {
        const phone = (evt.target.validity.valid) ? evt.target.value : this.state.phone;

        this.setState({phone});

    }

    componentDidMount() {
        this.wakeUpServer();
    }

    wakeUpServer = () => {
        axios.get(wakeUpBackendServer);
    }

    applyValidation = () => {
        const errMessage = document.querySelector("#errMessage");
        const {firstName, lastName, phone, email, gender, ageRange, tAndC} = this.state
        const check = email === "" || firstName === "" || lastName === "" || phone === "" || gender === "" || ageRange === ""
        const isChecked = !tAndC;

        function emptyFields() {
            errMessage.textContent = ""
        }

        if (check) {
            errMessage.textContent = "Please fill in missing fields"
            // setTimeout(emptyFields, 4000)
            this.enableButtonPress();
            return false
        } else if (isChecked) {

            errMessage.textContent = "Please check the T&C box"
            this.enableButtonPress();
            // setTimeout(emptyFields, 4000)
            return false
        } else if (!EmailValidator.validate(email)) {

            errMessage.textContent = `Please enter a valid email address`;
            this.enableButtonPress();

            function wrongMail() {
                errMessage.textContent = ""
            }

            // setTimeout(wrongMail,4000)
            return false
        } else {
            errMessage.textContent = "";
            return true
        }
    };
    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.applyForm();
        }
    };


    disableButtonPress = () => {
        this.btn.setAttribute("disabled", "disabled");
    };
    enableButtonPress = () => {
        this.btn.removeAttribute("disabled");

    };

    applyForm = (e) => {
        e.preventDefault();
        this.disableButtonPress();
        const errMessage = document.querySelector("#errMessage");
        const {firstName, lastName} = this.state;
        if (this.applyValidation()) {
            // omitting dialCode from the state.
            // const newState= _.omit(this.state,'dialCode');

            axios.post(registerUserAPI, this.state)
                .then(res => {

                    if (res.status === 201) {
                        this.props.history.push(`/application_successful`);

                        this.setState(
                            {
                                firstName: "",
                                lastName: "",
                                phone: "",
                                email: "",
                                gender: "",
                                ageRange: ""
                            });
                    }
                    // else{
                    //            this.enableButtonPress();
                    //            errMessage.textContent = res.data.toString();
                    //
                    //        }
                })
                .catch(err => {
                    this.handleError(err);
                    // const error = document.querySelector("#errMessage");
                });
        }
    };
    handleError = (e) => {
        const errMessage = document.querySelector("#errMessage");
        if (e === "Network Error") {
            errMessage.textContent = "Network connection issues from your provider, try again";
            this.enableButtonPress();
            return true;
        } else if (e === "ERR_INTERNET_DISCONNECTED") {
            errMessage.textContent = "Connect to the internet and try again";
            this.enableButtonPress();
            return true;
        } else {


            if (e.response === undefined) {
                errMessage.textContent = "Connect to the internet and try again";
                this.enableButtonPress();
            } else if (e.response.data.message === "Email exist") {
                errMessage.textContent = "Email already exists";
                this.enableButtonPress();
            } else {
                errMessage.textContent = e.response.data.message;
            }


        }

    }


}

export default RegisterHooks;