import React, {Component} from 'react';
import {ReactComponent as SemLogo} from "../../images/semfulllogo.svg";
import DeskIll from "../../images/desk-illu.svg"
import '../../css/login.css'
import axios from 'axios';
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import {resetPassword} from "../routes/Api's";
import SignInButton from "./SignInButton";
import * as EmailValidator from 'email-validator'


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",

        }
    }

    changeEvent = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.processRequestPasswordRequest();
        }
    };


    disableButtonPress = () => {
        this.btn.setAttribute("disabled", "disabled");
    };


    enableButtonPress = () => {
        this.btn.removeAttribute("disabled");

    };

    mailValidation = (email) => {
        if (!EmailValidator.validate(email)) {
            const errorMessage = document.querySelector("#errorMessage");
            errorMessage.textContent = `Please enter a valid email address`;
        } else {
            return true
        }

    };


    processRequestPasswordRequest = (e) => {
        const {email} = this.state;
        e.preventDefault();
        this.disableButtonPress();

        if (this.mailValidation(email)) {
            axios.post(resetPassword(email))
                .then(res => {

                    if (res.status === 200) {
                        // alert("Application Successful");
                        this.props.history.push(`/resetPassword_successful`);
                    } else {
                        this.props.history.push(`/resetPassword_successful`);
                    }


                })
                .catch(error => {
                    if (error.response === undefined) {
                        const errMessage = document.querySelector("#errorMessage");
                        errMessage.textContent = "Connect to the internet and try again";
                        this.enableButtonPress();
                    } else {
                        this.props.history.push(`/resetPassword_successful`);
                    }
                })
            // .catch(err => {
            //     const error = document.querySelector("#errMessage");
            //
            //
            //     function emptyError() {
            //         error.textContent = ""
            //     }
            //
            //     setTimeout(emptyError, 5000)
            // });


        } else {
            this.enableButtonPress();

        }

    };

    render() {
        let {email} = this.state;

        // const  { classes } = this.props;
        return (
            <div className="form-container w-100 d-flex">

                <div className="left-form-div">
                    <div className="container d-flex justify-content-center align-items-center h-100">
                        <div className="d-flex left-content flex-column mx-auto pl-lg-5 h-100">
                            <div className="h-25 d-flex">
                                <div className="d-flex align-items-center" style={{marginBottom: 70 + 'px'}}>
                                    <SemLogo width="200px"/>
                                </div>
                            </div>
                            <div className="welmsg welreset"><h1>Reset Your</h1>
                                <h1> Password</h1>
                            </div>

                            <div className="my-form m-0">
                                <form onSubmit={this.processRequestPasswordRequest}>

                                    <TextField className="newField" type="email"
                                               name="email" value={email}
                                               onChange={this.changeEvent} label="Email Address "
                                               fullWidth variant="outlined" margin="normal"
                                               color="secondary"
                                               required
                                    />


                                    <p id="errorMessage" className="text-danger"></p>
                                    <button className="d-block w-100 p-3  bt bigbt " ref={btn => {
                                        this.btn = btn;
                                    }} onSubmit={this.processRequestPasswordRequest} onKeyPress={this.onEnterPress}>
                                        <p>Send email with reset password link</p></button>
                                    <p id="err" className="text-danger"></p>
                                </form>


                                <h2 className="forget"> Remember your password? <Link to={`/login`}
                                                                                      className="terms"> Log In </Link>
                                </h2>


                            </div>

                        </div>
                    </div>
                </div>
                <div className="right-ill-div login-right">
                    <SignInButton/>
                    <div className="form-img d-flex justify-content-center align-items-center">
                        <img alt="doorIll" src={DeskIll} width="500px"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;