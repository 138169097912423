import React from 'react'
import '../../css/fulldiv.css'
import AdminDashboardClass from '../../hooks/AdminDashboardClass'

class Pagination extends AdminDashboardClass {

    render() {
        const {eachButton, pages} = this.props
        return (
            <div id="pageDiv" className="pagination d-flex">
                <button style={{background: "none", marginRight: "20px", color: "#960C0C"}} onClick={this.handle}
                        color="red">&lt;</button>
                <button onClick={this.onPrev} style={{background: "none"}}>Back</button>
                <button id={`${this.state.button1}`} onClick={eachButton(`${this.state.button1}`)}
                        className="myBtn active">{this.state.button1}</button>
                <button id={`${this.state.button2}`} onClick={eachButton(`${this.state.button2}`)} className="myBtn"
                        href="#">{this.state.button2}</button>
                {pages > 2 ? <button id={`${this.state.button3}`} onClick={eachButton(`${this.state.button3}`)}
                                     className="myBtn">{this.state.button3}</button> : null}
                {pages > 4 ? <h5>...</h5> : null}
                {pages >= 4 ?
                    <button id={`${pages}`} onClick={eachButton(`${pages}`)} className="myBtn">{pages}</button> : null}
                <button onClick={this.onNext} style={{background: "none"}}>Next</button>
                <button style={{background: "none", marginLeft: "35px", color: "#960C0C"}}>&gt;</button>
            </div>
        )
    }
}

export default Pagination
