import React, {Component} from 'react';
import '../../../css/login.css'
import {ReactComponent as SemLogo} from "../../../images/semfulllogo.svg";
import {ReactComponent as ErrorImage} from "../../../images/error.svg";

// const useStyles = withStyles(theme => ({
//     errorImg: {
//         [theme.breakpoints.down('sm')]: {
//
//         }
//     }
// }))

class InfoSessionExpired extends Component {

    render() {
        return (
            <div className="h-100">
                <div className="container-fluid bg-white h-100">
                    <div className="ml-5 pt-3">
                        <SemLogo width="200px"/>
                    </div>


                    <div className="err-image">
                        <h4 className="mb-5">Oops, Your information session has ended please reschedule another!</h4>
                        <ErrorImage/>
                        <a href="/login">
                            <button className="btn px-4 py-2  btn-outline-danger mt-5 ">Try again</button>
                        </a>
                    </div>


                </div>
            </div>
        );
    }

}

export default InfoSessionExpired;