import {createMuiTheme} from "@material-ui/core/styles";

const myTheme = createMuiTheme(
    {
        overrides: {
            MuiInput: {
                underline: {
                    color: "red",
                    '&:hover:not($disabled):after': {
                        borderColor: "green"
                    }
                }
            }
        }
    }
);

export default myTheme