import React from 'react';

import {ReactComponent as SemLogo} from "../../images/semfulllogo.svg";
import DoorIll from "../../images/door.svg";
import '../../css/login.css'
import TextField from "@material-ui/core/TextField";
import '../Themes/MUITheme'
import RegisterHooks from "../../hooks/RegisterHooks";
import TermsConditionModal from "./TermsConditionModal";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
//import {wakeUpBackendServer} from "../routes/Api's";
import SignInButton from "./SignInButton";


class Register extends RegisterHooks {


    render() {

        const agedropdown = {
            ageRange: ['Under 16', '16-20', '21-30', '31-39', '40 and above'],

        }


        let {firstName, lastName, email, gender, ageRange} = this.state;
        return (
            <div className="form-container w-100 d-flex">
                <div className="left-form-div">
                    <div className="left-content h-90 mx-auto">
                        <div className="d-flex site-logo">
                            <SemLogo width="200px"/>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center">
                            <div className="form w-100">
                                <form onSubmit={this.applyForm} className="form" autoComplete="off">
                                    <h3 className="form-title">Become an innovator</h3>
                                    <div className="error-message">
                                        <p id={"errMessage"} className="text-danger"></p>
                                    </div>

                                    <div className="full-name row d-flex justify-content-around">
                                        <div className="col-md-6">
                                            <TextField onChange={this.setOnChange}
                                                       className="newField"
                                                       fullWidth margin="normal" type="text"
                                                       label="First Name " variant="outlined"
                                                       name='firstName' value={firstName}
                                                       color={"secondary"} required


                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField onChange={this.setOnChange}
                                                       margin="normal" fullWidth type="text" label="Last Name "
                                                       variant="outlined" name='lastName' value={lastName}
                                                       color={"secondary"} className="newField" required
                                            />
                                        </div>
                                    </div>
                                    <TextField onChange={this.setOnChange}
                                               margin="normal" fullWidth type="email" label="Email Address "
                                               variant="outlined" name='email' value={email}
                                               color={"secondary"} className="newField" required
                                    />
                                    <div className="row d-flex justify-content-around mt-4">
                                        <div className="col-sm-4 col-3">

                                            <PhoneInput
                                                inputClass="phone  dial-form"
                                                containerClass="conDial"
                                                onChange={this.dialCodeChange.bind(this, 'dialCode')}
                                                country={"ng"}
                                                name='dialCode'
                                                prefix="+"
                                                countryCodeEditable={false}
                                                value={this.state.dialCode}
                                                margin="normal"
                                                variant="outlined"
                                                className=" newField" required
                                                defaultCountry={'ng'} preferredCountries={['ng', 'us']}


                                            />
                                        </div>
                                        <div className="col-sm-8 col-9 ">
                                            <TextField
                                                margin="normal" fullWidth type="number" label="Phone number "
                                                onChange={this.setOnChange}
                                                inputProps={{
                                                    max: 999999999999999,
                                                }}
                                                variant="outlined" onInput={this.handlePhoneChange.bind(this)}
                                                pattern="[0-9]*" value={this.state.phone} name="phone"
                                                color={"secondary"} className="newField mt-0" required
                                            />
                                        </div>


                                    </div>

                                    <TextField onChange={this.setOnChange}

                                               select label="Age Range " name='ageRange'
                                               variant="outlined" value={ageRange} margin="normal"
                                               color={"secondary"} className="newField age" required
                                    >
                                        {agedropdown.ageRange.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}

                                    </TextField>


                                    <RadioGroup aria-label="gender" name="gender" value={gender}
                                                onChange={this.setOnChange} required>
                                        <label className="genders">Gender</label>
                                        <FormControlLabel value="Female" className="radioCircle" control={<Radio/>}
                                                          label="Female"/>
                                        <FormControlLabel value="Male" className="radioCircle" control={<Radio/>}
                                                          label="Male"/>

                                    </RadioGroup>


                                    <div className="col-md-12 d-flex  tick">
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.tAndC} onChange={this.setOnCheck}
                                                               name="tAndC"/>}
                                        />
                                        <label className="agree  agreelabel">
                                            I agree to the <TermsConditionModal/></label>

                                    </div>


                                    {/*<p id={"errMessage"} className="text-danger"> </p>*/}
                                    <button type="submit" className="w-100 d-block p-3 bt " ref={btn => {
                                        this.btn = btn;
                                    }} onSubmit={this.applyForm} onKeyPress={this.onEnterPress}>Apply
                                    </button>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-ill-div">
                    <SignInButton/>
                    <div className="form-img d-flex justify-content-center align-items-center">
                        <img alt="doorIll" src={DoorIll} width="500px"/>
                    </div>
                </div>

            </div>
        );
    }
}

export default Register