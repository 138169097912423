import React, {useState} from 'react';
import {useParams} from 'react-router-dom'
import '../../css/modal.css'
import '../../css/fulldiv.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {getAllInterviewComment, sendInterviewComment} from "../routes/Api's";
import axios from 'axios';
import {ReactComponent as Comment} from "../../images/CommentIcon.svg";

export default function CommentModal(props) {


    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState("")
    const [commentLists, setCommentList] = useState([]);


    const admin = JSON.parse(sessionStorage.getItem('headers'))
    const applicantEmail = useParams()


    console.log(props.state)

    const fetchComments = () => {
        axios(getAllInterviewComment(applicantEmail.id),
            {

                headers: admin
            }).then(response => {
            setCommentList(Object.values(response.data.commentList));
            // console.log(Object.values(response.data.commentList));

        }).catch(e => {
            console.log(e)
        });
    }

    // useEffect(()=>{
    //
    //     fetchComments()
    //
    // },[]);


    const handleOpen = () => {
        fetchComments()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setComment(e.target.value);
        console.log(comment);
    }

    function sendComment() {

        fetch(sendInterviewComment(applicantEmail.id, comment), {
            method: "post",

            headers: admin

        }).then(res => {
            console.log("Comment Sent Successfully", res)
            setComment(" ");
            fetchComments()
        }).catch(err => console.log("Sending... failed", err.response))

    }


    return (


        <div>
            <span onClick={handleOpen} className="add-comments font-weight-bold"><label>
                <Comment className="comment-box" />
                {/*<div className="comment-box"></div>*/}
            </label>Add/View Comments
            </span>

            <Dialog

                open={open}
                onClose={handleClose}>
                <div className="dialog-main dialog-width">

                    <div className="row-sm-12">

                        <div className=" d-flex" onClick={handleClose}>
                            <h6 className="cancelsym close ">&times;</h6>
                            <h6 className="closetext"> Close</h6>
                        </div>
                        <DialogTitle className="dialog-heading">
                            <p className="invitemsg tex">Comments</p>
                        </DialogTitle>
                        <DialogContent>

                            <p className="dialog-des">These are the comments from your colleagues. You can add
                                yours:</p>

                            <div className="row ">

                                {commentLists.map((msg, i) => <div key={i}
                                                                   className="adminid textareaComment">{msg}</div>)}

                            </div>

                            <div className="row">

                                <textarea className="adminid textareaComment " placeholder="Write a message(Optional)"
                                          value={comment} onChange={handleChange}/>
                            </div>
                            <br/><br/>
                            < div className="d-flex">
                                <button className="w-15-ns d-block  cancelterms" onClick={handleClose}>Cancel</button>
                                <button className="w-15-ns d-block  closeterms" onClick={sendComment}>Add comment
                                </button>
                            </ div>
                        </DialogContent>
                    </div>
                </div>
            </Dialog>
        </div>

    );

};
