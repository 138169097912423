import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom'
import '../../css/modal.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import HomeIcon from '../../images/home icon.svg';
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import {getInterviewSchedule} from "../routes/Api's";


export default function ScheduledInterview() {
    const [open, setOpen] = React.useState(false);
    const [details, setDetails] = React.useState([]);


    const applicant = useParams();

    const admin = JSON.parse(sessionStorage.getItem('headers'))

    useEffect(() => {

        fetchInterviewDetails()

    }, []);

    const handleOpen = () => {
        setOpen(true);
        fetchInterviewDetails()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchInterviewDetails = () => {
        axios(getInterviewSchedule(applicant.id),
            {

                headers: admin
            }).then(response => {
            setDetails(response.data);
            console.log(details)

        }).catch(e => {
            console.log(e)
        });
    }


    return (

        <div>
            <div>

                <span className="invite  font-weight-bold " onClick={handleOpen}><label>
                    <div className="invite-box"></div>
                </label>View Interview Schedule


                </span>
            </div>
            <Dialog className=""

                    open={open}
                    onClose={handleClose}>
                <div className="dialog-main schedule-width">


                    <div className="closeBox d-flex" onClick={handleClose}>
                        <h6 className="cancelsym close ">&times;</h6>
                        <h6 className="closetext"> Close</h6>
                    </div>
                    <div className="d-flex flex-column justify-content-center ml-3">
                        <DialogTitle>
                            <p className="dialog-heading">Interview schedule</p>

                        </DialogTitle>

                        <DialogContent>

                            <p className="dialog-des"> The applicant has been scheduled for:</p>


                            <div className="mt-0 schedule-box ">
                                <div className=" row-cols-sm-3 mt-4 d-flex">
                                    <div className="col-3">
                                        <img className="pl-0 ml-0" src={HomeIcon} alt="..."/>

                                    </div>
                                    <div className="col-3">
                                        <button className="w-15-ns d-block schedule-status">PENDING</button>
                                    </div>

                                </div>
                                {/*{details.map((data,index)=><div key={index}>*/}
                                {/*    /!*<p>{data.id}</p>*!/*/}
                                {/*    <p>{data}</p>*/}
                                {/*</div>)}*/}
                                <div className="row-cols-sm-3 ml-3">
                                    <p>12:00PM</p>
                                    <p>Tue, 7th April </p>
                                </div>


                                <div className="row-cols-sm-6 ml-3 mt-5 pb-5 text-nowrap">
                                    <h3 className="schedule-mode"> Physical Session</h3>
                                </div>


                            </div>


                            < div className="d-flex mt-5">
                                <button className="w-15-ns d-block  cancelterms" onClick={handleClose}>Cancel</button>
                                <button className="w-15-ns d-block  closeterms">Start Interview</button>
                                {/* <br/><br/> */}
                            </ div>
                        </DialogContent>

                    </div>

                </div>

            </Dialog>
        </div>
    );

};


