import React, {Component} from 'react';
import axios from "axios";
import {createPasswordAPI, wakeUpBackendServer, WithdrawApplicationAPI} from "../components/routes/Api's";

class WithdrawApplicationHook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iWantTo: "",
            because: "",
            user: ""
        }
    }

    changeEvent = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.submitPassword();
        }
    };

    componentDidMount() {
        this.wakeUpServer();
    }

    wakeUpServer = () => {
        axios.get(wakeUpBackendServer);
    }
    disableButtonPress = () => {
        this.btn.setAttribute("disabled", "disabled");
    };
    enableButtonPress = () => {
        this.btn.removeAttribute("disabled");

    };

    submitPassword = (e) => {
        e.preventDefault();

        this.disableButtonPress();
        const {match} = this.props;
        let errMessage = document.querySelector("#err");

        function emptyError() {
            errMessage.textContent = ""
        }

        errMessage.textContent = "";
        if (!(this.state.iWantTo === "" && this.state.because === "")) {

            axios.post(`${WithdrawApplicationAPI(match.params.user)}`, {

            }, {
                params:{
                    reasonForWithdraw: "I want to " + this.state.iWantTo + " because " + this.state.because
                }

            })
                .then(response => {
                    if (response.status === 200) {
                        this.props.history.push(`/application_withdrawn`);

                    }
                }).catch(err => {
                if (err.response === undefined) {
                    errMessage.textContent = "Connect to the internet and try again";
                    this.enableButtonPress();
                } else {

                    errMessage.textContent = String(err.response.data.message);
                    this.enableButtonPress();
                }
            });

        } else {
            errMessage.textContent = "Kindly fill all fields";
            this.enableButtonPress();
        }

    };
}

export default WithdrawApplicationHook;