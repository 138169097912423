const secondPage = [
    {
        question: "What social media platform are you most active on? ",
        type: "drop down",
        options: ["Whatsapp", "Telegram", "Instagram", "Tiktok","Linkedin", "Twitter","Github", "Facebook", "None"],
        response: null
    }, {
        question: "Please provide an alternate phone number in case we need to reach you urgently",
        type: "solo",
        options: null,
        response: null
    }

]

export default secondPage