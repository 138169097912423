import  {Component} from 'react';
import axios from "axios";
import {postFormDataAPI} from "../components/routes/Api's";


class FormHook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            headers: "",
            page: 1,
            isNationalityNigeria: false,
            isCountryOfResidenceNigeria: false,
            questionnaire: {
                nationality: "",
                stateOfOrigin: "",
                countryOfResidence: "",
                stateOfResidence: "",
                dateOfBirth: "",
                maritalStatus: "",
                commitToProgram: "",
                iAmCurrently: "",
                nyscStatus: "",
                relocateForProgram: "",

                whatsapp:"",
                twitter: "",
                instagram: "",
                tiktok: "",
                github: "",
                linkedin: "",
                facebook: "",
                telegram: "",
                alternatePhone:"",
                mostPreferredSocialNetwork:"",



                programmingLanguage: [],
                database: [],
                frameworks: [],
                familiarTechnologies: [],
                familiarCourses: [],
                operatingSystems: [],
                whyApply: "",
                whereWorkCurrently: "",
                highestEducation: "",
                doctorateCourseField: "",
                doctorateInstitutionField: "",
                doctorateGradGrade: "",
                doctorateGradYear: "",
                mastersCourseField: "",
                mastersInstitutionField: "",
                mastersGradGrade: "",
                mastersGradYear: "",
                universityCourseField: "",
                universityInstitutionField: "",
                universityGradGrade: "",
                universityGradYear: "",
                underGradInstitution: "",
                underGradDegreeType: "",
                underGradCourse: "",
                underGradGrade: "",
                underGradGradYear: ""
            },

        }
        this.state.user = JSON.parse(sessionStorage.getItem("userData"));
        this.state.headers = JSON.parse(sessionStorage.getItem("headers"));

    }

    componentDidMount() {

    }

    valueChecker(state) {
        if (state) {
            if (state !== 'Nigeria') {
                this.setState({state: "Not Applicable"})
            } else {
                return state
            }
        }

    }

    handleChange = p => (e, values) => {
        switch (p) {
            case "nationality":
                if (values === "Nigeria") {
                    this.setState({isNationalityNigeria: true})
                this.setState({questionnaire: {...this.state.questionnaire, [p]: values}})}
                else if (values === "nigeria") {
                    this.setState({isNationalityNigeria: true})
                    this.setState({questionnaire: {...this.state.questionnaire, [p]: "Nigeria"}})}

            else{
                    this.setState({isNationalityNigeria: false})
                    this.setState({questionnaire: {...this.state.questionnaire, [p]: values}})
                }

                break
            case "countryOfResidence":
                if (values === "Nigeria"){
                    this.setState({isCountryOfResidenceNigeria: true})
                    this.setState({questionnaire: {...this.state.questionnaire, [p]: values}})
                }
                else if (values === "nigeria"){
                    this.setState({isCountryOfResidenceNigeria: true})
                    this.setState({questionnaire: {...this.state.questionnaire, [p]: "Nigeria"}})
                }
                else {this.setState({isCountryOfResidenceNigeria: false})
                    this.setState({questionnaire: {...this.state.questionnaire, [p]: values}})
                }

                break
            case "stateOfOrigin":
            case "stateOfResidence":
            case "programmingLanguage":
            case "database":
            case "frameworks":
            case "operatingSystems":
            case "familiarTechnologies":
            case "mastersInstitutionField":
            case "mastersCourseField":
            case "underGradInstitution":
            case "underGradCourse":
            case "doctorateInstitutionField":
            case "doctorateCourseField":
            // case "nyscStatus":
            //     console.log("I got here")
            // case "iAmCurrently":
                this.setState({questionnaire: {...this.state.questionnaire, [p]: values}})
                break
            default:
                this.setState({questionnaire: {...this.state.questionnaire, [p]: e.target.value}})
                break
        }


    };
    handleNameChange = (e) => {
        this.setState({questionnaire: {...this.state.questionnaire, [e.target.name]: e.target.value}})
    }

    errMessageFunction(divID, message) {
        const errMessage = document.getElementById(divID)
        errMessage.textContent = `${message} field is empty`
    }

    onNext = (e) => {
        const {
            nationality,
            stateOfOrigin,
            countryOfResidence,
            stateOfResidence,
            dateOfBirth,
            maritalStatus,
            commitToProgram,
            iAmCurrently,
            nyscStatus,
            whyApply,


            whatsapp,
            twitter,
            instagram,
            tiktok,
            github,
            linkedin,
            facebook,
            telegram,
            alternatePhone,
            mostPreferredSocialNetwork,




            highestEducation,
            doctorateCourseField,
            doctorateInstitutionField,
            doctorateGradGrade,
            doctorateGradYear,
            mastersCourseField,
            mastersInstitutionField,
            mastersGradGrade,
            mastersGradYear,

            underGradInstitution,
            underGradDegreeType,
            underGradCourse,
            underGradGrade,
            underGradGradYear
        } = this.state.questionnaire;

        const displayErrMsg = msg => () => {
            const err = document.getElementById('errText');
            err.textContent = msg;
        }

        if (mastersGradYear !== "" && underGradGradYear > mastersGradYear) {
            const msg = "Undergraduate year of graduation cannot be after Masters year of graduation";
            displayErrMsg(msg)();
            setTimeout(empty, 5000);
            return;
        }

        if (doctorateGradYear !== "" && doctorateGradYear < mastersGradYear) {
            const msg = "Masters year of graduation cannot be after Doctorate year of graduation";
            displayErrMsg(msg)();
            setTimeout(empty, 5000);
            return;
        }

        if (doctorateGradYear !== "" && doctorateGradYear < underGradGradYear) {
            const msg = "Undergraduate year of graduation cannot be after Doctorate year of graduation";
            displayErrMsg(msg)();
            setTimeout(empty, 5000);
            return;
        }


        const isNotFilledA = nationality === "" || nationality === null ||
            stateOfOrigin === null || (stateOfOrigin === "" && this.state.isNationalityNigeria) ||
            countryOfResidence === "" || countryOfResidence === null ||
            stateOfResidence === null || (stateOfResidence === "" && this.state.isCountryOfResidenceNigeria) ||
            dateOfBirth === "" ||
            maritalStatus === "" ||
            commitToProgram === "" || iAmCurrently==="" || nyscStatus === "";



        const isNotFilledB = mostPreferredSocialNetwork === "" || alternatePhone === "" ||mostPreferredSocialNetwork===null || alternatePhone===null;
        const isNotFilledC = whyApply === "";


        const isDoctor = highestEducation.toLowerCase() === "doctorate"
        const isMasters = highestEducation.toLowerCase() === "masters"
        const isUndergraduate = highestEducation.toLowerCase() === "undergraduate" || highestEducation.toLowerCase() === "graduate";
        const isNotApplicable = highestEducation.toLowerCase() === "Not Applicable"

        const doctorNotFilled = doctorateCourseField === "" ||
            doctorateInstitutionField === "" ||
            doctorateGradGrade === "" ||
            doctorateGradYear === ""

        const mastersNotFilled = mastersCourseField === "" ||
            mastersInstitutionField === "" ||
            mastersGradGrade === "" ||
            mastersGradYear === ""

        const underGraduateNotFilled = underGradInstitution === "" ||
            underGradDegreeType === "" ||
            underGradCourse === "" ||
            underGradGrade === "" ||
            underGradGradYear === ""
        const {questionnaire} = this.state

        function errMessage() {
            const err = document.getElementById('errText')
            err.textContent = "Please fill in all fields"
        }


        function errMessage2() {
            const err = document.getElementById('errText')
            err.textContent = "Already submitted questionnaire"
        }

        function empty() {
            const err = document.getElementById('errText')
            err.textContent = ""
        }

        const sendToDB = () => {
            axios.patch(postFormDataAPI, questionnaire, {headers: this.state.headers})
                .then(res => {

                    sessionStorage.setItem("userData", JSON.stringify(res.data));
                    this.props.switchComponent("formSplash")
                })

                .catch(err => {

                    if (err.response === undefined) {
                        const errText = document.getElementById('errText')
                        errText.textContent = "Connect to the internet and try again";
                        this.enableButtonPress();
                    } else {

                        this.handleError(err)
                    }
                });
        }
        if (this.state.page === 1) {
            if (isNotFilledA) {
                errMessage();
                setTimeout(empty, 5000)

            } else if (!isNotFilledA) {
                this.setState({page: this.state.page + 1})
            }

        } else if (this.state.page === 2) {
            if (isNotFilledB) {
                errMessage()
                setTimeout(empty, 5000)

            } else if (!isNotFilledB) {
                this.setState({page: this.state.page + 1})
            }
        } else if (this.state.page === 3) {
            if (isNotFilledC) {
                errMessage()
                setTimeout(empty, 5000)

            } else if (!isNotFilledC) {
                this.setState({page: this.state.page + 1})
            }
        } else if (this.state.page === 4) {
            if (isDoctor) {
                const fieldsNotField = doctorNotFilled || mastersNotFilled || underGraduateNotFilled
                if (fieldsNotField) {
                    errMessage()
                    setTimeout(empty, 5000)
                } else {
                    sendToDB()
                    sessionStorage.removeItem('formData')

                }
            } else if (isMasters) {
                const fieldsNotField = mastersNotFilled || underGraduateNotFilled
                if (fieldsNotField) {
                    errMessage()
                    setTimeout(empty, 5000)
                } else {
                    sendToDB()
                    sessionStorage.removeItem('formData')

                }
            } else if (isUndergraduate) {

                if (underGraduateNotFilled) {
                    errMessage()
                    setTimeout(empty, 5000)
                } else {
                    sendToDB()
                    sessionStorage.removeItem('formData')
                }
            } else if (!isNotApplicable) {
                sendToDB()
                sessionStorage.removeItem('formData')
            } else {
                errMessage()
                setTimeout(empty, 5000)
            }
        }
    };
    onBack = (e) => {
        if (this.state.page > 1) {
            this.setState({page: this.state.page - 1})
        }
    };

    handleError = (e) => {
        switch (e.response.status) {
            case 401:
            case 403:
                this.props.history.push('/login');
                break;

            case 400:
                const err = document.getElementById('errText')
                err.textContent = "Already submitted questionnaire";
                break;
            default:
                break;
        }
    };


}

export default FormHook;