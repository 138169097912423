import React from 'react';
import '../../../../css/forms.css'
import Form1 from "./Form1";
import Form3 from "./Form3";
import Form4 from "./Form4";
import FormHook from "../../../../hooks/FormHook";
import UserDashBoardHeader from "../../UserDashboard/UserDashBoardHeader";
import Form2 from "./Form2";

class Forms extends FormHook {

    render() {
        const formData = JSON.parse(sessionStorage.getItem('formData'))
        const {page, universities} = this.state
        const backStyle = () => {
            if (page === 1) {
                return {
                    opacity: 0.2
                }
            }
        }
        const {
            nationality,
            stateOfOrigin,
            countryOfResidence,
            stateOfResidence,
            dateOfBirth,
            maritalStatus,
            commitToProgram,
            relocateForProgram,
            nyscStatus,
            iAmCurrently,
            whereWorkCurrently,

            whatsapp,
            twitter,
            instagram,
            tiktok,
            github,
            linkedin,
            facebook,
            telegram,
            alternatePhone,
            mostPreferredSocialNetwork,

            programmingLanguage,
            database,
            frameworks,
            operatingSystems,
            whyApply,
            familiarTechnologies: [],
            familiarCourses: [],

            highestEducation,
            doctorateCourseField,
            doctorateInstitutionField,
            doctorateGradGrade,
            doctorateGradYear,
            mastersCourseField,
            mastersInstitutionField,
            mastersGradGrade,
            mastersGradYear,
            universityCourseField,
            universityInstitutionField,
            universityGradGrade,
            universityGradYear,
            underGradInstitution,
            underGradDegreeType,
            underGradCourse,
            underGradGrade,
            underGradGradYear


        } = this.state.questionnaire


        return (
            <div className="">
                <div className="row  justify-content-center p-4">
                    <div className="container-fluid mb-5">
                        <UserDashBoardHeader user={this.state.user} onOpen={this.openNav}/>
                    </div>
                    <div className="col-md-5 vh-79 shadow-3 fixed-form-width">
                        <div>
                            <h3 style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "20px",
                                lineHeight: "26px",
                                color: "#960C0C"
                            }} className="text-danger text-center">Let's get to know you</h3>
                        </div>
                        <div className="row formContainers justify-content-around">
                            {this.state.page === 1 ? <Form1 nationality={nationality} stateOfOrigin={stateOfOrigin}
                                                            countryOfResidence={countryOfResidence}
                                                            stateOfResidence={stateOfResidence}
                                                            dateOfBirth={dateOfBirth} maritalStatus={maritalStatus}
                                                            nyscStatus={nyscStatus}
                                                            iAmCurrently={iAmCurrently}
                                                            onNationalityChange={this.onNationalityChange}
                                                            onResidenceChange={this.onResidenceChange}
                                                            relocateForProgram={relocateForProgram}
                                                            commitToProgram={commitToProgram}
                                                            onData={this.onData} disabledChecker={this.disabledChecker}
                                                            countryChange={this.countryChange}
                                                            inputChecker={this.inputChecker}
                                                            inputChecker2={this.inputChecker2}
                                                            whereWorkCurrently={whereWorkCurrently}

                                                            handleChange={this.handleChange} formData={formData.data}
                                                            handleNameChange={this.handleNameChange}
                                                            isNationalityNigeria={this.state.isNationalityNigeria}
                                                            isCountryOfResidenceNigeria={this.state.isCountryOfResidenceNigeria}
                            /> : ""}

                            {this.state.page === 2 ? <Form2
                                onData={this.onData} disabledChecker={this.disabledChecker}
                                whatsapp={whatsapp}
                                twitter={twitter}
                                instagram={instagram}
                                linkedin={linkedin}
                                tiktok={tiktok}
                                telegram={telegram}
                                alternatePhone = {alternatePhone}
                                mostPreferredSocialNetwork = {mostPreferredSocialNetwork}
                                facebook = {facebook}
                                github = {github}
                                formData={formData.data}
                                handleChange={this.handleChange}
                                handleNameChange={this.handleNameChange}
                                familiarTechnologies={this.state.questionnaire.familiarTechnologies}
                                familiarCourses={this.state.questionnaire.familiarCourses}
                            /> : ""}

                            {this.state.page === 3 ? <Form3
                                onData={this.onData} disabledChecker={this.disabledChecker}
                                inputChecker={this.inputChecker} handleChange={this.handleChange}
                                programmingLanguage={programmingLanguage} database={database}
                                frameworks={frameworks} operatingSystems={operatingSystems} whyApply={whyApply}
                                formData={formData.data}
                                familiarTechnologies={this.state.questionnaire.familiarTechnologies}
                                familiarCourses={this.state.questionnaire.familiarCourses}
                            /> : ""}
                            {this.state.page === 4 ? <Form4
                                onData={this.onData} disabledChecker={this.disabledChecker}
                                inputChecker={this.inputChecker} handleChange={this.handleChange}
                                handleNameChange={this.handleNameChange}
                                highestEducation={highestEducation} doctorateCourseField={doctorateCourseField}
                                doctorateInstitutionField={doctorateInstitutionField}
                                doctorateGradYear={doctorateGradYear}
                                doctorateGradGrade={doctorateGradGrade} mastersCourseField={mastersCourseField}
                                mastersInstitutionField={mastersInstitutionField} mastersGradYear={mastersGradYear}
                                mastersGradGrade={mastersGradGrade} universityCourseField={universityCourseField}
                                universityInstitutionField={universityInstitutionField}
                                universityGradYear={universityGradYear}
                                universityGradGrade={universityGradGrade} universityData={universities}
                                underGradInstitution={underGradInstitution} underGradDegreeType={underGradDegreeType}
                                underGradCourse={underGradCourse} underGradGrade={underGradGrade}
                                underGradGradYear={underGradGradYear}
                                formData={formData.data}

                            /> : ""}
                        </div>
                        <div className="text-center w-100">
                            <p id="errText" className="text-danger mx-auto text-center small"></p>
                        </div>
                        <div className="buttons w-100 d-flex justify-content-between">
                            <button style={backStyle()} onClick={this.onBack} className="prev-button back"><i
                                style={{marginRight: 15, width: 10}} className="fas fa-arrow-left"> </i>Back
                            </button>
                            <p className="mt-4 centers">{page}/4</p>
                            <button onClick={this.onNext}
                                    className="prev-button submit p-3">{page === 4 ? "Submit" : "Next"} <i
                                style={{marginLeft: 5, width: 10}} className="fas fa-arrow-right"> </i></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Forms;