import React from 'react';
import axios from "axios";
import {ELDER_URL, wakeUpBackendServer} from "../components/routes/Api's";
import {submitAnyPreviousTest} from "./FunctionalHooks";
import Login from "../components/user/Login";


const jwtDecode = require('jwt-decode')


class RedirectFromElderDashBoard extends Login {
    constructor(props) {
        super(props);
        this.state = {
            userToken: "",
            isEnabled: true,
            isAuth: false,
            user: "",
            headers: {
                ContentType: "application/json",
                Authorization: ""
            },
            email: "",
            password: "",


        }
    }

    componentDidMount() {
        this.wakeUpServer();
        this.redirectToDashboard(this.props.match.params.userToken);
    }


    redirectToDashboard = (userToken) => {
        const decodedToken = jwtDecode(userToken)
        const headers = {
            ContentType: "application/json",
            Authorization: `Bearer ${userToken}`,
        }
        this.saveUserDataToSessionStorage(decodedToken, headers);
        this.redirectToPage(decodedToken.roles.toLowerCase());

    }
    saveUserDataToSessionStorage = (decodedToken, header) => {
        this.setState({user: decodedToken.userData});
        sessionStorage.setItem("userData", JSON.stringify(decodedToken.userData));
        sessionStorage.setItem("headers", JSON.stringify(header));
    }

    redirectToPage = (role) => {
        switch (role) {
            case "applicant":
                submitAnyPreviousTest();
                sessionStorage.setItem("controlFlag", JSON.stringify(this.controlFlag));
                this.props.history.push(`/dashboard/`);
                break;
            case "admin":

                this.props.history.push(`/systemAdmin/admin`);
                break;
            default:
                this.redirect("wrongRoute")
                break;


        }
    }

    redirect = (token) => {
        window.location.href = ELDER_URL + '/redirect/' + token;

    }

    wakeUpServer = () => {
        axios.get(wakeUpBackendServer).then(r => "do nothing");
    }


}

export default RedirectFromElderDashBoard;