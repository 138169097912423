import React, {useState} from "react"
import UserDashBoardHeader from "../UserDashboard/UserDashBoardHeader";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import {submitNativeAgreementForm} from "../../routes/Api's";

const useStyles = makeStyles(theme => ({
    parent: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: '100%',
        marginTop: '25vh'
    },
    formHolder: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: '50%',
        padding: '45px 55px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '10px 15px',
        },
        '& > p > a': {
            textDecoration: 'underline',
            color: 'blue'
        }
    },
    checkbox: {
        marginTop: 15,
        '& > span.MuiTypography-body1': {
            lineHeight: '1.5 !important',
        }
    },
    backBtn: {
        padding: '12px 17px',
        background: "#FFF",
        border: '1px solid #FF2E2E',
        transition: `all .5s ${theme.transitions.easing.easeInOut}`,
        color: '#FF2E2E',
        '&:hover': {
            background: '#FF2E2E',
            color: '#FFF'
        }
    },
    submit: {
        padding: '12px 17px',
        background: "#FF2E2E",
        color: '#FFF',
        border: '1px solid #FF2E2E',
        transition: `all .5s ${theme.transitions.easing.easeInOut}`,
        '&:hover': {
            borderColor: '#960C0C',
            background: '#960c0c',
            color: '#FFF'
        }
    },
    btns: {
        maxWidth: 200,
        display: 'flex',
        justifyContent: 'space-between',
    }
}))

const NativeForm = (props) => {
    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const classes = useStyles(props)
    const headers = JSON.parse(sessionStorage.getItem("headers"))
    const handleSubmit = e => {
        e.preventDefault()
        if (!checked) return
        setDisabled(true)
        axios.post(submitNativeAgreementForm('fillNativeAgreement'), {agreedToTerms: checked}, {headers: headers})
            .then(res => {
                console.log(res.data)
                sessionStorage.setItem('userData', JSON.stringify(res.data))
                props.switchComponent('dashboard')
            })
            .catch(err => {
                console.log(err.response.data)
                setDisabled(false)
                console.log('an error occurred...')
            })
    }

    return (
        <>
            <UserDashBoardHeader user={props.user} imageLoading={false}/>
            <div className={`w-100 ${classes.parent}`}>
                <div className={`shadow-3 ${classes.formHolder}`}>
                    <h5 className="text-center" style={{fontWeight: 'bold', color: "#960C0C"}}>
                        TERMS AND CONDITIONS BETWEEN SEMICOLON AND NATIVES
                    </h5>
                    <p>These terms and conditions apply when you become a Native at Semicolon. The terms and conditions shall apply between Semicolon and you, a Native. By accepting Semicolon’s offer of a place on the program, you accept to be bound by these terms and conditions in full.</p>
                    <ul>
                        <li>
                            As a Native in Semicolon, all fees paid are non-refundable.
                        </li>
                        <li>
                            You shall ensure that the payment of your tuition is completed before the
                            completion of your program and adhere strictly to your payment plan.
                        </li>
                        <li>
                            You shall ensure you are well behaved and act within the bounds of the law while
                            you remain a Native with Semicolon.
                        </li>
                        <li>
                            You shall not damage any property of Semicolon and shall be held liable for any
                            of such damage on Semicolon property.
                        </li>
                        <li>
                            Semicolon shall also be responsible for providing you a laptop upon the payment
                            of your initial deposit unless you have indicated that you have a laptop that meets
                            Semicolon’s minimum specifications.
                        </li>
                        <li>
                            This laptop once issued is fully owned by you and you shall be responsible for any
                            form of repair or maintenance on it.
                        </li>
                        <li>
                            No Native shall engage in the use of illicit drugs and consumption of Alcohol within
                            the premises of our facilities.
                        </li>


                        <li>
                            That where a Native chooses to engage in a romantic relationship with a fellow Native,
                            parties to this relationship shall disclose to the management of Semicolon the
                            existence of such relationship.
                        </li>
                        <li>
                            That the activities of a romantically rampaging Native of Semicolon shall not be
                            condoned.
                        </li>
                        <li>
                            You shall be bound by the existing Semicolon Code of Conduct.
                        </li>
                        <li>
                            It is your responsibility to ensure that all the information you provide to Semicolon
                            are true and accurate.
                        </li>

                        <li>
                            Semicolon shall reserve the right to terminate your status as a Native if found guilty of any grave offence upon proper investigation.
                        </li>
                        <li>
                            Semicolon reserves the sole discretion to modify these terms and conditions and the
                            Native shall be duly notified of such modifications.
                        </li>

                    </ul>
                    <p>If you have any questions or concerns about these terms and conditions, please contact us on <a
                        href="mailto:info@semicolon.africa">info@semicolon.africa</a></p>
                    <FormControlLabel
                        className={classes.checkbox}
                        label="The Native agrees to have carefully read through these terms and conditions and agrees to be bound by them."
                        control={<Checkbox checked={checked} onChange={() => setChecked(!checked)}
                                           name="tAndC"/>}
                    />
                    <div className="stamp-div">
                        <img alt={"stamp"} src="https://res.cloudinary.com/semicolon-africa/image/upload/v1601575479/emailpng/small_seal_cropped_upejhj.png"/>
                    </div>
                    <div className={`${classes.btns} mt-5`}>
                        <button className={classes.backBtn} disabled={disabled} onClick={() => props.switchComponent('dashboard')}>Go back</button>
                        <button className={classes.submit} disabled={disabled} onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NativeForm