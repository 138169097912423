import React,{Component} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import '../../css/profile.css'
import {ReactComponent as TestScore} from "../../images/TestScore.svg";


class TestInfo extends Component{

    constructor(props) {
        super(props);
        this.state={
        }
    }

    render() {
        const testData =  this.props.testData;


        return(
            <div className="">
                <div className=" col-12 profile-menu ml-1 mr-3 mb-5 fullInfoInner ">

                        <Card className="profile-card  mt-5">
                            <CardContent className="ml-3">
                                <h3 className="summary-head">Aptitude Test Scores</h3>
                                <div>

                                    { testData.map((test, index)=>(
                                        <div className="row" key={index}>
                                            <div className="col-sm-9">
                                                <p className="question-info">Question {index}</p>
                                                <p className="question-label">{test.question}</p>
                                                {/*<input type="radio" >*/}
                                                {/*    {test.options.map((option, index)=>(*/}
                                                {/*        <label key={index}>{option}</label>))}*/}
                                                {/*</input>*/}
                                                {test.options.map((option, index)=>(
                                                    <div key={index}>{option}</div>))}
                                            </div>
                                            {/*<div className="col-sm-3 d-flex">*/}
                                            {/*    <div className="score-circle">9/10</div>*/}
                                            {/*    <TestScore/>*/}


                                            {/*</div>*/}
                                        </div>
                                    )) }


                                </div>

                            </CardContent>
                        </Card>
                </div>
            </div>
        );
    }


} export default TestInfo