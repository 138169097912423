import React, {Component} from 'react';
import thirdPage from "../formData/thirdPage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import '../../../../css/forms.css'

class Form3 extends Component {

    render() {
        // const formDatas = JSON.parse(sessionStorage.getItem('formData'))
        const {
            handleChange,
            programmingLanguage,
            database,
            frameworks,
            // onData,
            operatingSystems,
            whyApply,
            formData,
            familiarCourses,
            familiarTechnologies
        } = this.props

        return (
            <div className="row w-100">
                <div className="col-md-12">
                    <Autocomplete
                        multiple
                        // freeSolo
                        value={programmingLanguage}
                        onChange={handleChange("programmingLanguage")}
                        options={formData.programmingLanguages}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label={thirdPage[0].question}
                                       margin="normal" variant="outlined"
                                       name="programmingLanguage"
                                       color="secondary"
                            />
                        )}
                    />
                </div>
                <div className="col-md-12">
                    <Autocomplete
                        multiple
                        // freeSolo
                        onChange={handleChange("database")}
                        value={database}
                        options={formData.databases}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label={thirdPage[1].question}
                                       margin="normal" variant="outlined"
                                       color="secondary"
                            />
                        )}
                    />
                </div>
                <div className="col-md-12">
                    <Autocomplete
                        multiple
                        // freeSolo
                        value={frameworks}
                        onChange={handleChange("frameworks")}
                        options={formData.frameworks}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label={thirdPage[6].question}
                                       margin="normal" variant="outlined"
                                       color="secondary"
                            />
                        )}
                    />
                </div>
                <div className="col-md-12">
                    <Autocomplete
                        multiple
                        // freeSolo
                        onChange={handleChange("operatingSystems")}
                        value={operatingSystems}
                        options={formData.os}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label={thirdPage[2].question}
                                       margin="normal" variant="outlined"
                                       color="secondary"
                            />
                        )}
                    />
                </div>
                <div className="col-md-12">
                    <Autocomplete
                        multiple
                        // freeSolo
                        onChange={handleChange("familiarTechnologies")}
                        value={familiarTechnologies}
                        options={formData.otherTechnologies}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} label={thirdPage[7].question}
                                       margin="normal" variant="outlined"
                                       color="secondary"
                            />
                        )}
                    />
                </div>
                <div className="col-md-12">
                    <TextField
                        // id="text"
                        label={thirdPage[8].question}
                        color="secondary"
                        className="newText"
                        value={familiarCourses}
                        onChange={handleChange('familiarCourses')}
                        margin="normal"
                        variant={"outlined"}

                    />
                </div>
                <div className="col-md-12">
                    <TextField
                        // id="text"
                        label={thirdPage[3].question}
                        color="secondary"
                        className="newText"
                        value={whyApply}
                        onChange={handleChange('whyApply')}
                        margin="normal"
                        variant={"outlined"}

                    />
                </div>
            </div>
        );
    }
}

export default Form3;