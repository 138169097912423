import React, {Component} from 'react';
import SemLogo from "../../../images/semfulllogo.svg";
import Hamburger from "../Hamburger";
import UserDropdown from "./UserDropdown";
import {Link} from "react-router-dom";

class UserDashBoardHeader extends Component {

    render() {
        const {onOpen, user, imageURL, imageLoading} = this.props;
        return (
            <nav className="navbar navbar-expand-lg fixed-top navbar-light my-navbar">
                <div className="container">
                    <Link className="navbar-brand" to="#"><img src={SemLogo} alt="Logo" width="178px"
                                                               height="78px"/></Link>
                    <div className="user">
                        <UserDropdown user={user} imageURL={imageURL} imageLoading={imageLoading}/>
                    </div>
                    <Hamburger onOpen={onOpen}/>
                </div>
            </nav>
        );
    }
}

export default UserDashBoardHeader;