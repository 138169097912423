import React, {Component} from 'react';
import '../../css/modal.css'
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import {
    createBatch,
    PurgeUserAPI,
    GetUserDetails,
    UndoApplicantTest,
    deleteDiscountCode,
    createDiscountCode,
    UpdateInfoSession, SendInfoSessionInvitation, AnnounceInfoSession
} from "../routes/Api's";
import {toast, ToastContainer} from "react-toastify";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

class multiButtonCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discountOwner: "",
            code:"",
            numberOfValidRegistration:"",
            discountAmount:"",
        };
    };

    createDiscountCode = () => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        const  {discountOwner,code,numberOfValidRegistration, discountAmount}= this.state;

        console.log(this.state)


            axios.post(createDiscountCode, {
                discountOwner: discountOwner,
                code: code,
                numberOfValidRegistration: numberOfValidRegistration,
                discountAmount: discountAmount
            }, {headers: headers})
                .then(res => {
                        toast.success('Discount code created successfully')
                        toast.success(res.data.discountCode);
                    }
                ).catch(err => {
                // toast.error('Info Session creation failed')
                toast.error(err.response.data.message)

            });

    }

    updateDiscountCode = () => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        const  {code,numberOfValidRegistration, discountAmount}= this.state;

        console.log(this.state)


        axios.patch(createDiscountCode, {
            code: code,
            numberOfValidRegistration: numberOfValidRegistration,
            discountAmount: discountAmount
        }, {headers: headers})
            .then(res => {
                    toast.success('Discount code updated successful')
                    toast.success(res.data.discountCode);
                }
            ).catch(err => {
            // toast.error('Info Session creation failed')
            toast.error(err.response.data.message)

        });

    }

    deleteDiscountCode = () => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        const  {code}= this.state;

        console.log(this.state)


        axios.delete(deleteDiscountCode(code), {headers: headers})
            .then(res => {
                    toast.success('Discount code deleted successful')

                }
            ).catch(err => {
            // toast.error('Info Session creation failed')
            toast.error(err.response.data.message)

        });

    }




    render() {


        return (

            <div className="col-xl-3 col-md-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Summer School Discount Code Generator</h6>
                    </div>
                    <div className="card-body">

                        <div className="form-group mt-3">
                            {/*<div className="col-md-6">*/}
                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label="Discount Owner "
                                           variant="outlined" name='discountOwner'
                                           color={"secondary"} className="newField" required
                                />
                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label="Discount Code"
                                           variant="outlined" name='code'
                                           color={"secondary"} className="newField"
                                />
                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label=" Number of Registration Allowed"
                                           variant="outlined" name='numberOfValidRegistration'
                                           color={"secondary"} className="newField" required
                                />
                            <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label="Discount Amount"
                                           variant="outlined" name='discountAmount'
                                           color={"secondary"} className="newField" required
                                />
                                {/* <TextField onChange={this.setOnChange}*/}
                                {/*           margin="normal" fullWidth type="text" label="mode -Online or Offline"*/}
                                {/*           variant="outlined" name='mode'*/}
                                {/*           color={"secondary"} className="newField" required*/}
                                {/*/>*/}





                        </div>
                        <button type="submit" className="btn w-30  p-2 bt border-dark " onClick={this.createDiscountCode}>Create Code</button>
                        <button type="submit" className="btn w-30  p-2 bt border-dark" onClick={this.updateDiscountCode}>Update Code</button>
                        <button type="submit" className="btn w-30  p-2 bt border-dark" onClick={this.updateDiscountCode}>Delete Code</button>
                        {/*<button type="submit" className="btn w-30 p-2 bt border-dark" onClick={this.sendInvitation}>Notify</button>*/}
                        <ToastContainer/>

                    </div>
                </div>


            </div>
        );
    };

    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
}

export default multiButtonCard;
