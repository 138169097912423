import React, {Component} from 'react';
import ProfileHeader from "../profile/ProfileHeader";
import AdminDashboardBody from "./AdminDashboardBody";

class AdminMainDashboard extends Component {
    render() {
        return (
            <div>
                <ProfileHeader/>
                <AdminDashboardBody/>
            </div>
        );
    }
}

export default AdminMainDashboard;