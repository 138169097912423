import React, {Component} from 'react';
import '../../css/profile.css'
import styles from "../../css/userDetails.module.css";

class FullInfo extends Component {
    render() {

        const applicant = this.props.applicant
        const questionnaire = this.props.questionnaire
        const applicantDocument = this.props.applicantDocument
        return (
            <div className="container p-5 ">
            <div className="full-info col-12 fullInfoInner "  >
                <div className="ml-4 mt-2 text-danger pt-4">
                    <p className="mt-2 summary-head">Full Info</p>
                </div>
                <div className="col-12 d-flex">
                    <div className="col-6 mt-3">
                        <label className="my-label">First Name</label>
                        <h6>{applicant.firstName}</h6>
                    </div>
                    <div className="col-6 mt-3">
                        <label className="my-label">Last Name</label>
                        <h6>{applicant.lastName}</h6>
                    </div>
                </div>
                <div className="col-12 d-flex">
                    <div className="col-6 mt-4">
                        <label className="my-label">Email Address</label>
                        <h6 >{applicantDocument.email}</h6>
                    </div>
                    <div className="col-6 mt-4">
                        <label className="my-label">Age Range</label>
                        <h6>{applicantDocument.ageRange}</h6>
                    </div>
                </div>
                <div className="col-12 d-flex">
                    <div className="col-6 mt-4">
                        <label className="my-label">State Of Origin</label>
                        {questionnaire.stateOfOrigin===""? <h6>Plateau</h6>:
                            <h6>{questionnaire.stateOfOrigin}</h6>}
                    </div>
                    <div className="col-6 mt-4">
                        <label className="my-label">State Of Residence</label>
                        <h6>{questionnaire.stateOfResidence}</h6>
                    </div>
                </div>
                <div className="col-12 d-flex">
                    <div className="col-6 mt-4">
                        <label className="my-label">Higher Institution</label>
                        <h6>{questionnaire.underGradInstitution}</h6>
                    </div>
                    <div className="col-6 mt-4">
                        <label className="my-label">Course of Study</label>
                        <h6>{questionnaire.underGradCourse}</h6>
                    </div>
                </div>
                <div className="col-12 d-flex">
                    <div className="col-6 mt-4">
                        <label className="my-label">University Grade</label>
                        <h6>First Class Hons</h6>
                    </div>
                    <div className="col-6 mt-4">
                        <label className="my-label">Marital Status</label>
                        <h6>{questionnaire.maritalStatus}</h6>
                    </div>
                </div>
                {/*<div className="col-12 d-flex">*/}
                {/*    <div className="col-6 mt-4">*/}
                {/*        <label className="my-label">Secondary School</label>*/}
                {/*        <h6>Ave Maria High School</h6>*/}
                {/*    </div>*/}
                {/*    <div className="col-6 mt-4">*/}
                {/*        <label className="my-label">Graduation year</label>*/}
                {/*        <h6>2014</h6>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-12 d-flex">
                    <div className="col-6 mt-4">
                        <label className="my-label">Programming Languages</label>
                        <h6>{questionnaire.programmingLanguage}</h6>
                    </div>
                    <div className="col-6 mt-4">
                        <label className="my-label">Databases</label>
                        <h6>{questionnaire.database}</h6>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default FullInfo;