import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CheckIcon from "../../../images/check-circle.svg";
import UnCheckIcon from "../../../images/uncheck-icon.svg";
import HomeIcon from "../../../images/home.svg";
import Typography from "@material-ui/core/Typography";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

const CreateScheduleButton = withStyles({
    root: {
        minWidth: '100%',
        color: '#FFF',
        background: '#FF2E2E',
        transition: 'hover .5s ease',
        '&:hover': {
            background: '#FF2E2E',
            opacity: .86,
        },
        marginTop: 50,
        marginBottom: 60,
        padding: 12,
    },
    text: {
        textTransform: "none",
    }
})(Button);

const useStyles = makeStyles({
    root: {
        border: '1px solid rgba(43, 28, 28, 0.25)',
        borderRadius: 8,
        padding: 20,
        boxShadow: '0px 4px 8px rgba(108, 132, 120, 0.15)',
        width: 200,
        height: 270,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    iconHome: {
        width: 45,
        height: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 126, 29, 0.15)',
        borderRadius: 8,
    }
});


const InfoSessionList = ({infoSessionId, infoSessionList, handleCreateSchedule}) => {

    const [selected, setSelected] = useState(infoSessionId);
    const [clicked, setClicked] = useState(true);


    const selectStyles = infoSessionId => () => {

        if (infoSessionId === selected) {
            return {
                borderColor: '#960C0C',
            }
        } else {
            return {
                borderColor: 'rgba(43, 28, 28, 0.25)',
            }
        }
    }


    const handleClick = infoSessionId => (e) => {
        setSelected(infoSessionId);
    }

    const executeHandleCreateSchedule = () => {
        handleCreateSchedule(selected);
        setClicked(!clicked);
    }


    const convertDate = d => () => {
        const [day, month, year] = d.split("-");
        const dateObj = new Date(year, month - 1, day);
        return dateObj.toDateString();
    }

    const convertTime = t => () => {
        let [hour, min] = t.split(':');
        const mode = hour > 11 ? "PM" : "AM";
        if (hour > 12) {
            hour %= 12;
            if (hour < 10) {
                hour = "0" + hour;
            }
        }
        return hour + ":" + min + " " + mode;
    }

    const classes = useStyles();

    if (infoSessionList.length === 0) {
        return "No Information Session available."
    }


    return (
        <Box>
            <Grid container spacing={4}>
                {infoSessionList.map((infoSession, index) => {
                    return (
                        <Grid item key={index} xs={12} sm={6}>
                            <Card className={classes.root}
                                  onClick={handleClick(infoSession.infoSessionId)}
                                  style={selectStyles(infoSession.infoSessionId)()}
                            >
                                <Box display="flex" justifyContent="flex-end" style={{
                                    height: '10%'
                                }}>
                                    <img style={{
                                        height: 20,
                                        width: 20,
                                    }} src={infoSession.infoSessionId === selected ? CheckIcon : UnCheckIcon} alt=""/>
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-around" style={{
                                    height: '90%'
                                }}>
                                    <div className={classes.iconHome}>
                                        <img style={{
                                            width: 25,
                                            height: 25,
                                        }} src={HomeIcon} alt=""/>
                                    </div>
                                    <Box>
                                        <Typography gutterBottom variant="body1" style={{
                                            color: 'rgba(43, 28, 28, 0.85)',
                                            maxWidth: 100,
                                        }}>
                                            {convertTime(infoSession.sessionTime)()}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" style={{
                                            color: 'rgba(43, 28, 28, 0.85)',
                                        }}>
                                            {convertDate(infoSession.sessionDate)()}
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" style={{
                                        color: '#960C0C',
                                        fontWeight: 'bold',
                                        maxWidth: 100,
                                    }}>
                                        {infoSession.mode === 'Online' ? 'Virtual Session' : 'Physical Session'}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
            <CreateScheduleButton onClick={executeHandleCreateSchedule}>Confirm Session</CreateScheduleButton>
        </Box>
    );
}


export default InfoSessionList;