import React, {useEffect} from 'react';
import '../../../css/login.css'

export default function PaymentButton(props) {

    return (


        <div>
            <button disabled={props.disableBtn} className="d-block w-100 p-3 payment pr-4 my-3 bt" onClick={props.sendPaymentDetails}>Confirm Payment
            </button>

        </div>
    );

};


