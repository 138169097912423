import React,{ Component} from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import UserDashBoardHeader from "../UserDashboard/UserDashBoardHeader";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import firstPage from "../forms/formData/firstPage";

export class HealthSafetyForm extends Component{

    back =e =>{
        e.preventDefault();
        this.props.prevStep();
    }
    submit =e=>{
        e.preventDefault();
        this.props.submitForm(e);
    }

    render() {
        const { values, handleChange, step} = this.props;
        let user = JSON.parse(sessionStorage.getItem("userData"));
        let imageURL = user.profilePictureUrl;
        let imageLoading = false;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className="justify-content-center p-4">
                        <div className="container-fluid mb-5">
                            <UserDashBoardHeader user={user} onOpen={this.openNav} imageLoading={imageLoading} imageURL={imageURL}/>
                        </div>
                        <div className="col-md-5 vh-79 personalCon shadow-3 ">
                            <p>{values.errMsg}</p>
                            <div>
                                <h3 style={{
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    lineHeight: "26px",
                                    color: "#960C0C"
                                }} className="text-danger">Health & Safety Section</h3>
                            </div>

                            <div className="row formContainers justify-content-around">

                                <form className="row w-100 ">
                                    <div className="col-md-12">
                                        <TextField
                                            label='Any medical conditions that
                                             we should know about? (e.g, asthma, a heart condition, etc.)'
                                            margin="normal" variant="outlined"
                                            size={"medium"}
                                            onChange={handleChange('medicalConditions')}
                                            defaultValue={values.medicalConditions}
                                            fullWidth
                                            color="secondary"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <TextField
                                            label='Do you have any food allergies we should know about?'
                                            margin="normal" variant="outlined"
                                            size={"medium"}
                                            onChange={handleChange('foodAllergies')}
                                            defaultValue={values.foodAllergies}
                                            fullWidth
                                            color="secondary"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <TextField
                                            label=' Emergency contact - Full Name'
                                            margin="normal" variant="outlined"
                                            color="secondary"
                                            size={"medium"}
                                            onChange={handleChange('emergencyContactName')}
                                            defaultValue={values.emergencyContactName}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <TextField
                                            label='Emergency contact - phone number(s)'
                                            margin="normal" variant="outlined"
                                            color="secondary"
                                            type="number"
                                            size={"medium"}
                                            onChange={handleChange('emergencyContactPhone')}
                                            defaultValue={values.emergencyContactPhone}
                                            fullWidth
                                        />
                                    </div>
                                    <div>
                                        <h3 style={{
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "18px",
                                            lineHeight: "24px",
                                            color: "#960C0C",
                                            marginLeft:20
                                        }} className="text-danger ">Areas of Personal Interest Section</h3>
                                    </div>
                                    {/*<div className="col-md-12">*/}
                                    {/*    <TextField*/}
                                    {/*        label='What types of problems/innovative solutions would you be most interested in working on?'*/}
                                    {/*        margin="normal" variant="outlined"*/}
                                    {/*        color="secondary"*/}
                                    {/*        onChange={handleChange('innovativeSolution')}*/}
                                    {/*        defaultValue={values.innovativeSolution}*/}
                                    {/*        fullWidth*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="col-md-12">
                                        <TextField
                                            label='Please briefly list any hobbies'
                                            margin="normal" variant="outlined"
                                            color="secondary"
                                            size={"medium"}
                                            onChange={handleChange('hobbies')}
                                            defaultValue={values.hobbies}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-12  d-flex">
                                        <RadioGroup onChange={handleChange('areYouInterestedInAccommodation')} defaultValue={values.areYouInterestedInAccommodation}  className="align-self-end radioButton radioText" >
                                            {/*<span className="small">Are you interested in accommodation?</span>*/}
                                            <span className="small">
                                             <div style={{width: "100%"}} className="-4">Are you interested in accommodation?</div>
                                            <div className="align-radio second-rad mt-7">
                                            <FormControlLabel value="Yes" className="" control={<Radio/>} label="Yes"/>
                                            <FormControlLabel value="No" className="" control={<Radio/>}
                                                              label="No"/>
                                            </div>
                                            </span>


                                        </RadioGroup>
                                    </div>
                                    <div className="col-md-12 d-flex">
                                        <RadioGroup onChange={handleChange('doYouHaveHealthInsurance')} defaultValue={values.doYouHaveHealthInsurance}  className="align-self-end radioButton radioText">
                                            {/*<span className="small">Do you have health insurance?</span>*/}
                                            <span className="small">
                                             <div style={{width: "100%"}} className="-4">Do you have health insurance?</div>
                                                <div className="align-radio second-rad mt-7">
                                                    <FormControlLabel value="Yes" className="radioCircle health-radio" control={<Radio/>} label="Yes"/>
                                                    <FormControlLabel value="No"  className="radioCircle" control={<Radio/>}
                                                                      label="No"/>
                                                </div>
                                            </span>


                                        </RadioGroup>
                                    </div>
                                    <p id={"errMessage"} className="text-danger text-center align-content-center"></p>
                                    <div className="buttons w-100 d-flex justify-content-between">
                                        <button className="prev-button back" onClick={this.back}><i
                                            style={{marginRight: 15, width: 10}} className="fas fa-arrow-left"> </i>Back
                                        </button>

                                        <p className="mt-4 centers">{step}/2</p>
                                        <button
                                            disabled={this.props.btnState}
                                            className="prev-button submit p-3" onClick={(e)=>this.submit(e)}>Submit <i
                                            style={{marginLeft: 5, width: 10}} className="fas fa-arrow-right"> </i></button>
                                    </div>


                                </form>


                            </div>
                        </div>
                    </div>

                </React.Fragment>

            </MuiThemeProvider>

        )
    }

}export default HealthSafetyForm