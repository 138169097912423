import React from 'react'
import {useParams} from 'react-router-dom'
import '../../css/modal.css'
import '../../css/fulldiv.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Check from "../../images/check-circle.svg";
import UnCheck from "../../images/uncheck-icon.svg";
import {sendInterviewDecision} from "../routes/Api's";
import {ReactComponent as Decision} from "../../images/Decision.svg";



export default function DecisionModal() {


    const [open, setOpen] = React.useState(false);
    const [decision, setDecision] = React.useState('empty')

    const applicantEmail = useParams()


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    console.log(decision);

    function sendDecision() {

        const admin = JSON.parse(sessionStorage.getItem('headers'))

        fetch(sendInterviewDecision(applicantEmail.id, decision), {
            method: "post",
            headers: admin
        }).then(res => {
            console.log("Decision Sent", res)
        }).catch(err => console.log("Decision not Sent", err.response))

        handleClose();

    }


    return (

        <div>

            <span onClick={handleOpen} className="make-decision font-weight-bold ml-0 mb-5"><label>
                <Decision className="decision-box ml-0" />
                {/*<div className="decision-box"></div>*/}
            </label>Make Decision
            </span>
            <div>

                <Dialog className=""

                        open={open}
                        onClose={handleClose}>
                    <div className="dialog-main dialog-width">

                        <div className="row-sm-12">

                            <div className=" d-flex" onClick={handleClose}>
                                <h6 className="cancelsym close ">&times;</h6>
                                <h6 className="closetext"> Close</h6>
                            </div>
                            <DialogTitle>
                                <p className="dialog-heading">Make decision</p>

                            </DialogTitle>

                            <DialogContent>

                                <p className="dialog-des">Send an interview schedule request to this applicant via
                                    email. </p>

                                <div className=" ">


                                    <div className="row date-and-time-input-box decisionRec"
                                         style={decision === 'Offer Admission' ? {borderColor: '#960C0C'} : {borderColor: 'rgba(43, 28, 28, 0.25)'}}
                                         onClick={() => setDecision("Offer Admission")}>
                                        <p className="decidetext">Offer Admission </p>
                                        <img className="decision-radio"
                                             src={decision === "Offer Admission" ? Check : UnCheck} alt={"..."}/>


                                    </div>

                                    <div className="row date-and-time-input-box decisionRec"
                                         style={decision === 'send' ? {borderColor: '#960C0C'} : {borderColor: 'rgba(43, 28, 28, 0.25)'}}
                                         onClick={() => setDecision("send")}>
                                        <p className="decidetext">Send to Community</p>
                                        <img className="send-radio" src={decision === "send" ? Check : UnCheck}
                                             alt={"..."}/>


                                    </div>


                                    <div className="row date-and-time-input-box decisionRec"
                                         style={decision === 'Not Sure' ? {borderColor: '#960C0C'} : {borderColor: 'rgba(43, 28, 28, 0.25)'}}
                                         onClick={() => setDecision("Not Sure")}>
                                        <p className="decidetext"> I’m still not sure</p>
                                        <img className="decision-radio" src={decision === "Not Sure" ? Check : UnCheck}
                                             alt={"..."}/>


                                    </div>

                                </div>
                                <br/> <br/>

                                < div className="d-flex">
                                    <button className="w-15-ns d-block  cancelterms" onClick={handleClose}>Cancel
                                    </button>
                                    <button className="w-15-ns d-block  closeterms" onClick={sendDecision}>Send
                                        Decision
                                    </button>
                                    {/* <br/><br/> */}
                                </ div>
                            </DialogContent>

                        </div>

                    </div>

                </Dialog>
            </div>
        </div>

    );

};