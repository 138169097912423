import React, {Component} from 'react';
import WhiteSplashHeader from "./WhiteSplashHeader";
import '../../../css/splash.css'
import {ReactComponent as Thumb} from '../../../images/thumbs-up-orangeorange.svg'


class TestSubmitted extends Component {
    // buttonClick() {
    //     // do something meaningful, Promises, if/else, whatever, and then
    //     window.location.assign('http://www.semicolon.africa');
    // }

    render() {

        return (
            <div className="bac newback vh-90">
                <WhiteSplashHeader/>
                <div className="container  d-flex justify-content-around align-items-center mt-md-5 ">
                    <div className="box bg-white align-self-md-start col-md-4  br4">
                        <div className="col-12 col-md-12 d-flex ">
                            <div className="thumb">
                                <Thumb/>
                            </div>
                        </div>

                        <div className="text-area">
                            <h1 className="submit-text">Amazing </h1>
                            <p className="p-text">You have successfully completed the Semicolon
                                Aptitude test. We will get back to you very soon.
                            </p>
                            <a href={`/dashboard/`}>
                                <button className="next-button">Dashboard</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TestSubmitted;