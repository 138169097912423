import React, {Component} from 'react';
import ButtonCardsArray from "./ButtonCardsArray";
import FileUpload from "./fileUpload";
import FormsArray from "./formsArray";
import UserDashBoardHeader from "../user/UserDashboard/UserDashBoardHeader";

import InfoSessionNewCard from "./InfoSessionNewCard";
import FormCard from "./FormCard";
import ChangeRoleCard from "./changeRoleCard";


class SystemAdminMainDashboard extends Component {
    //Button


    render() {
        // const {onOpen, user, imageURL, imageLoading} = this.props;
        let user = JSON.parse(sessionStorage.getItem("userData"));
        let imageURL = user.profilePictureUrl;
        let imageLoading = true;

        return (
            <div>
                <UserDashBoardHeader user={user} onOpen={this.openNav} imageLoading={imageLoading} imageURL={imageURL}/>


                <ButtonCardsArray className="pt-5"/>

                <br/> <br/>
                <div align='center' className="row">
                    <FormCard/>
                    <FileUpload/>
                    <InfoSessionNewCard/>
                    <ChangeRoleCard/>
                </div>
                <br/> <br/>
                <div>

                    <FormsArray/>

                </div>
                <div>


                </div>


            </div>

        );
    }
}

export default SystemAdminMainDashboard;