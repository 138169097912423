import React, {useEffect, useState} from 'react';
import '../../../css/modal.css';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {CancelIcon} from '../../Icons/CancelIcon';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import theme from "./theme";
import InfoSessionList from "./InfoSessionList";
import ScheduledInfoSession from "./ScheduledInfoSession";
import axios from "axios";
import {selectInfoSessionAPI} from "../../routes/Api's";


const useStyles = makeStyles({
    close: {
        boxShadow: 'none',
        height: 20 + '%',
    },

    paper: {
        height: '90%',
        top: '10%',
        width: 600,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },

    box: {
        padding: '40px 40px 0 40px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: "space-around",
    },
    scrollBar: {
        appearance: 'none',
    },
});



export default function InfoSessionModal({user, infoSessionList}) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [showScheduledInfoSession, setShowScheduledInfoSession] = useState(false);
    const [infoSessionId, setInfoSessionId] = useState(user.hasOwnProperty("infoSessionId") ? user.infoSessionId : "");

    useEffect(() => {
        if (infoSessionId !== "") setShowScheduledInfoSession(true);
    }, [])


    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')) return;
        (!open && infoSessionId) && setShowScheduledInfoSession(true);
        setOpen(open);
    };

    const handleReschedule = () => {
        setShowScheduledInfoSession(false)
    }

    const handleConfirmSession = (selectedInfoSessionId) => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        axios(selectInfoSessionAPI(selectedInfoSessionId), {headers: headers})
            .then(res => {
                setInfoSessionId(res.data.infoSessionId);
                sessionStorage.setItem("userData", JSON.stringify(res.data));
                setShowScheduledInfoSession(true);
            })
            .catch(err => {
                console.log(err.response)
                this.handleError(err)
            });
    }


    const list = () => (
        <Box role="presentation" className={classes.box}>
            <Grid container>
                <Grid item>
                    <Button
                        onClick={toggleDrawer(false)}
                        className={classes.close} startIcon={<CancelIcon/>}>
                        Close
                    </Button>
                </Grid>
            </Grid>
            <Box
                className={classes.scrollBar}
                style={{
                    height: 80 + '%',
                    marginLeft: 40,
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    paddingRight: 15,
                }}
                display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
                <Box style={{marginBottom: 40,}}>
                    <ThemeProvider theme={theme}>
                        <Typography variant="h5"
                                    style={{
                                        maxWidth: 350,
                                        marginBottom: 20,
                                        fontWeight: 'bold',
                                    }}>
                            {showScheduledInfoSession ? "Your upcoming information session" : "Kindly select your preferred session"}
                        </Typography>
                    </ThemeProvider>
                </Box>
                <Box style={{width: '100%',}}>
                    {(!showScheduledInfoSession) ?
                        <InfoSessionList
                            handleCreateSchedule={handleConfirmSession}
                            infoSessionList={infoSessionList} infoSessionId={infoSessionId}/> : <ScheduledInfoSession
                            infoSessionId={infoSessionId}
                            infoSessionList={infoSessionList}
                            handleReschedule={handleReschedule}
                        />}
                </Box>
            </Box>
        </Box>
    );


    function anchorTextColor() {
        return {
            color: 'white !important'
        };
    }


    return (
        <React.Fragment>
            <p
                className="mb-0"
                style={anchorTextColor()}
                onClick={toggleDrawer(true)}>
                {infoSessionId ? "View session schedule" : "Attend info session"}
            </p>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                classes={{paper: classes.paper}}
            >
                {list()}
            </SwipeableDrawer>
        </React.Fragment>
    );
}