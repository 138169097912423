import React, {Component} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// import MastersPage from "../formData/MastersPage";
import UniversityPage from "../formData/UniversityPage";
import MenuItem from "@material-ui/core/MenuItem";
import '../../../../css/forms.css'
import Undergraduate from "../formData/Undergraduate";

class FormMasters extends Component {
    render() {
        const {
            handleChange,
            // handleNameChange,
            mastersInstitutionField,
            mastersCourseField,
            mastersGradGrade,
            mastersGradYear,
            formData
        } = this.props

        const style = {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "26px",

            display: "flex",
            alignItems: "center",

            color: "#960C0C"
        }

        return (
            <div className="p-0 shad">
                <h4 style={style}>Masters</h4>
                <div>
                    <Autocomplete
                        freeSolo
                        autoSelect={true}
                        onChange={handleChange("mastersInstitutionField")}
                        value={mastersInstitutionField}
                        options={formData.higherInstitutions}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params}
                                       label={UniversityPage[0].question}
                                       margin="normal" variant="outlined"
                                       color="secondary"
                            />
                        )}
                    />


                    <div className="col-md-12 p-0">
                        <Autocomplete
                            freeSolo
                            autoSelect={true}
                            onChange={handleChange("mastersCourseField")}
                            options={formData.courses}
                            getOptionLabel={option => option}
                            value={mastersCourseField}
                            renderInput={params => (
                                <TextField {...params}
                                           label={UniversityPage[1].question}
                                           margin="normal" variant="outlined"
                                           size="medium"
                                           color="secondary"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col-md-6 pl-0">
                        <TextField
                            margin="normal"
                            select
                            value={mastersGradGrade}
                            label={UniversityPage[3].question}
                            color="secondary"
                            onChange={handleChange("mastersGradGrade")}
                            variant="outlined"
                        >
                            {UniversityPage[3].options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}

                        </TextField>
                    </div>
                    <div className="col-md-6 pr-0">
                        <TextField
                            margin="normal"
                            select
                            label={UniversityPage[2].question}
                            value={mastersGradYear}
                            color="secondary"
                            onChange={handleChange("mastersGradYear")}
                            variant="outlined"
                        >
                            {Undergraduate[3].options
                                .filter(option => !(option > new Date().getFullYear() + 4))
                                .map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}

                        </TextField>
                    </div>

                </div>
            </div>
        );
    }
}

export default FormMasters;