import React, {useState,useEffect} from 'react';
import {useParams} from 'react-router-dom'
import '../../../css/modal.css'
import '../../../css/fulldiv.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {ReactComponent as SuccessImage} from "../../../images/admissionOfferillus.svg";

export default function AdmissionOfferedAccepted(props) {

    const [open, setOpen] = React.useState(false);




    const applicantEmail = useParams()




    useEffect(()=>{

        setOpen(true);
    },[]);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.history.push(`/login`);
    };



    return (


        <div>

            <Dialog

                open={open}
                onClose={handleClose}>
                <div className="dialog-main dialog-width">

                    <div className="row-sm-12">

                        <div className="closeBox d-flex" onClick={handleClose}>
                            <h6 className="cancelsym close ">&times;</h6>
                            <h6 className="closetext"> Close</h6>
                        </div>
                        <DialogTitle className="dialog-heading">
                            <p className="inviteLabel ">Admission accepted!</p>
                        </DialogTitle>
                        <DialogContent>

                            <p className="inviteWord">Kindly make payments and upload proof of payment from your dashboard.</p>
                            {/*<br/>*/}
                            <p className="inviteWordRed"> We are excited to have you join our community!</p>

                            <div className="row ml-1">

                                <SuccessImage/>

                            </div>

                            <div className="row share">
                                follow us ON:
                            </div>
                            <div className="social d-flex">
                               <a href="https://www.linkedin.com/company/semicolonafrica"  className="social-link"> <span className="mr-3"> LinkedIn </span></a>
                                <a href="https://twitter.com/semicolonafrica"  className="social-link"><span  className="mr-3">Twitter</span></a>
                               <a href="https://www.instagram.com/semicolonafrica/" className="social-link"> <span  className="mr-3">Instagram</span></a>
                                </div>

                        </DialogContent>
                    </div>
                </div>
            </Dialog>
        </div>

    );

};
