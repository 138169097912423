import React, {Component} from 'react';
import axios from "axios";
import {
    allFormDataAPI,
    cloudinaryAPI,
    getAllAvailableInfoSessionList,
    getInterviewObject, getInterviewSchedule,
    postProfilePicture
} from "../components/routes/Api's";
import {switchComponent} from "../components/user/utils/displayUtil";
import {stopTest, submitAnyPreviousTest} from "./FunctionalHooks";

class UserDashboardHook extends Component {


    constructor(props) {
        super(props)
        this.state = {

            jwtToken: '',
            user: {},
            headers: "",
            questionnaireDetails: {},
            infoSessionList: [],
            infoSessionId: "",
            profilePictureUrl: "",
            imageLoading: false,
            interviewObject: {},
            showUserDetails: false,
        }
        this.state.user = JSON.parse(sessionStorage.getItem("userData"));
        this.state.headers = JSON.parse(sessionStorage.getItem("headers"));
    }


    async componentDidMount() {
        const {user, headers} = this.state;

        const applicantStatus = user.applicationStatus;

        this.loadAvailableInfoSessions();


        if (applicantStatus === "APPLIED") {
            await axios.get(allFormDataAPI).then(res => {
                sessionStorage.setItem('formData', JSON.stringify(res));
            });
        }
        if (applicantStatus === "TESTING") {
            const userObj = JSON.parse(sessionStorage.getItem('userData'));
            if (localStorage.getItem("userTest")) {
                await submitAnyPreviousTest();
            } else {
                stopTest(userObj.email);
            }

            await axios.get(allFormDataAPI).then(res => {
                sessionStorage.setItem('formData', JSON.stringify(res));
            });
        }


        if (user.hasOwnProperty("applicantQuestionnaire"))
            this.setState({questionnaireDetails: user.applicantQuestionnaire});


        if (user.hasOwnProperty("infoSessionId")) {
            this.setState({
                infoSessionId: user.infoSessionId,
            })
        }

        if (applicantStatus !== "APPLIED" && applicantStatus !== "QUESTIONNAIRE" && applicantStatus !== "TESTED" && applicantStatus !== "" && applicantStatus !== undefined) {

            if (applicantStatus === "INVITED_FOR_INTERVIEW") {
                axios(getInterviewObject(user.email), {headers: headers})
                    .then(res => {
                        this.setState({interviewObject: res.data})

                    })
                    .catch(err => this.handleError(err))
            } else {

                axios(getInterviewSchedule(user.email), {headers: headers})
                    .then(res => {
                        this.setState({interviewObject: res.data})

                    })
                    .catch(err => this.handleError(err))
            }
        }
    }

    loadAvailableInfoSessions = () => {

        const headers = JSON.parse(sessionStorage.getItem("headers"));
        axios(getAllAvailableInfoSessionList, {headers: headers})
            .then(res => {
                this.setState({infoSessionList: res.data});
            })
            .catch(err => {
                    this.handleError(err)
                }
            );


    }

    // routeOrView = () => {
    //     const user = this.state.user;
    //     const userStatus = user.applicationStatus;
    //     if (userStatus !== "APPLIED")
    //         this.setState({showUserDetails: true})
    //     else if (userStatus === "APPLIED")
    //         this.props.history.push(`/forms/${user.firstName}`)
    // }

    routeOrView = () => {
        const user = this.state.user;
        const userStatus = user.applicationStatus;
        if (userStatus !== "APPLIED") {
            this.setState({showUserDetails: true})
        } else if (userStatus === "APPLIED") {

            switchComponent("form")

            // this.props.history.push(`/login/`);
        }
    }

    backToDashboard = () => {
        this.setState({showUserDetails: false});
    }

    openNav = () => {
        const sideBar = document.querySelector('.user-right');
        sideBar.classList.add('user-right-in-view');
        const myDashboardContainer = document.querySelector('body');
        myDashboardContainer.classList.add('side-bar-pause');
    };

    uploadImage = async () => {
        const files =

            document.getElementById('file-input').files;
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('upload_preset', 'lampApply');
        this.setState({imageLoading: true})
        await axios.post(cloudinaryAPI, formData)
            .then(res => this.setState({profilePictureUrl: res.data.secure_url, imageLoading: false}));


        await axios.patch(postProfilePicture, {url: this.state.profilePictureUrl}, {headers: JSON.parse(sessionStorage.getItem("headers"))})
            .then(res => this.setProfilePicture(this.state.profilePictureUrl))
            .catch(err => {
                this.handleError(err)
            });

    }
    setProfilePicture = (profilePicture) => {
        this.state.user = JSON.parse(sessionStorage.getItem("userData"));
        this.state.user.profilePictureUrl = profilePicture;
        sessionStorage.setItem('userData', JSON.stringify(this.state.user));
        this.componentDidMount().then(r => "nothing");

    }


    onloadData = () => {

    }

    handleError = (err) => {

        switch (err.status) {
            case 401:
            case 403:
                this.props.history.push('/login');
                break;
            case 400:

                break;
            default:
                break;
        }
    };


    questionnaireButton = () => {
        if (this.state.user.applicationStatus === "APPLIED") {
            return {
                background: "#960c0c",
                border: "#960c0c solid 2px",
                color: "#fff",
                fontWeight: "bold"
            }
        } else {
            return {
                background: "#C3F8E8",
                border: "2px solid #00D689",
                color: "#156D47"
            }
        }
    };

    boardText = (prevStatus, currentStatus, nextStatus, buttonText) => {
        const {user} = this.state;
        const userStatus = user.applicationStatus;
        if (userStatus === 'APPLIED' && buttonText === 'Book Session') return 'Pending';
        if (userStatus === currentStatus) return buttonText;
        else if (userStatus === prevStatus) return "Pending";
        else if (userStatus === nextStatus) return "COMPLETED";
    };

    btnStyles = button => {
        const {user: {applicationStatus: userStatus, nativeState, canFillEnrollmentForm}} = this.state
        const upComing = {
            backgroundColor: "#960c0c",
            border: "#960c0c solid 2px",
            color: "#ffffff",
            fontWeight: "bold"
        }
        const completed = {
            background: "#C3F8E8",
            border: "2px solid #00D689",
            color: "#156D47",
            cursor: 'default'
        }
        const pending = {
            backgroundColor: "#2B1C1C",
            border: "#2B1C1C solid 2px",
            color: "#ffffff",
            cursor: 'default',
            pointerEvents: 'none',
        }

        switch (button) {
            case "QUESTIONNAIRE":
                if (userStatus === "APPLIED") {
                    return upComing
                } else {
                    completed.cursor = "pointer";
                    return completed
                }
            case "TEST":
                if (userStatus === "APPLIED") {
                    return pending;
                } else if (userStatus === "QUESTIONNAIRE") {
                    return upComing;
                } else {
                    return completed;
                }
            case "INTERVIEW":
                if (userStatus === "APPLIED" || userStatus === "QUESTIONNAIRE") {
                    return pending
                } else if (userStatus === "TESTED" || userStatus === "INVITED_FOR_INTERVIEW" || userStatus === "TESTING") {
                    return upComing;
                } else {
                    return completed;
                }
            case "PAYMENT":
                if (nativeState === 'PAID_TUITION' || nativeState === 'ENROLLMENT_FORM' || nativeState === 'PAYMENT_AGREEMENT') return {
                    ...completed,
                    cursor: 'pointer'
                }
                if (nativeState !== "PENDING") return upComing
                break;
            case "ENROLLMENT":
                if (nativeState === "ACCEPTED_ADMISSION" || nativeState === 'PAID_TUITION' && !canFillEnrollmentForm) {
                    return pending
                } else if (nativeState === 'PAID_TUITION' && canFillEnrollmentForm) {
                    return upComing
                } else if (nativeState === 'ENROLLMENT_FORM' || nativeState === 'PAYMENT_AGREEMENT' || nativeState === 'CHECKED_IN' || userStatus === 'NATIVE' || nativeState === 'NATIVE_AGREEMENT' || nativeState === 'TENANCY_AGREEMENT') {
                    return {...completed, pointerEvents: 'none'}
                }
                break
            case "PAYMENT_AGREEMENT":
                if ((nativeState === "ACCEPTED_ADMISSION" || nativeState === "PAID_TUITION") || userStatus === 'ENROLLED') {
                    return pending
                } else if (nativeState === "CHECKED_IN") {
                    return upComing
                } else if (nativeState === 'PAYMENT_AGREEMENT' || userStatus === 'NATIVE' || nativeState === 'NATIVE_AGREEMENT' || 'TENANCY_AGREEMENT') {
                    return {...completed, pointerEvents: 'none'}
                }
                break
            case "NATIVE_FORM":
                if (nativeState === "PAYMENT_AGREEMENT") return upComing
                else if (nativeState === "NATIVE_AGREEMENT" || nativeState === 'NATIVE' || nativeState === 'TENANCY_AGREEMENT') return {
                    ...completed,
                    pointerEvents: 'none'
                }
                else if (nativeState !== "PAYMENT_AGREEMENT")
                    return pending
                break
            case "TENANCY":
                const {canFillTenancyAgreementForm} = this.state.user
                if (!canFillTenancyAgreementForm)
                    return {...pending, cursor: 'not-allowed', pointerEvents: 'auto'}
                else if ((nativeState === 'CHECKED_IN' || nativeState === 'PAYMENT_AGREEMENT' || nativeState === 'ENROLLMENT_FORM') && canFillTenancyAgreementForm) return pending
                else if (nativeState === 'NATIVE_AGREEMENT' && canFillTenancyAgreementForm)
                    return upComing
                else if ((nativeState === 'NATIVE' && canFillTenancyAgreementForm) || nativeState === 'TENANCY_AGREEMENT') return {
                    ...completed,
                    pointerEvents: 'none'
                }
                break
            case "LOAN":
                const {canFillLoanAgreement} = this.state.user
                if (!canFillLoanAgreement) return {...pending, cursor: 'not-allowed', pointerEvents: 'auto'}
                else if ((nativeState === 'CHECKED_IN' || nativeState === 'PAYMENT_AGREEMENT' || nativeState === 'ENROLLMENT_FORM') && canFillLoanAgreement) return pending
                else if ((nativeState === 'NATIVE_AGREEMENT' && canFillLoanAgreement) || (nativeState === 'TENANCY_AGREEMENT' && canFillLoanAgreement)) return upComing
                else if (nativeState === 'LEARN_FUND' || nativeState === 'NATIVE') return {...completed, pointerEvents: 'none'}
                break
            default:
                return;
        }
    }

    showDone = (thumbs, theThumb) => {
        thumbs.filter(thumb => thumbs.indexOf(thumb) <= thumbs.indexOf(theThumb))
            .map(thumb => thumb.classList.add('thumb-active'));
    };

    deactivateDiv = () => {
        return {
            opacity: 0.4
        }
    };

    // @todo refactor We'd love to chat should come last
    getInterviewText = () => {
        const {user} = this.state;
        const userStatus = user.applicationStatus;
        if (userStatus === "APPLIED" || userStatus === "QUESTIONNAIRE" || userStatus === "TESTED" || userStatus === "TESTING") {
            return "We'd love to chat";
        } else if (userStatus === "INVITED_FOR_INTERVIEW" || userStatus === "ACCEPTED_INTERVIEW" || userStatus === "INVITED FOR INTERVIEW" || userStatus === "ACCEPTED INTERVIEW") {
            return `We are chatting on ${this.state.interviewObject.interviewDate}`;
        } else {
            return `We chatted on ${this.state.interviewObject.interviewDate}`;
        }
    }


}


export default UserDashboardHook