const firstPage = [
    {
        question: "Date Of Birth    ",
        type: "date",
        options: null,
        response: null
    }, {
        question: "Country of Residence",
        type: "drop down",
        options: null,
        response: null
    }, {
        question: "State of Origin",
        type: "drop down",
        options: null,
        response: null
    }, {
        question: "State of Residence",
        type: "drop down",
        options: null,
        response: null
    }, {
        question: "Able to commit to program fully?",
        type: "drop down",
        options: ["Yes", "No"],
        response: null
    }, {
        question: "Able to relocate to Lagos for the program?",
        type: "drop down",
        options: ["Yes", "No"],
        response: null
    }, {
        question: "Marital Status",
        type: "drop down",
        options: ["Married", "Single", "Other"],
        response: null
    },

    {
        question: "I am currently...",
        type: "drop down",
        options: ["Married", "Single"],
        response: null
    },

    {
        question: "Where do you work currently? (NYSC PPA applies too)",
        type: "solo",
        options: null,
        response: null
    },

    {
        question: "I am currently...",
        type: "drop down",
        options: ["Employed","Self Employed", "Unemployed", "Serving"],
        response: null
    },

    {
        question: "NYSC Status",
        type: "drop down",
        options: ["Completed", "Currently Serving", "Exempted", "N/A"],
        response: null
    }
,

]

export default firstPage