import React, {Component} from 'react';
// import { getAllApplicants, getElderApplicants } from '../components/routes/Api\'s';
// import axios from 'axios'
import {Link} from 'react-router-dom';
import { consoleLog } from '../components/admin/consoleLog';


class AdminDashboardClass extends Component {
    constructor(props) {
        super(props);
        this.filteredApps = []
        this.state={
            filtered:[],
            applicants:[],
            search:"",
            loading:false,
            currentPage: 1,
            showPerPage:10,
            pages:10,
            button1: 1,
            button2: 2,
            button3: 3,
            paginated:[]
        }
    }
    
    onNext =(e)=>{
        consoleLog("propPages", this.props.pages)
        if(this.state.button3 < this.props.pages - 1){
            this.setState({
                button1: this.state.button1 + 1,
                button2: this.state.button2 + 1,
                button3: this.state.button3 + 1,
                currentPage: this.state.currentPage + 1
            })
            
            consoleLog(this.state.currentPage)
        }
        
    }

    onPrev=()=>{
        if(this.state.button1> 1){
            this.setState({
                button1: this.state.button1 - 1,
                button2: this.state.button2 - 1,
                button3: this.state.button3 - 1
            })
            this.data = this.data + 1
        }
    }

    eachButton=id=>(e)=>{
        let button = document.getElementById(id)
        let buttons = document.querySelectorAll('.myBtn')
        this.setState({currentPage:id})
        consoleLog("CurrentPage:",this.state.currentPage)
        buttons.forEach(btn=>{
            btn.classList.remove('active')
            consoleLog(btn)
        })  
        consoleLog("buttonId",Number(button.id))
        button.classList.add('active')
        consoleLog("fromPaginationClass",this.allPages)
    }

    onSearch=(e)=>{
        this.setState({search:e.target.value})
    };

    closeBar =()=>{
        const labels = document.querySelectorAll('.off')
        const sideWidth = document.querySelector('#sideBar')
        const frontIcon = document.querySelector('#open')
        const backIcon = document.querySelector('#close')
        backIcon.style.display = "none"
        frontIcon.classList.toggle("tester")
        sideWidth.style.maxWidth = "70px"
        labels.forEach(label=>{
            label.style.display = "none"
        })
    }
    openBar =()=>{
        const labels = document.querySelectorAll('.off')
        const sideWidth = document.querySelector('#sideBar')
        const frontIcon = document.querySelector('#open')
        const backIcon = document.querySelector('#close')
        backIcon.style.display = "block"
        frontIcon.classList.toggle("tester")
        sideWidth.style.maxWidth = "240px"
        labels.forEach(label=>{
            label.style.display = "block"
        })
    }

   
addActive = (e) => {
    e.preventDefault();
    console.log(e.target);
    const activeIndicators = document.querySelectorAll('.active-indicator');
    consoleLog(activeIndicators);

    activeIndicators.forEach((activeIndicator) => {
        activeIndicator.classList.remove('active-indicator-fill');
    });
    activeIndicators[0].classList.add('active-indicator-fill');
 };
 
move=()=>{
    window.location.href = "/profile/:id"

}

paginateData=(()=>{
    const allApplicants =  JSON.parse(sessionStorage.getItem("allApplicants"))
    const countryLength = allApplicants.length
    const row = 10
    const MyPages = Math.ceil(countryLength/row)
    let allPages = []

    let i,j
     for(i=0, j =0; i< MyPages; i++){
        const start = row * j;
        // const end = start + row
        const paginate = allApplicants.splice(start,row)
        allPages[i] = paginate

    }
    this.setState({paginated:allPages})
    console.log("alPages",allPages)
    // console.log("paginated",this.state.paginated)
})

renderAllApplicants= (filteredApplicants)=>{
    consoleLog("filteredData",filteredApplicants)
    return(
        <tbody> 
                {filteredApplicants.map((data,index)=>{
                    return(
                    <Link to={`profile/${data.applicantDocument.email}`}>  <tr style={{cursor:"pointer"}}  className="trContent" key={index}>
                            <td>{index+1}</td>
                            <td>{data.firstName}</td>
                           <td>{data.lastName}</td>
                           <td>{data.phone}</td>
                           <td>{data.gender}</td>
                           <td>{data.applicantDocument.applicationStatus}</td>
                           <td>{data.applicantDocument.ageRange}</td>
                        </tr>
                    </Link>
                    
                    )
                })}
        </tbody>
    )
    
}

        
}


export default AdminDashboardClass;
