import React, {Component} from 'react';
import InterviewModal from "./InterviewModal";
import CommentModal from "./CommentModal";
import DecisionModal from "./DecisionModal";
import {withRouter} from "react-router-dom";
import ScheduledInterview from "./ScheduledInterview";
import '../../css/profile.css'


class StudentIdDiv extends Component {
    static propTypes = {

    };
    render() {


        const applicant = this.props.applicant
        const questionnaire = this.props.questionnaire
        const applicantDocument = this.props.applicantDocument
        return (

            <div>

                <div className="text-center ">
                    <p className="student-id">Student Id</p>
                </div>
                <div className="text-center student-border hr ">
                    <img alt={"pic div"} className="pic-div"/>
                    <div className="text-center font-weight-normal">
                        <p>{applicant.firstName}</p>
                    </div>
                    <div className="status text-center font-weight-bold">

                        <p>{applicant.applicationStatus}</p>
                    </div>
                </div>
                <br/><br/><br/>
                <div className="ml-4">
                    {applicant.applicationStatus==="INVITED_FOR_INTERVIEW"?  <ScheduledInterview />:<InterviewModal email={applicant.applicantDocument}/>}

                    <br/>
                    <CommentModal/>
                    <br/>
                    <DecisionModal/>
                    {/*{applicant.applicationStatus==="INVITED_FOR_INTERVIEW"?  <DecisionModal/>:""}*/}
                </div>
            </div>
        );
    };
}
export default withRouter (StudentIdDiv);
