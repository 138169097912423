import React from 'react'
import '../../../css/modal.css'
import '../../../css/fulldiv.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Check from "../../../images/check-circle.svg";
import UnCheck from "../../../images/uncheck-icon.svg";
import USSD from '../../../images/USSD.svg'
import Deposit from '../../../images/bank deposit.svg'
import Transfer from '../../../images/Bank transfer.svg'
import {cloudinaryAPI, sendPaymentDetail} from "../../routes/Api's";
import USSDModal from "./USSDModal";
import BankDepositModal from "./BankDepositModal";
import BankTransferModal from "./BankTransferModal";
import UploadSuccessfulModal from "./UploadSuccessfulModal";
import axios from "axios";


var openModal = false;

function PaymentModal(props) {
    const {open, handleClose,
        paymentOption, setPaymentOption,
        paymentData,handleChange, setDate,date,loadImage,imageName,sendPaymentDetails,displayFileName,loading,disableBtn, errMsg} = props



    return (

        <div>

            <div>

                <Dialog className=""

                        open={open}
                        onClose={handleClose}>
                    <div className="dialog-main dialog-width dialog-width-no-scroll">

                        <div className="row-sm-12">

                            <div className="closeBox d-flex" onClick={handleClose}>
                                <h6 className="cancelsym close ">&times;</h6>
                                <h6 className="closetext"> Close</h6>
                            </div>
                            <DialogTitle>
                                <p className="dialog-heading"> Kindly Confirm Payment</p>

                            </DialogTitle>

                            <DialogContent>

                                <p className="dialog-des">Choose from the options below. </p>

                                <div className=" ">


                                    <div className="row date-and-time-input-box decisionRec"
                                         style={paymentOption === 'ussd' ? {borderColor: '1px solid #FF7E1D'} : {borderColor: 'rgba(43, 28, 28, 0.25)'}}
                                         onClick={() => setPaymentOption("ussd")}>
                                        <img src={USSD} alt={"..."} width="40px"/>
                                        <p className="paymentText">USSD</p>
                                        <img className="ussd-radio"
                                             src={paymentOption === "ussd" ? Check : UnCheck} alt={"..."}/>


                                    </div>

                                    <div className="row date-and-time-input-box decisionRec"
                                         style={paymentOption === 'bank deposit' ? {borderColor: '#960C0C'} : {borderColor: 'rgba(43, 28, 28, 0.25)'}}
                                         onClick={() => setPaymentOption("bank deposit")}>
                                        <img src={Deposit} alt={"..."} width="40px"/>
                                        <p className="paymentText">Bank Deposit</p>
                                        <img className="send-radio" src={paymentOption === "bank deposit" ? Check : UnCheck}
                                             alt={"..."}/>


                                    </div>


                                    <div className="row date-and-time-input-box decisionRec"
                                         style={paymentOption === 'bank transfer' ? {borderColor: '#960C0C'} : {borderColor: 'rgba(43, 28, 28, 0.25)'}}
                                         onClick={() => setPaymentOption("bank transfer")}>
                                        <img src={Transfer} alt={"..."} width="40px"/>
                                        <p className="paymentText">Bank Transfer</p>
                                        <img className="transfer-radio"
                                             src={paymentOption === "bank transfer" ? Check : UnCheck}
                                             alt={"..."}/>


                                    </div>

                                </div>
                                <br/>

                                < div className="d-flex">
                                    <button className="w-15-ns d-block  cancelterms" onClick={handleClose}>Cancel
                                    </button>
                                    <div>
                                        {(() => {
                                            if (paymentOption === 'ussd') {
                                                return (
                                                    <USSDModal paymentData={paymentData} handleChange={handleChange}
                                                               loadImage={loadImage} date={date} setDate={setDate}
                                                               imageName={imageName}
                                                               sendPaymentDetails={sendPaymentDetails} displayFileName={displayFileName}
                                                               loading={loading}
                                                               disableBtn={disableBtn}
                                                               errMsg={errMsg}
                                                    />
                                                )
                                            } else if (paymentOption === 'bank deposit') {
                                                return (
                                                    <BankDepositModal title={'Bank Deposit'}
                                                                      info={'Upload proof of transaction either in JPEG, PNG or PDF'}
                                                                      paymentData={paymentData} handleChange={handleChange}
                                                                      loadImage={loadImage} date={date} setDate={setDate}
                                                                      imageName={imageName}
                                                                      sendPaymentDetails={sendPaymentDetails}
                                                                      loading={loading}
                                                                      disableBtn={disableBtn}
                                                                      errMsg={errMsg}
                                                    />
                                                )
                                            } else if (paymentOption === 'bank transfer') {
                                                return (
                                                    <BankTransferModal title={"Bank Transfer"}
                                                                      info={'Upload proof of transaction either in JPEG, PNG or PDF'}
                                                                       paymentData={paymentData} handleChange={handleChange}
                                                                       loadImage={loadImage} date={date} setDate={setDate}
                                                                       imageName={imageName}
                                                                       sendPaymentDetails={sendPaymentDetails}
                                                                       loading={loading}
                                                                       disableBtn={disableBtn}
                                                                       errMsg={errMsg}
                                                    />
                                                )
                                            }
                                        })()}

                                    </div>

                                </ div>
                            </DialogContent>

                        </div>

                    </div>

                </Dialog>
            </div>
        </div>

    );

}


export default function PaymentOptionModal(props) {


    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [paymentOption, setPaymentOption] = React.useState('empty')
    const [imageName, setImageName] = React.useState("")
    const [date, setDate] = React.useState("");
    const [disableBtn, setDisableBtn] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState("")

    const sendPaymentData ={
        paymentMethod: "",
        amountPaid:0,
        transactionDate: "",
        transactionImageLink: "",
        phoneNumber: "",
        depositorName: "",
        depositorAccountName: "",
        originatingBankName: "",
        depositorEmail: ""

    };
    const [paymentData, setPaymentData] = React.useState(sendPaymentData)
   const handleChange =e=>{
        const {name,value} = e.target;
        setPaymentData({...paymentData,[name]:value})
    }

    const loadImage = async () => {
        setLoading(true)
        const files = document.getElementById('raised-button-file').files;
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('upload_preset', 'lampApply');
        await axios.post(cloudinaryAPI, formData)
            .then(res =>{
                // console.log(res)
                paymentData.transactionImageLink = res.data.secure_url
                setImageName(res.data.original_filename +"." +res.data.format)
                setLoading(false)
            }).catch(err=>{
                // console.log(err)
                setLoading(false)
            });

    };

    const validateInput =()=>{
        const errMessage = document.querySelector("#errMessage");
        if(paymentOption==="ussd") {
            if (!paymentData.amountPaid) {
                errMessage.textContent = "Please Enter Amount"
                return false

            } else if (!date) {
                errMessage.textContent = "Please Enter Transaction Date"
                return false
            } else if (!paymentData.phoneNumber) {
                errMessage.textContent = "Please Enter Phone Number"
                return false
            }else {
                return true
            }
        }
        else if(paymentOption ==="bank deposit") {
                    if(!paymentData.amountPaid){
                        errMessage.textContent="Please Enter Amount"
                        return false

                    }
                    else if(!date){
                        errMessage.textContent="Please Enter Transaction Date"
                        return false
                    }
                    else if(!paymentData.depositorName){
                        errMessage.textContent="Please Depositor Name"
                        return false
                    }
                    else if(!paymentData.originatingBankName){
                        errMessage.textContent="Please Bank Name"
                        return false
                    }else{
                        return true
                    }

        }
        else if(paymentOption ==="bank transfer") {
            if(!paymentData.amountPaid){
                errMessage.textContent="Please Enter Amount"
                return false

            }
            else if(!date){
                errMessage.textContent="Please Enter Transaction Date"
                return false
            }
            else if(!paymentData.depositorAccountName){
                errMessage.textContent="Please Enter Account Name"
                return false
            }
            else if(!paymentData.originatingBankName){
                errMessage.textContent="Please Enter Bank Name"
                return false
            }
            else{
                return true
            }

        }
        else{
            return true;
        }

    };

    const sendPaymentDetails = (e) => {
        e.preventDefault();
        setDisableBtn(true)
        if(validateInput()) {
            const headers = JSON.parse(sessionStorage.getItem("headers"));

            let data = {
                paymentMethod: paymentOption,
                amountPaid: paymentData.amountPaid,
                transactionDate: (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "-" + (date.getMonth()+1 >= 10 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)) + "-" + date.getFullYear(),
                transactionImageLink: paymentData.transactionImageLink,
                phoneNumber: paymentData.phoneNumber,
                depositorName: paymentData.depositorName,
                depositorAccountName: paymentData.depositorAccountName,
                originatingBankName: paymentData.originatingBankName,
                depositorEmail: paymentData.depositorEmail

            }
            // console.log(data)

            axios.post(sendPaymentDetail, data, {headers: headers})
                .then(res => {
                    // openModal = true
                    alert("Uploaded Successfully")
                    handleClose()


                }).catch(err => {
                setErrMsg(err.response.data.message)
                setDisableBtn(false)
            })
        }
    };
    const  displayFileName =()=>{
        if(imageName===''){
            return <label>Choose from file</label>
        }
        else{
            return <label>{imageName}</label>
        }
    }


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);


    };






return (
    <div>
        <p onClick={handleOpen} className="m-0">Upload</p>
        <PaymentModal handleClose={handleClose} open={open} handleChange={handleChange} paymentOption={paymentOption}
                      paymentData={paymentData} setPaymentOption={setPaymentOption}
                      loadImage={loadImage} date={date} setDate={setDate} imageName={imageName}
                      sendPaymentDetails={sendPaymentDetails} displayFileName={displayFileName}
                      loading={loading}
                      disableBtn={disableBtn}
                      errMsg={errMsg}
        />
    </div>
)

};