import React, {Component} from 'react';
import {ReactComponent as SemLogo} from "../../../images/semfulllogo.svg";
import '../../../css/splash.css'
import {Link} from "react-router-dom";

class WhiteSplashHeader extends Component {
    render() {
        const user = JSON.parse(sessionStorage.getItem("userData"))
        return (
            <div className="rowl bac w-100">
                <div className="col-md-12 d-flex side-div side-new mb-2">
                    <div className="col-md-6 col-sm-6 col-6 mt-3 ">
                        <SemLogo width="220px"/>
                    </div>
                    <div className="container partners  col-md-6 mt-3 d-flex justify-content-end">
                        <div className="dropdown">
                            <p className="dropdown-toggle patDiv"
                               id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false">
                                Hi, {user.firstName}
                            </p>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to="#">Action</Link>
                                <Link className="dropdown-item" to="#">Another action</Link>
                                <Link className="dropdown-item" to="#">Something else here</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WhiteSplashHeader;