import React, {useState} from "react"
import UserDashBoardHeader from "../UserDashboard/UserDashBoardHeader";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from 'axios';
import {submitNativeAgreementForm} from "../../routes/Api's";

const useStyles = makeStyles(theme => ({
    parent: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: '100%',
        marginTop: '25vh'
    },
    formHolder: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: '50%',
        padding: '45px 55px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '10px 15px',
        }
    },
    checkbox: {
        marginTop: 15,
        '& > span.MuiTypography-body1': {
            lineHeight: '1.5 !important',
        }
    },
    backBtn: {
        padding: '12px 17px',
        background: "#FFF",
        border: '1px solid #FF2E2E',
        color: '#FF2E2E',
        transition: `all .5s ${theme.transitions.easing.easeInOut}`,
        '&:hover': {
            background: '#FF2E2E',
            color: '#FFF',
        }
    },
    submit: {
        padding: '12px 17px',
        background: "#FF2E2E",
        color: '#FFF',
        border: '1px solid #FF2E2E',
        transition: `all .5s ${theme.transitions.easing.easeInOut}`,
        '&:hover': {
            borderColor: '#960C0C',
            background: '#960c0c',
            color: '#FFF'
        }
    },
    btns: {
        maxWidth: 200,
        display: 'flex',
        justifyContent: 'space-between',
    }
}))

const TenancyForm = (props) => {
    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const classes = useStyles(props)
    const headers = JSON.parse(sessionStorage.getItem("headers"))
    const handleSubmit = e => {
        e.preventDefault()
        if (!checked) return
        setDisabled(true)
        axios.post(submitNativeAgreementForm('fillTenancyAgreement'), {agreedToTerms: checked}, {headers: headers})
            .then(res => {
                sessionStorage.setItem('userData', JSON.stringify(res.data))
                props.switchComponent('dashboard')
            })
            .catch(err => {
                console.log(err)
                setDisabled(false)
            })
    }

    return (
        <>
            <UserDashBoardHeader user={props.user} imageLoading={false}/>
            <div className={`w-100 ${classes.parent}`}>
                <div className={`shadow-3 p-5 ${classes.formHolder}`}>
                    <h5 className="text-center" style={{fontWeight: 'bold', color: "#960C0C"}}>
                        TERMS AND CONDITIONS FOR ACCOMMODATION PROVISION BETWEEN SEMICOLON VILLAGE LIMITED AND NATIVE.
                    </h5>
                    <h5 className="my-4"><strong>Welcome to Semicolon Africa!</strong></h5>
                    <p>These are the terms and conditions governing Semicolon and Natives provided with accommodation. These terms and conditions set out obligations of all occupants concerning the accommodation.</p>
                    <h6>
                        <strong>
                            ACCEPTING THE TERMS
                        </strong>
                    </h6>
                    <p>In order to occupy the provided accommodation, you must firstly agree to be bound by these terms and conditions. You may not have access to the accommodation offer if you do not accept the following terms:</p>
                    <ul>
                        <li>
                            Semicolon shall provide an accommodation for the Native which shall last through the period of the training of the Native as agreed in the tenancy agreement upon the payment of rent by the Native.
                        </li>
                        <li>
                            All fees paid as a Native are non-refundable.
                        </li>
                        <li>
                            Semicolon shall not be responsible for furnishing the provided accommodation.
                        </li>
                        <li>
                            Semicolon shall not be responsible for any utility bills such as electricity bill.
                        </li>
                        <li>
                            Semicolon shall not be responsible for any form of repair on the said property.
                        </li>
                        <li>
                            Semicolon reserves the sole discretion in relation to room allocation in the house.
                        </li>
                        <li>
                            The Native shall not be entitled to a refund of rent paid even if the Native does not occupy the provided accommodation for the stipulated period.
                        </li>
                        <li>
                            No native shall accommodate anyone that has not been allotted a space in the room.
                        </li>
                        <li>
                            The Native agrees to keep the apartment in a tenantable condition and promises to fix all damages that might have occurred during his/her stay.
                        </li>
                        <li>
                            Native shall keep the house clean and tidy through the tenancy period.
                        </li>
                        <li>
                            Native shall not remove any of the contents or fittings of the house
                        </li>
                        <li>
                            Native shall not engage in any act that is in contravention with the law.
                        </li>
                        <li>
                            Native shall respect the personal properties of fellow occupants and neighbors in general.
                        </li>
                        <li>
                            Native shall not create noise at a level which interferes with the comfort, study or sleep of another resident or the occupier of a neighboring property.
                        </li>
                        <li>
                            The Native shall allow Semicolon or its representatives access to the apartment at reasonable notice not less than 24 hours except in an emergency case.
                        </li>
                        <li>
                            Native shall give up immediate possession if for any reason they cease to be a native at Semicolon.
                        </li>
                        <li>
                            At the end of the training period, the Native shall give up immediate possession and shall remove all personal belongings.
                        </li>
                    </ul>
                    <FormControlLabel
                        className={classes.checkbox}
                        label="The Native agrees to have carefully read through these terms and conditions and agrees to be bound by them."
                        control={<Checkbox checked={checked} onChange={() => setChecked(!checked)}
                                           name="tAndC"/>}
                    />
                    <div className="stamp-div">
                        <img alt={"stamp"} src="https://res.cloudinary.com/semicolon-africa/image/upload/v1601575479/emailpng/small_seal_cropped_upejhj.png"/>
                        {/*<img alt={"stamp"} src={stamp}/>*/}
                    </div>
                    <div className={`${classes.btns} mt-5`}>
                        <button className={classes.backBtn} disabled={disabled} onClick={() => props.switchComponent('dashboard')}>Go back</button>
                        <button className={classes.submit} disabled={disabled} onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TenancyForm