import React, {Component} from 'react';
import {ReactComponent as SemLogo} from "../../../images/semfulllogo.svg";
import '../../../css/splash.css'

class SplashHeader extends Component {
    render() {
        return (
            <div className="row bac w-100">
                <div className="col-md-12 d-flex side-div mb-2">
                    <div className="col-md-6 col-sm-6 col-6 mt-3 mr-3">
                        <SemLogo width="220px"/>
                    </div>
                    {/* <div className="container partners col-md-6 mt-3 d-flex justify-content-end">*/}
                    {/*    <button className="btn  partners text-danger border-danger">Partner With Us</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default SplashHeader;