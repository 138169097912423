import React from "react"
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    container: {
        width: '80%',
        margin: '0 auto'
    },
    wrapper: {
        width: '50%',
        margin: '40px auto',
        color: '#FF2E2E',
    }
}))

const PaymentDeclinedPage = () => {
    const classes = useStyles()


    return (
        <div className={classes.container}>
            <div className={`${classes.wrapper} d-flex flex-column justify-content-center align-items-center h-100`}>
                <h2 className={'text-center'}>Payment Declined <br/> Successfully!</h2>
                <br/>
                <img
                    src="https://res.cloudinary.com/semicolon-africa/image/upload/v1588334599/emailpng/payment_ptalxw.png"
                    alt=""/>
                <a className={'btn btn-danger mt-5 btn-lg'} href="https://semicolonelder.herokuapp.com">Back to
                    dashboard</a>
            </div>
        </div>
    );

}

export default PaymentDeclinedPage