import React from "react";
import styles from '../../../css/userDetails.module.css';
import BackHomeIcon from '../../../images/backHomeIcon.svg';

const UserDetails = ({user, userDetails, backToDashboard}) => {

    const labels = {
        "First Name": user.firstName,
        "Last Name": user.lastName,
        "Email Address": user.email,
        "Gender": user.gender,
        "Age": userDetails.dateOfBirth,
        "Marital Status": userDetails.maritalStatus,
        "Nationality": userDetails.nationality,
        "State of Origin": userDetails.stateOfOrigin,
        "Country of Residence": userDetails.countryOfResidence,
        "State of Residence": userDetails.stateOfResidence,
        "Able to commit": userDetails.commitToProgram,
        "Able to relocate": userDetails.relocateForProgram,
        "I am currently": userDetails.iAmCurrently,
        "NYSC Status": userDetails.nyscStatus,
        "Alternate Contact": userDetails.alternatePhone,

        "WhatsApp": userDetails.whatsapp,
        "Twitter": userDetails.twitter,
        "Instagram" : userDetails.instagram,
        "Tiktok": userDetails.tiktok,
        "GitHub": userDetails.github,
        "LinkedIn": userDetails.linkedin,
       "Facebook": userDetails.facebook,
        "Telegram": userDetails.telegram,
        "Preferred Social Network": userDetails.mostPreferredSocialNetwork,
        "Programming Languages": userDetails.programmingLanguage,
        "Database": userDetails.database,
        "Frameworks": userDetails.frameworks,
        "Operating Systems": userDetails.operatingSystems,
        "Why I applied": userDetails.whyApply,
        "Highest Education": userDetails.highestEducation,
        "Doctorate Course of Study": userDetails.doctorateCourseField,
        "Doctorate Institution": userDetails.doctorateInstitutionField,
        "Doctorate Grade": userDetails.doctorateGradGrade,
        "Doctorate Year": userDetails.doctorateGradYear,
        "Masters Course of Study": userDetails.mastersCourseField,
        "Masters Institution": userDetails.mastersInstitutionField,
        "Masters Grade": userDetails.mastersGradGrade,
        "Masters Year": userDetails.mastersGradYear,
        "Undergraduate Institution": userDetails.underGradInstitution,
        "Undergraduate Course of Study": userDetails.underGradCourse,
        "Undergraduate Type": userDetails.underGradDegreeType,
        "Undergraduate Grade": userDetails.underGradGrade,
        "Undergraduate Year": userDetails.underGradGradYear,
    };


    const responseLabels = Object.keys(labels);

    const calculateAge = () => {

        if (labels["Age"] === undefined) return;
        let arrAge = labels["Age"].split('-');
        let [year, month, day] = arrAge;


        month = --month; //month counts from zero and not one, decrementing by one consolidates it

        let dateOfBirth = new Date(Number(year), Number(month), Number(day));
        let currentDate = new Date();

        let currentAge = currentDate.getFullYear() - dateOfBirth.getFullYear();
        let monthDifference = currentDate.getMonth() - dateOfBirth.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < dateOfBirth.getDate())) {
            currentAge--;
        }
        return currentAge;
    }

    labels["Age"] = calculateAge();

    const handleScroll = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 27)
            e.target.previousElementSibling.classList.add(styles.opacityBoxGone);
        else e.target.previousElementSibling.classList.remove(styles.opacityBoxGone);
    };


    return (
        <div className={`row h-100 user-left m-0`}>
            <div className={`horizontal-divider d-flex align-items-center pl-2`}>
                <div className={styles.goBack}>
                    <span className={`d-flex justify-content-around`} onClick={backToDashboard}><img src={BackHomeIcon}
                                                                                                     alt="..."/><span
                        className={styles.goBackText}>Back Home</span></span>
                </div>
            </div>
            <div className={`col-md-12 d-flex flex-column justify-content-center mx-auto ${styles.fullInfo}`}>
                <div className={`${styles.infoHeader}`}>
                    <h4>Full Info</h4>
                </div>
                <div className={`${styles.opacityBox}`}></div>
                <div className={`${styles.fullInfoInner} mx-auto`} onScroll={handleScroll}>
                    <div className={`row ${styles.details}`}>
                        {responseLabels.filter(label => labels[label] !== "").map((label, index) => {
                            let current = labels[label];
                            if (Array.isArray(current)) {
                                return (
                                    <div key={index} className={`col-md-6 ${styles.detailsItem}`}>
                                        <p className={styles.label}>{label}</p>
                                        <h5 className={styles.value}>{current.map((arrayRes, index) => {
                                            if (current.indexOf(arrayRes) === current.length - 1) return (
                                                <span key={index}>{arrayRes}</span>);
                                            else return (<span key={index}>{arrayRes}, </span>);
                                        })}</h5>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index} className={`col-md-6 ${styles.detailsItem}`}>
                                        <p className={styles.label}>{label}</p>
                                        <h5 className={styles.value}>{current}</h5>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UserDetails;