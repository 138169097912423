import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FourthPage from "../formData/fourthPage";
import FormDoctorate from "./FormDoctorate";
import FormMasters from "./FormMasters";
import FormUnderGraduate from "./FormUnderGraduate";

class Form4 extends Component {
    render() {
        const {
            onData,
            handleChange,
            handleNameChange,
            disabledChecker,
            inputChecker,
            highestEducation,
            doctorateCourseField,
            doctorateInstitutionField,
            doctorateGradGrade,
            doctorateGradYear,
            mastersCourseField,
            mastersInstitutionField,
            mastersGradGrade,
            mastersGradYear,
            underGradInstitution,
            underGradDegreeType,
            underGradCourse,
            underGradGrade,
            underGradGradYear,
            formData
        } = this.props

        const style = {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "26px",

            display: "flex",
            alignItems: "center",

            color: "#960C0C",
            paddingTop: "1.3rem"
        }

        function checkState(keyword1, keyword2, keyword3, keyword4) {
            if (highestEducation) {
                if (highestEducation === keyword1) {
                    return (
                        <div className="col-md-12">
                            <FormUnderGraduate
                                graduateLabelText="Undergraduate"
                                underGradInstitution={underGradInstitution} underGradDegreeType={underGradDegreeType}
                                underGradCourse={underGradCourse} underGradGrade={underGradGrade}
                                underGradGradYear={underGradGradYear}
                                onData={onData} disabledChecker={disabledChecker} formData={formData}
                                inputChecker={inputChecker} handleChange={handleChange}
                                handleNameChange={handleNameChange}
                            />
                        </div>
                    )
                } else if (highestEducation === keyword2) {


                    return (
                        <div className="col-md-12">
                            <FormMasters
                                mastersCourseField={mastersCourseField}
                                mastersInstitutionField={mastersInstitutionField} mastersGradYear={mastersGradYear}
                                mastersGradGrade={mastersGradGrade} formData={formData}
                                onData={onData} disabledChecker={disabledChecker}
                                inputChecker={inputChecker} handleChange={handleChange}
                                handleNameChange={handleNameChange}

                            />
                            <h4 style={style}>Undergraduate</h4>

                            <FormUnderGraduate
                                underGradInstitution={underGradInstitution} underGradDegreeType={underGradDegreeType}
                                underGradCourse={underGradCourse} underGradGrade={underGradGrade}
                                underGradGradYear={underGradGradYear}
                                onData={onData} disabledChecker={disabledChecker} formData={formData}
                                inputChecker={inputChecker} handleChange={handleChange}
                                handleNameChange={handleNameChange}
                            />
                        </div>
                    )
                } else if (highestEducation === keyword4) {
                    return (
                        <div className="col-md-12">
                            <FormUnderGraduate
                                graduateLabelText="Graduate"
                                underGradInstitution={underGradInstitution} underGradDegreeType={underGradDegreeType}
                                underGradCourse={underGradCourse} underGradGrade={underGradGrade}
                                underGradGradYear={underGradGradYear}
                                onData={onData} disabledChecker={disabledChecker} formData={formData}
                                inputChecker={inputChecker} handleChange={handleChange}
                                handleNameChange={handleNameChange}
                            />
                        </div>
                    )
                } else if (highestEducation === keyword3) {
                    return (
                        <div className="col-md-12">
                            <FormDoctorate
                                doctorateCourseField={doctorateCourseField} handleNameChange={handleNameChange}
                                doctorateInstitutionField={doctorateInstitutionField}
                                doctorateGradYear={doctorateGradYear}
                                doctorateGradGrade={doctorateGradGrade} formData={formData}
                                onData={onData} disabledChecker={disabledChecker}
                                inputChecker={inputChecker} handleChange={handleChange}

                            />
                            <FormMasters
                                mastersCourseField={mastersCourseField}
                                mastersInstitutionField={mastersInstitutionField} mastersGradYear={mastersGradYear}
                                mastersGradGrade={mastersGradGrade} formData={formData}
                                onData={onData} disabledChecker={disabledChecker}
                                inputChecker={inputChecker} handleChange={handleChange}
                                handleNameChange={handleNameChange}
                            />
                            <h4 style={style}>Undergraduate</h4>

                            <FormUnderGraduate
                                underGradInstitution={underGradInstitution} underGradDegreeType={underGradDegreeType}
                                underGradCourse={underGradCourse} underGradGrade={underGradGrade}
                                underGradGradYear={underGradGradYear}
                                onData={onData} disabledChecker={disabledChecker} formData={formData}
                                inputChecker={inputChecker} handleChange={handleChange}
                                handleNameChange={handleNameChange}
                            />
                        </div>
                    )
                }
                // }else if (highestEducation === keyword4) {
                //     return (
                //         <div className="col-md-12">
                //             <FormUniversity
                //                 universityCourseField={universityCourseField}
                //                 universityInstitutionField={universityInstitutionField} universityGradYear={universityGradYear}
                //                 universityGradGrade={universityGradGrade}
                //                 onData={onData} disabledChecker = {disabledChecker} formData = {formData}
                //                 inputChecker ={inputChecker} handleChange = {handleChange} handleNameChange ={handleNameChange}
                //             />
                //         </div>
                //     )
                // }
            }
        }

        return (
            <div className="row w-100">
                <div className="col-md-12">
                    <TextField
                        margin="normal"
                        select
                        label={FourthPage[0].question}
                        value={highestEducation}
                        color="secondary"
                        onChange={handleChange("highestEducation")}
                        variant="outlined"
                    >
                        {FourthPage[0].options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {checkState("Undergraduate", "Masters", "Doctorate", "Graduate")}
            </div>
        );
    }
}

export default Form4;