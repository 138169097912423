import React from 'react';
import '../../css/fulldiv.css';
import {Link} from "react-router-dom";
import {ReactComponent as AllApplicantsIcon} from "../../images/applicantsicon.svg";
import {ReactComponent as InterviewingIcon} from "../../images/interviewingicon.svg";
import {ReactComponent as InterviewedIcon} from "../../images/interviewedicon.svg";
import {ReactComponent as OfferedAdmissionIcon} from "../../images/Aptitude-Test.svg";
import {ReactComponent as DeclinedAdmissionIcon} from "../../images/declinedadmissionicon.svg";
import {ReactComponent as Enrolled} from "../../images/enrolled.svg"
import {ReactComponent as Clock} from "../../images/clock.svg"
import AdminDashboardClass from '../../hooks/AdminDashboardClass';

class AdminNav extends AdminDashboardClass {
    render() {
        return (
            <React.Fragment>
                <div id="applicant" onClick={this.addActive} className="col-12 sideBut float-left">
                    <a href="/dashboard/all-applicants">
                        <label className="float-left"><AllApplicantsIcon width="50px" className="ml-1"/></label>
                        <span className="off float-left">All Applicants</span>
                    </a>
                    <div className="active-indicator"></div>
                </div>
                {/* <br /> */}
                <div onClick={this.addActive} className="col-12 sideBut side-active float-left">
                    <a href="/profile/full-info">
                        <label className="float-left"><Clock width="50px" className="ml-1"/></label>
                        <span className="off float-left w-20">Interviews Scheduled</span>
                    </a>
                </div>
                <div className="col-12 sideBut side-active float-left">
                    <a href="/profile/full-info">
                        <label className="float-left"><InterviewingIcon width="50px" className="ml-1"/></label>
                        <span className="off float-left w-20">Interviewing </span>
                    </a>
                </div>
                <div className="col-12 sideBut side-active float-left">
                    <a href="/profile/quiz">
                        <label className="float-left"><InterviewedIcon width="50px" className="ml-1"/></label>
                        <span className="off float-left">Interviewed</span></a>
                </div>
                <div className="col-12 sideBut side-active float-left">
                    <Link to="/profile/activity-log">
                        <label className="float-left"><OfferedAdmissionIcon width="50px" className="ml-1"/></label>
                        <span className="off float-left">Offered Admission</span></Link>
                </div>
                <div className="col-12 sideBut side-active float-left">
                    <Link to="/profile/activity-log">
                        <label className="float-left"><DeclinedAdmissionIcon width="50px" className="ml-1"/></label>
                        <span className="off float-left w-20">Declined Admission</span></Link>
                </div>
                <div className="col-12 sideBut side-active float-left">
                    <Link to="/profile/activity-log">
                        <label className="float-left"><Enrolled width="50px" className="ml-1"/></label>
                        <span className="off float-left">Enrolled</span></Link>
                </div>

            </React.Fragment>
        );
    }
}

export default AdminNav;