import testImage5 from '../testImage/testImage5.png'
import testImage7 from '../testImage/testImage7.png'
import testImage6 from '../testImage/testImage6.png'
import testImage8 from '../testImage/testImage8.png'
import testImage9 from '../testImage/testImage9.png'
import testImage10 from '../testImage/testImage10.png'
import testImage11 from '../testImage/testImage11.png'
import testImage12 from '../testImage/testImage12.png'


const NumericReasoningQuestions = [
    {
        id: "three",
        question: `Divide 50 by 0.5 and add 20.`,
        image: "no image",
        options: ["25", "45", "75", "100", "120"],
        answer: `120`
    }, {
        id: "four",
        question: `A bat and a ball together cost N150. The bat costs N100 more than the ball. How much does the ball cost?`,
        image: "no image",
        options: ["N50", "N100", "N75", "N25"],
        answer: `N25`
    },
    {
        id: "five",
        question: `What is the difference between the average precipitation in Lagos and Kano?`,
        image: testImage5,
        options: ["64", "66", "68", "70"],
        answer: `68`
    }, {
        id: "six",
        question: `What proportion of rainfall in January occurred in Surulere?`,
        image: testImage6,
        options: ["26%", "30%", "31%", "35%"],
        answer: `26%`
    }, {
        id: "seven",
        question: `What is the value of Total's share in the Oil and Gas industry, in 2021 and 2022 combined?`,
        image: testImage7,
        options: ["N501k", "N534k", "N621k", "N1,035k"],
        answer: `N534k`
    },

    {
        id: "sixteen",
        question: `In an election between two candidates, one got 55% of the total valid votes, 20% of
                        the votes were invalid. If the total number of votes was 7500, the number of 
                            valid votes that the other candidate got was?`,
        image: "no image",
        options: ["2500", "2700", "2900", "3100"],
        answer: `2700`
    },
    {
        id: "seventeen",
        question: `if 5+3=28, 9+1=810, 8+6=214, 5+4=19 then 7+2=?`,
        image: "no image",
        options: ["72", "572", "59", "55"],
        answer: `59`
    },
    {

        id: "eighteen",
        question: `Mom and dad have four daughters and each daughter has one brother. How many people
                    are in the family?`,
        image: "no image",
        options: ["9", "14", "10", "7"],
        answer: `7`
    },
    {
        id: "twentyOne",
        question: `You want to purchase 3 Kobo stamps. You're at the post office and waiting in line for your turn.
                        How many 3 Kobo stamps will you get in a dozen?`,
        image: "no image",
        options: ["4", "12", "36", "9", "15"],
        answer: `12`
    },

    {
        id: "twentyThree",
        question: `Complete the series. 7 = 5, 8 = 5, 9 = 4, 17 = ?,  21 = 9, 22 = 9, 24 = 10, 99 = 10, 100 = 7`,
        image: "no image",
        options: ["8", "6", "7", "9"],
        answer: `9`
    },
    {
        id: "twentyFour",
        question: `Complete the series.  7 = 5, 8 = 5, 9 = 4, 16 = ?, 21 = 9, 22 = 9, 24 = 10, 99 = 10, 100 = 7 `,
        image: "no image",
        options: ["7", "9", "6", "5"],
        answer: `7`
    },

    {
        id: "thirty",
        question: `60 * 3 / x - 78 = 12`,
        image: "no image",
        options: ["1", "3", "2", "9", "4"],
        answer: `2`
    },
    {
        id: "thirtyOne",
        question: `X * 4 / 2 = 84`,
        image: "no image",
        options: ["45", "25", "46", "42", "22"],
        answer: `42`
    },

    {
        id: "thirtyTwo",
        question: `X * 2 / 8 = 35`,
        image: "no image",
        options: ["146", "141", "147", "140", "148"],
        answer: `140`
    },

    {
        id: "thirtyThree",
        question: `What number belongs in the red box`,
        image: testImage12,
        options: ["37", "47", "27", "57"],
        answer: `37`
    },

    {
        id: "thirtyFour",
        question: `What is the average of first five multiples of 12`,
        image: "no image",
        options: ["36", "38", "40", "42"],
        answer: `36`
    },

    {
        id: "thirtyFive",
        question: `A shopkeeper sold an article for NGN2500. If the cost price of the article is NGN2000, find the profit percent`,
        image: "no image",
        options: ["23", "25", "27", "29"],
        answer: `25`
    },

    {
        id: "thirtySix",
        question: `Average age of a group of 30 boys is 16 years. A boy of age 19 leaves the group and a new boy joins the group. If the new average age of the group is 15.8 years, find the age of the new boy.`,
        image: "no image",
        options: ["12 years", "13 years", "14 years", "15 years"],
        answer: `13 years`
    },

    {
        id: "thirtySeven",
        question: `What is the probability of getting an even number when a dice is rolled?`,
        image: "no image",
        options: ["1/2", "1/5", "1/3", "1/3"],
        answer: `1/2`
    },

    {
        id: "fortyOne",
        question: `What is the factorial of 5`,
        image: "no image",
        options: ["50", "120", "60", "15", "25"],
        answer: `120`
    },
    {
        id: "fortyTwo",
        question: ` What is 17/68 of 12.5% of 19/57 of 30/45 of 144`,
        image: "no image",
        options: ["1", "16", "0", "12", "8"],
        answer: `1`
    },
    {
        id: "fiftyTwo",
        question: `In a consignment of eggs 552 were cracked, which was 12 percent of the total consignment.
                How many eggs were in the consignment?`,
        image: "no image",
        options: ["4600", "6624", "46", "540"],
        answer: `4600`
    },

    {
        id: "fiftyFour",
        question: `Of the teams competing in the world archery championships, twenty percent are from Europe. Half as many are from the United states and one twentieth are from Africa. What fraction of teams are from neither Europe, the US or Africa?`,
        image: "no image",
        options: ["13/20", "15/20", "13/15", "13/17"],
        answer: `13/20`
    }
    ]
const LogicalReasoningQuestions =[
    {
        id: "one",
        question: `If you are running a race and pass the person in second position, what position would you be in now?`,
        image: "no image",
        options: ["1st", "2nd", "3rd", "4th"],
        answer: `2nd`
    },
    {
        id: "two",
        question: `Which is heavier, a ton of gold or a ton of silver?`,
        image: "no image",
        options: ["Gold", "Silver", "Don't Know", "Same"],
        answer: `Same`
    },

    {
        id: "nineteen",
        question: `The River Niger is the dividing line between Asaba and Onitsha.
                            If an airplane crashed exactly in the middle of the River Niger,
                                where would the survivors be buried?`,
        image: "no image",
        options: ["No where", "Asaba", "Onitsha", "River Niger", "Half in Asaba and half in Onitsha"],
        answer: `No where`
    },

    {
        id: "twenty",
        question: `How close of a relative would the sister-in-law of your dad's only brother be to you?`,
        image: "no image",
        options: ["Your cousin", "Your niece", "Your aunt", "Your mother", "Your dad's sister"],
        answer: `Your mother`
    },

    {
        id: "twentyTwo",
        question: `Which is heavier, a ton of feathers or a ton of silver coins?`,
        image: "no image",
        options: ["Silver coins", "Neither", "Feathers", "Don't know"],
        answer: `Neither`
    },
    {
        id: "twentyFive",
        question: `Ojo's father has four sons named East, West, North,...Guess what would be the name of the fourth?`,
        image: "no image",
        options: ["South", "Ojo", "Dapo", "Compass"],
        answer: `Ojo`
    },
    {
        id: "twentySix",
        question: `There are 3 apples and you take away 2 apples. How many do you have?`,
        image: "no image",
        options: ["3", "0", "1", "2"],
        answer: `2`
    },
    {
        id: "twentySeven",
        question: `Some months have 31 days, how many have 28?`,
        image: "no image",
        options: ["1", "12", "0", "4"],
        answer: `12`
    },
    {
        id: "twentyEight",
        question: `Can a man marry his widow's sister?`,
        image: "no image",
        options: ["Yes", "No"],
        answer: `No`
    },

    {
        id: "twentyNine",
        question: `I am standing on a hill and I see 5 cows, 4 sheep, and 6 chickens. How many feet are on the hill?`,
        image: "no image",
        options: ["48", "50", "15", "16"],
        answer: `50`
    },
    {
        id: "thirtyEight",
        question: `Ayo is Tayo's father. Who was born later?`,
        image: "no image",
        options: ["Ayo", "Tayo"],
        answer: `Tayo`
    },

    {
        id: "thirtyNine",
        question: `The giraffe is taller than the kangaroo but shorter than the palm tree. 
                   Which animal is the tallest?`,
        image: "no image",
        options: ["Kangaroo", "Palm tree", "Giraffe", "Ostrich"],
        answer: `Giraffe`
    },

    {
        id: "forty",
        question: `A father's child, a mother's child, yet no one's son. Who am I?`,
        image: "no image",
        options: ["My Father's Son", "My Mother's Son", "A Daughter", "My Parent's Son"],
        answer: `A Daughter`
    },
    {
        id: "fiftyThree",
        question: `A B C D E F G H
What letter is two to the right of the letter which is four to the
left of the letter which is immediately to the right of the letter
four to the right of the letter C?`,
        image: "no image",
        options: ["F", "D", "E", "A"],
        answer: `F`
    }
]
const VerbalReasoningQuestions = [
       {
        id: "eleven",
        question: `Being obliged to break confidentiality in a therapeutic relationship is always a direct 
                    result of the law.`,
        image: testImage11,
        options: ["True", "False", "Cannot Tell"],
        answer: `False`
    },
    {
        id: "twelve",
        question: `When a client reports their involvement in a serious crime, their therapist is legally
                    obliged to report this.`,
        image: testImage11,
        options: ["True", "False", "Cannot Tell"],
        answer: `False`
    },
    {
        id: "thirteen",
        question: `Therapists have some flexibility regarding what they deem serious enough to lead them
                    to breach confidentiality.`,
        image: testImage11,
        options: ["True", "False", "Cannot Tell"],
        answer: `True`
    }, {
        id: "fourteen",
        question: `Most therapists agree with the conditions the law places on them relating to breaching
                    client confidentiality.`,
        image: testImage11,
        options: ["True", "False", "Cannot Tell"],
        answer: `Cannot Tell`
    }, {
        id: "fifteen",
        question: `If a therapist breaches confidentiality about a serious issue without first informing
                    their client of their intentions, they are breaking the law.`,
        image: testImage11,
        options: ["True", "False", "Cannot Tell"],
        answer: `False`
    },

]
const AbstractReasoningQuestion = [

    {
        id: "eight",
        question: `The boxes run in a sequence from left to right. Determine which box (from A to F)
                    goes in the missing part of the sequence.`,
        image: testImage8,
        options: ["A", "B", "C", "D", "E", "F"],
        answer: `A`
    }, {
        id: "nine",
        question: `The boxes run in a sequence from left to right. Determine which box (from A to F)
                    goes in the missing part of the sequence.`,
        image: testImage9,
        options: ["A", "B", "C", "D", "E", "F"],
        answer: `D`
    }, {
        id: "ten",
        question: `The boxes run in a sequence from left to right. Determine which box (from A to F)
                    goes in the missing part of the sequence.`,
        image: testImage10,
        options: ["A", "B", "C", "D", "E", "F"],
        answer: `A`
    },


]

const ComputingQuestions = [
    {
        id: "fortyThree",
        question: ` _______ is the process of finding errors and fixing them within a program`,
        image: "no image",
        options: ["Compiling", "Executing", "Debugging", "Scanning"],
        answer: `Debugging`
    },
    {
        id: "fortyFour",
        question: ` _______ is the smallest unit of data`,
        image: "no image",
        options: ["Byte", "Bit", "Kilobit", "Kilobyte"],
        answer: `Bit`
    },
    {
        id: "fortyFive",
        question: `The physical/tangible component of a computer is called `,
        image: "no image",
        options: ["Output devices", "Hardware", "Touchable devices", "Input device"],
        answer: `Hardware`
    }
    ,
    {
        id: "fortySix",
        question: `The non-physical component of a computer is called `,
        image: "no image",
        options: ["Output devices", "Hardware", "Untouchable devices", "Software"],
        answer: `Software`
    },
    {
        id: "fortySeven",
        question: `SQL means`,
        image: "no image",
        options: ["Structured Query Language", "Structural Query Language", "Software Query Language", "Software Questions and Lexis"],
        answer: `Structured Query Language`
    }
    ,
    {
        id: "fortyEight",
        question: `HTML means`,
        image: "no image",
        options: ["Hyper Text Makeup Language", "Hyper Text Markup Language", "Hyper Test Markup Language", "Hi-Text Markup Language"],
        answer: `Hyper Text Markup Language`
    }
    ,
    {
        id: "fortyNine",
        question: `Data is sent into the computer through hardware devices collectively referred to as`,
        image: "no image",
        options: ["Touch Screens", "Scanner", "Input devices", "Microphone"],
        answer: `Input devices`
    }
    ,
    {
        id: "fifty",
        question: `Which is the odd one out?`,
        image: "no image",
        options: ["Joystick", "Scanner", "Printer", "Mouse"],
        answer: `Printer`
    }
    ,
    {
        id: "fiftyOne",
        question: `Which is the odd one out?`,
        image: "no image",
        options: ["Thumb Drive", "RAM", "CD/DVD disk", "Hard Drive"],
        answer: `RAM`
    }
    ,
    {
        id: "fiftyFive",
        question: `Which is the odd one out?`,
        image: "no image",
        options: ["Kilobits", "Kilobyte", "Megabit", "Megabite"],
        answer: `Megabite`
    }
]

const TestData = [

]

const secondTestData = [

]

//fisher yates shuffle algorithm
function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}


//shuffle all questions using the fisher yates shuffle algorithm
const shuffledNumericQuestion = shuffle(NumericReasoningQuestions);
const shuffledVerbalQuestion = shuffle(VerbalReasoningQuestions);
const shuffledComputingQuestions = shuffle(ComputingQuestions);
const shuffledAbstractQuestions = shuffle(AbstractReasoningQuestion);
const shuffledLogicalQuestions = shuffle(LogicalReasoningQuestions);

const applicantTestQuestions = [];
function prepareApplicantTestQuestionFrom(questionBank, numberOfQuestionsToExtractFromBank){
    let numberOfQuestionsExtracted = 0;
    while(numberOfQuestionsExtracted < numberOfQuestionsToExtractFromBank){
        applicantTestQuestions.push(questionBank[numberOfQuestionsExtracted])
        numberOfQuestionsExtracted++
    }
}
const NUMERIC_QUESTIONS_TO_SERVE = 5;
const VERBAL_QUESTIONS_TO_SERVE = 2;
const  ABSTRACT_QUESTIONS_TO_SERVE = 1;
const COMPUTING_QUESTIONS_TO_SERVE = 5;
const LOGICAL_QUESTIONS_TO_SERVE = 7;
prepareApplicantTestQuestionFrom(shuffledNumericQuestion, NUMERIC_QUESTIONS_TO_SERVE)
prepareApplicantTestQuestionFrom(shuffledVerbalQuestion, VERBAL_QUESTIONS_TO_SERVE)
prepareApplicantTestQuestionFrom(shuffledAbstractQuestions, ABSTRACT_QUESTIONS_TO_SERVE)
prepareApplicantTestQuestionFrom(shuffledComputingQuestions, COMPUTING_QUESTIONS_TO_SERVE)
prepareApplicantTestQuestionFrom(shuffledLogicalQuestions, LOGICAL_QUESTIONS_TO_SERVE)

export const FilteredData = shuffle(applicantTestQuestions);

