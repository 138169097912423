import React from 'react';
import Slider from '@material-ui/core/Slider';
import {withStyles,} from '@material-ui/core/styles';


const marks = [
    {
        value: 100,
    },
    {
        value: 88,
    },
    {
        value: 77,
    },
    {
        value: 65,
    },
    {
        value: 54,
    },
    {
        value: 42,
    },
    {
        value: 31,
    },
    {
        value: 20,
    },
    {
        value: 9,
    }
];

const MySlider = withStyles({
    root: {
        color: '#00D689',
        height: '250% !important',
        cursor: 'default'
    },
    thumb: {
        border: '#00D689 1px solid',
        height: '30px !important',
        width: '30px !important',
        marginLeft: '-14.2px !important',
        marginBottom: '-7px !important',
        backgroundColor: 'transparent',
        '&:hover, &:focus, &:active': {
            boxShadow: 'none',
        }
    },
    mark: {
        height: '17px !important',
        width: '17px !important',
        borderRadius: '50%',
        marginLeft: '-8px !important',
        marginTop: '7px !important',
        zIndex: 100,
        color: '#828282 !important',
    },
    rail: {
        width: '.5px !important',
    },
    track: {
        width: '.5px !important',
    }
})(Slider);

export default function ProgressSliderForLoanForm({user}) {
    const userStatus = user.applicationStatus

    const [value, setValue] = React.useState(0);

    const values = () => {
        switch (userStatus) {
            case "APPLIED":
            case "":
                return 100;
            case "QUESTIONNAIRE":
                return 87;
            case "TESTED":
            case "INVITED_FOR_INTERVIEW":
            case "TESTING":
            case "ACCEPTED_INTERVIEW":
            case "DECLINED_INTERVIEW":
            case "INTERVIEWING":
            case "INTERVIEWED":
            case "OFFERED_ADMISSION":
            case "DECLINED_ADMISSION":
            case "ADMISSION_REJECTED":
                return 77;
            case "ACCEPTED_ADMISSION":
                if (user.nativeState === "ACCEPTED_ADMISSION")
                    return 65;
                else if (user.nativeState === "PAID_TUITION" || user.nativeState === "ENROLLMENT_FORM")
                    return 54
                else if (user.nativeState === "PAYMENT_AGREEMENT")
                    return 42
                else return 65
            case "ENROLLED":
                return 42
            case "CHECKED_IN":
                if (user.nativeState === 'CHECKED_IN')
                    return 42
                else if (user.nativeState === 'PAYMENT_AGREEMENT') return 31
                else if ((user.nativeState === 'NATIVE_AGREEMENT' && user.canFillTenancyAgreementForm)) return 9
                else if (user.nativeState === 'NATIVE_AGREEMENT') return 31
                else if (user.nativeState === 'TENANCY_AGREEMENT') return 20
                break
            case "NATIVE":
                if (user.canFillLoanAgreement && user.canFillTenancyAgreementForm) return 9
                if (user.canFillTenancyAgreementForm) return 20
                return 31
        }
    };

    return (
        <MySlider
            orientation="vertical"
            value={values()}
            marks={marks}
            track="inverted"
            onChange={() => {
                if (userStatus === 'APPLIED') setValue(100);
                else if (userStatus === 'QUESTIONNAIRE') setValue(72);
                else if (userStatus === 'TESTED') setValue(43);
                else if (userStatus === 'TESTING') setValue(43);
            }}
        />
    )
}