import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import FullDiv from "./FullDiv";
import ProfileHeader from "./ProfileHeader";

// import ProfileHeader from "./ProfileHeader";

class Profile extends Component {
    render() {

        return (
            <React.Fragment>
                <ProfileHeader/>
                <FullDiv/>
                {/*<ProfileHeader />*/}
                {/*<AdminNavigation />*/}
            </React.Fragment>
        );
    }
}

export default withRouter(Profile);