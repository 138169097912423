import React from 'react';
import {ReactComponent as SemLogo} from "../../images/semfulllogo.svg";
import password from "../../images/password.svg"
import '../../css/splash.css'
import TextField from "@material-ui/core/TextField";
import CreatePasswordClass from "../../hooks/CreatePasswordClass";
import SignInButton from "./SignInButton";
import MenuItem from "@material-ui/core/MenuItem";
import WithdrawApplicationHook from "../../hooks/WithdrawApplicationHook";
import firstPage from "./forms/formData/firstPage";
import DoorIll from "../../images/door.svg";

class WithdrawApplication extends WithdrawApplicationHook {

    render() {
        const {whatIWant: iWantTo, myReason: because} = this.state;
        const whatIWantDropDown = {
            question: "I want to",
            type: "drop down",
            options: ["Unsubscribe for your Emails", "Withdraw my Application", "Other"],

        }
        return (
            <div className="form-container w-100 d-flex">
                <div className="left-form-div">
                    <div className="container d-flex justify-content-center align-items-center h-100">
                        <div className="d-flex left-content flex-column mx-auto pl-lg-5 h-100">
                            <div className="h-25 d-flex">
                                <div className="d-flex align-items-center" style={{marginBottom: 70 + 'px'}}>
                                    <SemLogo width="200px"/>
                                </div>
                            </div>
                            <div className="form-title"><h3>Unsubscribe from our Emails</h3></div>
                            <div>
                                <div className="my-form m-0">
                                    <form onSubmit={this.submitPassword}>
                                        <TextField
                                            margin="normal"
                                            select
                                            name="iWantTo"
                                            label={whatIWantDropDown.question}
                                            value={iWantTo}
                                            color="secondary"
                                            onChange={this.changeEvent}
                                            variant="outlined"
                                            required={true}
                                        >
                                            {whatIWantDropDown.options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                        <TextField className="newField"
                                                   type="text" name="because"
                                                   required={true} value={because}
                                                   onChange={this.changeEvent} fullWidth
                                                   label="Because" margin="normal"
                                                   variant="outlined" color="secondary"

                                        /><br/>
                                        <button className="d-block w-100 p-3 my-3 bt" ref={btn => {
                                            this.btn = btn;
                                        }} onSubmit={this.submitPassword} onKeyPress={this.onEnterPress}>Withdraw Application
                                        </button>
                                        <p id="err" className="text-danger"></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="right-ill-div login-right">
                    <SignInButton/>
                    <div className="col-md-12 ">
                        <img alt="doorIll" src={DoorIll} width="500px"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithdrawApplication;