import React, {Component} from 'react';
import '../../css/modal.css'

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import {changeRoleAPI} from "../routes/Api's";
import {toast, ToastContainer} from "react-toastify";

class ChangeRoleCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            newRole: "",


        };
    };


    render() {


        const newRoleDropDown = {
            role: ['Admin', 'Applicant', 'Elder', 'Suspended', 'Sponsor'],

        }
        let {email, newRole} = this.state;


        return (

            <div className="col-xl-3 col-md-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Change Role</h6>
                    </div>
                    <div className="card-body">

                        <div className="form-group mt-3">

                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label="Email "
                                           variant="outlined" name='email' value={email}
                                           color={"secondary"} className="newField" required
                                />



                            <TextField onChange={this.setOnChange}

                                       select label="Select  New Role" name='newRole'
                                       variant="outlined" value={newRole} margin="normal"
                                       color={"secondary"} required
                            >
                                {newRoleDropDown.role.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}

                            </TextField>
                            <ToastContainer/>


                        </div>
                        <button type="submit" className="w-100 d-block p-3 bt " onClick={this.changeUserRole}>Change Role
                        </button>

                    </div>
                </div>


            </div>
        );
    };

    changeUserRole = () => {
        const {email, newRole} = this.state;
        const data = "/?email=" + email + "&newRole=" + newRole.toUpperCase();
        axios.patch(changeRoleAPI + data, "", {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success(newRole + " Set Successfully");
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error("Role failed")
                toast.error(err.data)
            })
        ;

    };


    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
}

export default ChangeRoleCard;
