import React, {Component} from 'react';
import axios from "axios";
import {createPasswordAPI, wakeUpBackendServer} from "../components/routes/Api's";

class CreatePasswordClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            confirmPassword: "",
            user: ""
        }
    }

    changeEvent = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.submitPassword();
        }
    };

    componentDidMount() {
        this.wakeUpServer();
    }

    wakeUpServer = () => {
        axios.get(wakeUpBackendServer);
    }
    disableButtonPress = () => {
        this.btn.setAttribute("disabled", "disabled");
    };
    enableButtonPress = () => {
        this.btn.removeAttribute("disabled");

    };

    submitPassword = (e) => {
        e.preventDefault();

        this.disableButtonPress();
        const {match} = this.props;
        let errMessage = document.querySelector("#err");

        function emptyError() {
            errMessage.textContent = ""
        }

        errMessage.textContent = "";
        if (this.state.newPassword === this.state.confirmPassword && !(this.state.newPassword === "" && this.state.confirmPassword === "")) {
            axios.patch(`${createPasswordAPI(match.params.user)}`, {}, {
                headers: {
                    response: this.state.newPassword
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        this.props.history.push(`/password_successful`);

                    }
                }).catch(err => {
                if (err.response === undefined) {
                    errMessage.textContent = "Connect to the internet and try again";
                    this.enableButtonPress();
                } else {

                    errMessage.textContent = String(err.response.data.message);
                    this.enableButtonPress();
                }
            });

        } else {
            errMessage.textContent = "Passwords don't match";
            this.enableButtonPress();
        }

    };
}

export default CreatePasswordClass;