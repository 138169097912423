import React, {Component} from "react";
import PersonalInfoDetails from "./PersonalInfoDetails";
import HealthSafetyForm from "./HealthSafetyForm";
import axios from 'axios'
import {submitEnrollmentForm} from "../../routes/Api's";
import _ from 'lodash';

export class UserEnrollmentForm extends Component {
    state = {
        step: 1,
        btnDisabledState: false,
        fullLegalName: '',
        preferName: '',
        address: '',
        phone: '',
        // eduProExperience: '',
        yearExperience: '',
        industries: [],
        iTExperience: '',
        medicalConditions: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
        foodAllergies: '',
        hobbies: '',
        areYouInterestedInAccommodation:'',
        doYouHaveHealthInsurance:'',
        errMsg:'',


    }

    fullLegalName = `${this.props.user.firstName} ${this.props.user.lastName}`
    phone = `${this.props.user.phone}`

    componentDidMount() {
        this.setState({...this.state, fullLegalName: this.fullLegalName, phone: this.phone})
    }

    validateFormOnNext = () => {
        const errMessage = document.querySelector("#errMessage");
        if (!this.state.fullLegalName) {
            errMessage.textContent = "Please Fill your Full Name"
            return false;
        } else if (!this.state.preferName) {
            errMessage.textContent = "Please Fill your Prefer Name"
            return false;

        } else if (!this.state.address) {
            errMessage.textContent = "Please Fill your Address"
            return false;

        } else {
            return true;
        }
    }

    validateFormOnSubmit = () => {
        const errMessage = document.querySelector("#errMessage");
        if (!this.state.emergencyContactName) {
            errMessage.textContent = "Please Fill your Emergency Contact Name"
            return false;
        }
        else if(!this.state.emergencyContactPhone){
            errMessage.textContent="Please Fill your Emergency Contact Phone Number"
            return false;

        }
        else if(!this.state.doYouHaveHealthInsurance){
            errMessage.textContent="Please select health insurance option"
            return false;
        }
        else if(!this.state.areYouInterestedInAccommodation){
            errMessage.textContent="Please select accommodation option"
            return false;
        }


        else {
            return true;
        }
    }

    //next step
    nextStep = () => {
        if (this.validateFormOnNext()) {
            const {step} = this.state;
            this.setState({
                ...this.state,
                step: step + 1
            });
        }
    }
    //previous step
    prevStep = () => {
        const {step} = this.state;
        if (step === 1) {
            this.props.switchComponent('dashboard')
            return
        }
        this.setState({
            step: step - 1
        });

    }


    handleChangeMultiple = (event) => {
        const {options} = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({
            ...this.state,
            industries: value
        })
        ;
    };

    //submit form
    submitForm = (e) => {
        e.preventDefault();
        if (this.validateFormOnSubmit()) {
            this.setState({...this.state, btnDisabledState: true})
            const EnrollmentData = _.omit(this.state, 'step', 'btnDisabledState','errMsg');
            const headers = JSON.parse(sessionStorage.getItem("headers"));
            axios.post(submitEnrollmentForm, EnrollmentData, {headers: headers})
                .then(res => {
                    sessionStorage.setItem("userData", JSON.stringify(res.data))
                    this.props.switchComponent('dashboard')
                }).catch(err => {
                    this.setState({...this.state,errMsg:err.response.data.message})
                this.setState({...this.state, btnDisabledState: true})
            })

        }

    }

    //onChange method
    handleChange = input => e => {
        this.setState({[input]: e.target.value});
    }

    render() {
        const {step, btnDisabledState} = this.state;
        const {
            fullLegalName, preferName, address,
            phone, eduProExperience, workExperience,
            yearExperience, industries, iTExperience,
            medicalConditions, emergencyContactName, emergencyContactPhone,
            foodAllergies, hobbies,errMsg
        } = this.state;

        const values = {
            fullLegalName, preferName, address,
            phone, eduProExperience, workExperience,
            yearExperience, industries, iTExperience,
            medicalConditions, emergencyContactName, emergencyContactPhone,
            foodAllergies, hobbies,errMsg
        }

        switch (step) {
            case 1:
                return (

                    <PersonalInfoDetails
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        prevStep={this.prevStep}
                        fullLegalName={this.fullLegalName}
                        phone={this.phone}
                        values={values} step={step}/>
                );
            case 2 :
                return (
                    <HealthSafetyForm
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}
                        step={step}
                        btnState={btnDisabledState}
                        submitForm={this.submitForm}
                    />
                )


        }
    }

}

export default UserEnrollmentForm;