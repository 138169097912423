import React from 'react';
import Slider from '@material-ui/core/Slider';
import {withStyles,} from '@material-ui/core/styles';


const marks = [
    {
        value: 100,
    },
    {
        value: 72,
    },
    {
        value: 43,
    }
];

const MySlider = withStyles({
    root: {
        color: '#00D689',
        height: '100%',
        cursor: 'default'
    },
    thumb: {
        border: '#00D689 1px solid',
        height: '30px !important',
        width: '30px !important',
        marginLeft: '-14.2px !important',
        marginBottom: '-7px !important',
        backgroundColor: 'transparent',
        '&:hover, &:focus, &:active': {
            boxShadow: 'none',
        }
    },
    mark: {
        height: '17px !important',
        width: '17px !important',
        borderRadius: '50%',
        marginLeft: '-8px !important',
        marginTop: '7px !important',
        zIndex: 100,
        color: '#828282 !important',
    },
    rail: {
        width: '.5px !important',
    },
    track: {
        width: '.5px !important',
    }
})(Slider);

export default function ProgressSlider({user}) {
    const userStatus = user.applicationStatus
    const [value, setValue] = React.useState(100);

    const values = () => {
        switch (userStatus) {
            case "APPLIED":
            case "":
                return 100;
            case "QUESTIONNAIRE":
                return 72;
            case "TESTED":
            case "INVITED_FOR_INTERVIEW":
            case "TESTING":
            case "INTERVIEWING":
            case "ACCEPTED_INTERVIEW":
            case "ACCEPTED_ADMISSION":
            case "INTERVIEWED":
            case 'OFFERED_ADMISSION':
                return 43;
        }
    };

    return (
        <MySlider
            orientation="vertical"
            value={values()}
            marks={marks}
            track="inverted"
            onChange={() => {
                if (userStatus === 'APPLIED') setValue(100);
                else if (userStatus === 'QUESTIONNAIRE') setValue(72);
                else if (userStatus === 'TESTED') setValue(43);
                else if (userStatus === 'TESTING') setValue(43);
            }}
        />
    )
}