import React,{ Component} from "react";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from "@material-ui/core/TextField";
import '../../../css/forms.css'
import EnrollmentData from "./EnrollmentData";
import UserDashBoardHeader from "../UserDashboard/UserDashBoardHeader";
import { MenuItem} from "material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListItemIcon from "@material-ui/core/ListItemIcon";


export class PersonalInfoDetails extends Component{

    continue =e =>{
        e.preventDefault();
        this.props.nextStep();
    }

    PaperProps: {
        style: {
            maxHeight: 224,
            width: 280,
        },
    }








    render() {
        const {classes } = this.props;
        const { values, handleChange,step} = this.props;
        let user = JSON.parse(sessionStorage.getItem("userData"));
        let imageURL = user.profilePictureUrl;
        let imageLoading = false;
        return (
                <MuiThemeProvider>
                    <React.Fragment>
                        <div className="justify-content-center p-4">
                            <div className="container-fluid mb-5">
                             <UserDashBoardHeader user={user} onOpen={this.openNav} imageLoading={imageLoading} imageURL={imageURL}/>
                            </div>
                                <div className="col-md-5 vh-79 personalCon shadow-3 ">
                                    <div>
                                        <h3 style={{
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            fontSize: "24px",
                                            lineHeight: "34px",
                                            color: "#960C0C"
                                        }} className="text-danger text-center">Enrollment Form</h3>
                                    </div>

                                <div className="row formContainers justify-content-around">
                                    <form className="row w-100 ">
                                        <div>
                                            <h5 style={{
                                                fontStyle: "normal",
                                                fontWeight: 600,
                                                fontSize: "20px",
                                                lineHeight: "26px",
                                                color: "#960C0C",
                                                marginLeft: 20,
                                                marginTop:20
                                            }} className="text-danger ">Personal & Contact Information Section</h5>
                                        </div>
                                        <div className="col-md-12">
                                            <TextField
                                               label='Full legal name'
                                                margin="normal" variant="outlined"
                                                onChange={handleChange('fullLegalName')}
                                                defaultValue={this.props.fullLegalName}
                                               fullWidth
                                               type="text"
                                               color="secondary"
                                            />
                                        </div>
                                        <div className="col-md-12 row-spacing">
                                        <TextField
                                            label='Prefer to be called'
                                            margin="normal" variant="outlined"
                                            color="secondary"
                                            onChange={handleChange('preferName')}
                                            defaultValue={values.preferName}
                                            fullWidth

                                        />
                                        </div>
                                        <div className="col-md-12 row-spacing">
                                            <TextField
                                                label='Address'
                                                margin="normal" variant="outlined"
                                                color="secondary"
                                                onChange={handleChange('address')}
                                                defaultValue={values.address}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-md-12 row-spacing">
                                            <TextField
                                                label='Phone number'
                                                margin="normal" variant="outlined"
                                                color="secondary"
                                                type="text"
                                                onChange={handleChange('phone')}
                                                defaultValue={this.props.phone}
                                                fullWidth
                                                // inputProps={{
                                                //     max: 999999999999999,
                                                // }}
                                                // pattern="[0-9]*"
                                            />
                                        </div>
                                        <div>
                                            <h5 style={{
                                                fontStyle: "normal",
                                                fontWeight: 600,
                                                fontSize: "18",
                                                lineHeight: "26px",
                                                color: "#960C0C",
                                                marginLeft:20,
                                                marginTop:20
                                            }} className="text-danger ">Professional Experience</h5>
                                        </div>

                                        {/*<div className="col-md-12">*/}
                                        {/*    <TextField*/}
                                        {/*        label='Education & Professional Experience'*/}
                                        {/*        margin="normal" variant="outlined"*/}
                                        {/*        color="secondary"*/}
                                        {/*        onChange={handleChange('eduProExperience')}*/}
                                        {/*        defaultValue={values.eduProExperience}*/}
                                        {/*        fullWidth*/}
                                        {/*        type="text"*/}
                                        {/*    />*/}
                                        {/*</div>*/}

                                        <div className="col-md-12 row-spacing">
                                            <TextField
                                                label={EnrollmentData[0].question}
                                                margin="normal" variant="outlined"
                                                select
                                                size={"medium"}
                                                color="secondary"
                                                onChange={handleChange('yearExperience')}
                                                value={values.yearExperience}
                                                fullWidth>
                                                {EnrollmentData[0].options.map((option)=>(
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>

                                                    ))}
                                            </TextField>
                                        </div>
                                        <br/>
                                        <div className="col-md-12 row-spacing" >
                                            <FormControl variant="outlined"  className='d-flex industryHeight'>
                                                <InputLabel  id="demo-mutiple-checkbox-label"  className='d-flex industryHeight'>{EnrollmentData[1].question}</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={values.industries}
                                                    onChange={handleChange('industries')}
                                                    label={EnrollmentData[1].question}
                                                    className='d-flex'
                                                    // style={{
                                                    //     minWidth:240,
                                                    //     maxHeight:250
                                                    //
                                                    // }}

                                                    inputProps={{
                                                        name: 'industries',
                                                        id: 'outlined-age-native-simple',
                                                        className:''
                                                    }}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={this.PaperProps}
                                                >
                                                    {EnrollmentData[1].options.map((option) => (
                                                        <MenuItem key={option} value={option} className='d-flex multipleCheck'>
                                                            <ListItemIcon className="d-inline fl ">
                                                                <Checkbox checked={values.industries.indexOf(option) > -1} className="mt-0"  />
                                                            </ListItemIcon>

                                                            <ListItemText primary={option} className=" mt-5 pt-3" />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/*<div className="col-md-12">*/}
                                        {/*    <Autocomplete*/}
                                        {/*        freeSolo*/}
                                        {/*        multiple*/}
                                        {/*        value={values.industries}*/}
                                        {/*        onChange={handleChange('industries')}*/}
                                        {/*        options={EnrollmentData[1].question}*/}
                                        {/*        getOptionLabel={option => option}*/}
                                        {/*        defaultValue={values.industries}*/}
                                        {/*        renderInput={params =>(*/}
                                        {/*            <TextField {...params}*/}
                                        {/*                label={EnrollmentData[1].question}*/}
                                        {/*                margin="normal" variant="outlined"*/}
                                        {/*                color="secondary"*/}
                                        {/*                // onChange={handleChange('industries')}*/}

                                        {/*                fullWidth*/}
                                        {/*            />*/}

                                        {/*        )}*/}
                                        {/*    />*/}



                                        {/*</div>*/}
                                        <br/>
                                        <div className="col-md-12 row-spacing">
                                            <TextField
                                                label={EnrollmentData[2].question}
                                                margin="normal" variant="outlined"
                                                color="secondary"
                                                select
                                                size={"medium"}
                                                onChange={handleChange('iTExperience')}
                                                value={values.iTExperience}
                                                fullWidth
                                            >
                                                {EnrollmentData[2].options.map((option)=>(
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <p id={"errMessage"} className="text-danger text-center align-content-center"></p>
                                        <div className="buttons w-100 d-flex justify-content-between">
                                            <button className="prev-button back" onClick={this.props.prevStep}><i
                                                style={{marginRight: 15, width: 10,}} className="fas fa-arrow-left"> </i>Back
                                            </button>

                                            <p className="mt-4 centers">{step}/2</p>
                                            <button   onClick={this.continue}
                                                    className="prev-button submit p-3">Continue <i
                                                style={{marginLeft: 5, width: 10}} className="fas fa-arrow-right"> </i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>

                </MuiThemeProvider>

        )
    }

}export default PersonalInfoDetails;