import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import '../../css/fulldiv.css'
// import Summary from "../profile/Summary";
// import FullInfo from "../profile/FullInfo";
import AdminNav from "./AdminNav";
import SearchHeader from "./SearchHearder";
import AllApplicants from "./AllApplicants";
import AdminDashboardClass from '../../hooks/AdminDashboardClass';
import Pagination from './Pagination';
import axios from 'axios'
import {getAllApplicants} from "../routes/Api's";
// import { ADMIN_DASHBOARD } from '../routes/Routes';
// import ApplicantTable from "./ApplicantTable";


class AdminDashboardBody extends AdminDashboardClass {

    componentDidMount(){
        const elder =  JSON.parse(sessionStorage.getItem("admin"))
        const header =JSON.parse(sessionStorage.getItem('headers'))
        console.log(elder)
        const allApplicantData =  (async ()=>{
            this.setState({loading:true})
            await axios(getAllApplicants,{headers:header})
            .then(res=>{
                this.setState({applicants:res.data,loading:false})
                sessionStorage.setItem("allApplicants",JSON.stringify(res.data))
                console.log(res.data)
                this.paginateData()

            })
        })()
        //    this.filterAllApplicants()
    }

    // filterAllApplicants=(()=>{
    //     const allApplicants =  JSON.parse(sessionStorage.getItem("allApplicants"))
    //     this.filteredApps = this.allApplicants.filter((applicants)=>{
    //         return applicants.firstName.toLowerCase().includes(this.state.search.toLowerCase())
    //         || applicants.lastName.toLowerCase().includes(this.state.search.toLowerCase())
    //         || applicants.gender.toLowerCase().includes(this.state.search.toLowerCase())
    //         || applicants.phone.toLowerCase().includes(this.state.search.toLowerCase())
    //         || String(applicants.applicantDocument.email).toLowerCase().includes(this.state.search.toLocaleLowerCase())
    //     });
    //     console.log("filteredAppswee",this.filteredApps)
    //     // this.setState({filtered:filteredApplicants})
    //     // sessionStorage.setItem("filteredState",JSON.stringify(this.state.filtered))
    //     // console.log("filtered",this.state.filtered)
    //     // console.log("filteredApps",filteredApplicants)
    // })
   
    render() {
            this.filteredApps = this.state.applicants.filter((applicants)=>{
                return applicants.firstName.toLowerCase().includes(this.state.search.toLowerCase())
                || applicants.lastName.toLowerCase().includes(this.state.search.toLowerCase())
                || applicants.gender.toLowerCase().includes(this.state.search.toLowerCase())
                || applicants.phone.toLowerCase().includes(this.state.search.toLowerCase())
                || String(applicants.applicantDocument.email).toLowerCase().includes(this.state.search.toLocaleLowerCase())
            });
            console.log("filteredAppswee",this.filteredApps)
           
       
        // const allApplicants =  JSON.parse(sessionStorage.getItem("allApplicants"))
        // const {currentPage,showPerPage} = this.state
        // const indexOfLastApplicant =  currentPage * showPerPage
        // const indexOfFirstApplicant = indexOfLastApplicant - showPerPage
        // const paginatedApplicants = allApplicants.slice(indexOfFirstApplicant,indexOfLastApplicant)
        // console.log("paginated",allApplicants)

        // const allApplicants =  JSON.parse(sessionStorage.getItem("allApplicants"))
    // const countryLength = allApplicants.length 
    // const row =2
    // const MyPages = Math.ceil(countryLength/row)
    // let allPages = []
    
    // let i,j
    //  for(i=0, j =0; i< MyPages; i++){
    //     const start = row * j;
    //     // const end = start + row
    //     const paginate = allApplicants.splice(start,row)
    //     allPages[i] = paginate
        
    // }
    // console.log("allDashboardPages",allPages)
        
         
        // const allFilteredApps = allApplicants.filter(applicants=>{
        //     return applicants.firstName.toLowerCase().includes(this.state.search.toLowerCase()) 
        //     || applicants.lastName.toLowerCase().includes(this.state.search.toLowerCase())
        //     || applicants.gender.toLowerCase().includes(this.state.search.toLowerCase())
        //     || applicants.phone.toLowerCase().includes(this.state.search.toLowerCase())
        //     || String(applicants.applicantDocument.email).toLowerCase().includes(this.state.search.toLocaleLowerCase())
        // });
    //     console.log("workedUpData",allFilteredApps)
    //     // this.setState({filtered:this.filteredApps,pages:Math.ceil(allApplicants.length/this.state.showPerPage)})
    //     // const paginatedApplicants = JSON.parse(sessionStorage.getItem("paginatedApplicants"))
    //     // console.log("adminbodypagi",paginatedApplicants)
    //     console.log("allFilteredApps",allFilteredApps)
    //    const showRoute=()=>{
    //         if(window.location.href === ADMIN_DASHBOARD){
    //             return(
    //                 <Route path="/dashboard/all-applicants" exact component={()=>
    //                     <AllApplicants loading={this.state.loading} 
    //                     filteredApplicants={allFilteredApps } />} />
    //             )
    //         }
    //     }
     
        return (
            <React.Fragment >
                <Router>
                    <div className="full container-fluid row shadow-2 d-flex justify-content-around">
                        <div className="top-card"> <SearchHeader searched={this.onSearch} /> </div>
                        <div id="sideBar"  className="col-3  side-menu text-center mr-auto">
                            <AdminNav />
                        </div>
                        <div style={{flex:1}} className="container-fluid route-div  overflow-y-auto">
                        {/* {showRoute()} */}
                            <Router>
                                <Switch>
                                    {/* {showRoute()} */}

                                        <AllApplicants loading={this.state.loading} filteredApplicants={this.filteredApps } />}
                                            {/*<Route path="/dashboard/all-applicants" exact component={()=>*/}
                                    {/*<ApplicantTable/>} />*/}
                                    {/* <Route path="/dashboard/all-applicants" exact component={AllApplicants} />*/}
                                    {/*<Route path="/profile/full-info" component={FullInfo} />*/}
                                    {/*<Route path="/profile/summary" component={Summary} />*/}
                                </Switch>
                            </Router>
                        </div>
                        <div className="col-md-12 float-right">
                                <Pagination pages={this.state.pages} eachButton={this.eachButton} currentPage={this.state.currentPage} />
                        </div>
                    </div>
                </Router>
            </React.Fragment>

        );
    }
}

export default AdminDashboardBody;
