import React, {useEffect} from 'react';
import '../../css/modal.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DatePicker from "react-datepicker";
import TimeIcon from '../../images/Time.svg';
import DateIcon from '../../images/Date.svg';
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import {rescheduleInterview} from "../routes/Api's";


export default function RescheduleInterviewModal(props) {
    const [open, setOpen] = React.useState(false);
    const [inviteDate, setStartDate] = React.useState(null);
    const [inviteTime, setStartTime] = React.useState(null);
    const [serverResponse, setResponse] = React.useState('');


    useEffect(()=>{
        handleOpen()
    },[])

    const sendInvite ={
        applicantEmail:"",
        interviewDate: "",
        interviewTime: "",
        interviewMethod: "ONLINE"

    };
    const [inviteData, setInvite]=React.useState(sendInvite)
    const  applicant = props.match.params.id
     console.log(applicant)

    const admin =JSON.parse(sessionStorage.getItem('headers'))
    const handleOpen = () => {
             setOpen(true)


        // setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        props.history.push(`/login`);
    };
    const handleChange =e=>{
        const {name,value} = e.target;
        setInvite({...inviteData,[name]:value})
    }
    const DatepickerInput = ({ ...props }) => (
        <input type="text" {...props} readOnly />
    );



    function rescheduleApplicantForInterview() {
        let data = {
            // applicantEmail:applicant,
            interviewDate: (inviteDate.getDate() < 10 ? "0" + inviteDate.getDate() : inviteDate.getDate()) + "-" + ((inviteDate.getMonth() + 1) < 10 ? "0" + (inviteDate.getMonth() + 1) : (inviteDate.getMonth() + 1)) + "-" + inviteDate.getFullYear(),
            interviewTime: inviteTime.toString().substr(16, 5),
            interviewMethod: inviteData.interviewMethod
        };
        console.log(data)



        axios.post(rescheduleInterview(applicant),data).then(res=>{
            console.log(res.data)
            props.history.push(`/invite-successful`);
        }).catch(err=>{
            console.log(err)
            setResponse(err.response.data.message)
            console.log(serverResponse)
        });
        handleOpen();

    }


    return (

        <div>

            <Dialog className=""
                open={open}
                >
                <div className="dialog-main dialog-width">



                    {/*<div className="closeBox d-flex" onClick={handleClose}>*/}
                    {/*    <h6  className="cancelsym close ">&times;</h6>*/}
                    {/*    <h6 className="closetext"> Close</h6>*/}
                    {/*</div>*/}
                    <div className="d-flex flex-column justify-content-center ml-3">
                        <DialogTitle>
                            <p className="dialog-heading">Reschedule Your Interview</p>
                            
                        </DialogTitle>
                        
                        <DialogContent>
                            <div className="text-danger">
                            {serverResponse}
                            </div>

                        <p className="dialog-des pa0 ">You have been scheduled for an interview with the Semicolon team.</p>

                            <div className="row date-and-time-input-box ">
                            {/* <i className="calender-icon "> </i> */}
                            <DatePicker className="date d-block" selected={inviteDate}  onChange={date => setStartDate(date)} withPortal customInput={<DatepickerInput />}
                               placeholderText="Set New Date" dateFormat='dd-MM-yyyy' minDate={new Date()} />
                                <img src={DateIcon} alt="..." />
                            </div>

                            <div className="row date-and-time-input-box ">
                                <DatePicker className="time d-block" selected={inviteTime}  onChange={time => setStartTime(time)} withPortal customInput={<DatepickerInput />}
                                            placeholderText="Set New Time"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="time"

                                            timeFormat="HH:mm"
                                            dateFormat="h:mm aa" />
                                <img src={TimeIcon} alt="..." />
                            </div>


                            < div className="d-flex mt-3">
                        <button className="w-15-ns d-block  cancelterms" onClick={handleClose}>Cancel</button>
                        <button className="w-15-ns d-block  closeterms" onClick={rescheduleApplicantForInterview}>Submit</button>
                        </ div>
                        </DialogContent>
                        
                    </div>
                    
                </div>
                
            </Dialog>
        </div>
    );

};


