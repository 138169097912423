import React, {Component} from 'react';
import {ReactComponent as Logo} from '../../images/logo.svg';
// import '../../css/adminNav.css'
import '../../css/profile.css'

class ProfileHeader extends Component {
    render() {
        return (
            <React.Fragment>
                <nav className="container-fluid row bg-light d-flex " height="80px">
                    <div className="col-3  pt-3"><Logo width="200px" height="60px"/></div>
                    <div className="col-9 ">
                        <ul className="d-flex justify-content-end list-unstyled mr-auto">
                            <li className="mr-md-5 mt-5 ">Register an Applicant</li>
                            <li className="mr-md-5 mt-5 ">Applicant Records</li>
                            <li className="mt-5 dropdown-toggle">Hello, Sam</li>
                        </ul>
                    </div>
                </nav>

            </React.Fragment>
        );
    }
}

export default ProfileHeader;