import React from 'react';
import '../../../css/modal.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DatePicker from "react-datepicker";
import UploadIcon from '../../../images/upload.svg';
import DateIcon from '../../../images/calendar.svg';
import "react-datepicker/dist/react-datepicker.css";
import PaymentButton from "./PaymentButton";
import CircularProgress from "@material-ui/core/CircularProgress";


export default function BankDepositModal(props) {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
             setOpen(true)

    };

    const handleClose = () => {
        setOpen(false);
    };

    const DatepickerInput = ({ ...props }) => (
        <input type="text" {...props} readOnly />
    );


    return (


        <div>
            <div>
                <button className="w-15-ns d-block  closeterms" onClick={handleOpen}>Next
                </button>

            </div>
            <Dialog className=""

                open={open}
                onClose={open}>
                <div className="dialog-main dialog-width">



                    <div className="closeBox d-flex" onClick={handleClose}>
                        <h6  className="cancelsym close ">&times;</h6>
                        <h6 className="closetext"> Close</h6>
                    </div>
                    <div className="d-flex flex-column justify-content-center ml-3">
                        <DialogTitle>
                            <p className="dialog-heading">{props.title}</p>

                        </DialogTitle>

                        <DialogContent>

                        <p className="ussd-des">{props.info}</p>

                            <div className="row date-and-time-input-box upload ">
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                    placeholder={" Choose from file"}
                                    name="transactionImageLink"
                                    className={"amount"}
                                    onChange={props.loadImage}
                                />
                                {props.imageName ==="" ?

                                    <label htmlFor="raised-button-file" className="uploadLabel">Choose from file

                                        <img src={UploadIcon} alt="..." />
                                        {props.loading && <CircularProgress color="secondary"/>}

                                    </label>
                                    :<label htmlFor="raised-button-file" className="uploadLabel">{props.imageName}

                                        {/*<img src={UploadIcon} alt="..." />*/}
                                        {props.loading && <CircularProgress color="secondary"/>}

                                    </label>
                                }

                            </div>

                            <div className="row date-and-time-input-box ">
                            <DatePicker className="date transLabel d-block " selected={props.date}  onChange={date => props.setDate(date)}
                                        withPortal customInput={<DatepickerInput />} placeholderText="Transaction Date" dateFormat='dd-MM-yyyy' />
                                <img src={DateIcon} alt="..." />
                            </div>
                            <div className="row ">
                                <input type="number" name="amountPaid" required  onChange={props.handleChange} placeholder={"Amount"} className={"amount"}/>

                            </div >

                            <div className="row ">
                                <input type="text" placeholder={"Name of Depositor"} name="depositorName"  onChange={props.handleChange} className={"amount"}/>

                            </div >

                            <div className="row ">
                                <input type="text" placeholder={"Bank Name "}  onChange={props.handleChange} name="originatingBankName" className={"amount"}/>

                            </div >
                            <div>

                            </div>
                            <p id={'errMessage'} className="text-danger"></p>

                        <PaymentButton  disableBtn={props.disableBtn} sendPaymentDetails={props.sendPaymentDetails}/>
                        </DialogContent>

                    </div>

                </div>

            </Dialog>
        </div>
    );

};


