import React from 'react';
import './css/fulldiv.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import SystemAdminDashboard from "./components/systemAdmin/SystemAdminMainDashboard";
import Register from "./components/user/Register";
import CreatePassword from "./components/user/CreatePassword";
import RedirectFromElderDashBoard from "./hooks/RedirectFromElderDashBoard";

import Login from "./components/user/Login";
import ApplicationSubmitted from "./components/user/SplashScreens/ApplicationSubmitted";
import PartnerApplicationSubmitted from "./components/user/SplashScreens/PartnerApplicationSubmitted";
import CreatePasswordSuccessful from "./components/user/SplashScreens/CreatePasswordSuccesful";
import InviteSuccessful from "./components/user/SplashScreens/InviteSuccessful";

import TestSubmitted from "./components/user/SplashScreens/TestSubmitted";
import FormSubmitted from "./components/user/SplashScreens/FormSubmitted";
import WithdrawSuccessful from "./components/user/SplashScreens/WithdrawSuccessful";
import WithdrawApplication from "./components/user/WithdrawApplication";
import InterviewInviteSuccess from './components/user/SplashScreens/InterviewInviteSuccess'
import ResetPassword from "./components/user/ResetPassword";
import InfoSessionInvite from "./components/user/InfoSessionInvite";
import AdmissionOfferedAccepted from "./components/user/SplashScreens/AdmissionOfferAccepted";
import AdmissionOfferDeclined from "./components/user/SplashScreens/AdmissionOfferDeclined";

import AdmissionOfferExpired from "./components/user/Admissionerrors/AdmissionOfferExpired";
import AlreadyDecided from "./components/user/Admissionerrors/AlreadyDecided";
import AlreadyInvited from "./components/user/Admissionerrors/AlreadyInvited";


// import UserProfile from "./components/user/UserProfile";
import ResetPasswordCreated from "./components/user/SplashScreens/ResetPasswordCreated";
import Partner from "./components/partner/Partner";
import HiringPartner from "./components/hiringPartner/HiringPartner";
import PaymentOptionModal from "./components/user/UserDashboard/PaymentOptionModal";

import {
    ADMIN_DASHBOARD,
    ADMISSION_OFFER_ACCEPT,
    ADMISSION_OFFER_DECIDED,
    ADMISSION_OFFER_EXPIRED,
    ADMISSION_OFFER_REJECTED,
    APPLICATION_SUCCESSFUL,
    CONFIRM_PAYMENT,
    CREATE_PASSWORD,
    ENROLL,
    ENROLLMENT_DATA_SUBMITTED,
    EXPIRED,
    FORM_SUBMITTED,
    HIRE_AN_ENGINEER,
    INFOSESSION_INVITE_SUCCESSFUL,
    INFOSESSION_SELF_INVITE,
    INTERVIEW_INVITE_ALREADY_INVITED,
    INTERVIEW_INVITE_SUCCESS,
    LOGIN,
    NOTFOUND,
    PARTNER,
    PARTNER_APPLICATION_SUCCESSFUL,
    PASSWORD_SUCCESSFUL,
    PAYMENT_AGREEMENT_FORM,
    PROFILE,
    REDIRECT_FROM_ELDER,
    RESCHEDULE_INTERVIEW,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESSFUL,
    STAY_UPDATED,
    SYSTEM_ADMIN_DASHBOARD,
    TEST_SUCCESSFUL,
    TOOEARLY,
    UPDATED_SUCCESSFULLY,
    USER_DASHBOARD,
    USSD_MODAL,
    WITHDRAW_APPLICATION,
    WITHDRAW_SUCCESSFUL

} from "./components/routes/Routes";
import AdminMainDashboard from "./components/admin/AdminMainDashboard";
import Profile from "./components/profile/Profile";
import NotFoundPage from "./components/errorPage/NotFoundPage";
import InfoSessionExpired from "./components/user/infosessionerrors/InfoSessionExpired";
import InfoSessionTooEarly from "./components/user/infosessionerrors/InfoSessionTooEarly";
import DisplayManager from "./components/user/DisplayManager";
import RescheduleInterviewModal from "./components/user/RescheduleInterviewModal";
import USSDModal from "./components/user/UserDashboard/USSDModal";
import UserEnrollmentForm from "./components/user/Enrollment/UserEnrollmentForm";
import EnrollmentFormSubmitted from "./components/user/SplashScreens/EnrollmentFormSubmitted";
import PaymentAgreementForm from "./components/user/Enrollment/PaymentAgreementForm";
import PaymentConfirmationPage from "./components/user/SplashScreens/PaymentConfirmationPage";
import PaymentDeclinedPage from "./components/user/SplashScreens/PaymentDeclinedPage";
import PaymentAlreadyAttendedPage from "./components/user/SplashScreens/PaymentAlreadyAttendedPage";
import StayUpdatedPage from "./components/lampLaunch/StayUpdated";
import StayUpdatedSuccessful from "./components/user/SplashScreens/StayUpdatedSuccessful";

// import InterviewInviteSuccess from "./components/user/SplashScreens/InterviewInviteSuccess";

function App() {
    return (
        <React.Fragment>
            {/*<FormSlider />*/}
            <Router>
                <Switch>
                    <Route path={APPLICATION_SUCCESSFUL} exact component={ApplicationSubmitted}/>
                    <Route path={WITHDRAW_SUCCESSFUL} exact component={WithdrawSuccessful}/>
                    <Route path={WITHDRAW_APPLICATION} exact component={WithdrawApplication}/>
                    <Route path={PARTNER_APPLICATION_SUCCESSFUL} exact component={PartnerApplicationSubmitted}/>
                    <Route path={PASSWORD_SUCCESSFUL} exact component={CreatePasswordSuccessful}/>
                    <Route path={RESET_PASSWORD_SUCCESSFUL} exact component={ResetPasswordCreated}/>
                    <Route path={INFOSESSION_INVITE_SUCCESSFUL} exact component={InviteSuccessful}/>
                    <Route path={TEST_SUCCESSFUL} exact component={TestSubmitted}/>
                    <Route path={FORM_SUBMITTED} exact component={FormSubmitted}/>
                    <Route path={CREATE_PASSWORD} exact component={CreatePassword}/>
                    <Route path={REDIRECT_FROM_ELDER} exact component={RedirectFromElderDashBoard}/>
                    <Route path={RESET_PASSWORD} exact component={ResetPassword}/>
                    <Route path={INFOSESSION_SELF_INVITE} exact component={InfoSessionInvite}/>
                    <Route path={ADMISSION_OFFER_ACCEPT} exact component={AdmissionOfferedAccepted}/>
                    <Route path={ADMISSION_OFFER_DECIDED} exact component={AlreadyDecided}/>
                    <Route path={ADMISSION_OFFER_EXPIRED} exact component={AdmissionOfferExpired}/>
                    <Route path={ADMISSION_OFFER_REJECTED} exact component={AdmissionOfferDeclined}/>
                    <Route path={INTERVIEW_INVITE_ALREADY_INVITED} exact component={AlreadyInvited}/>
                     <Route path={USER_DASHBOARD} exact component={DisplayManager}/>
                    <Route path={TOOEARLY} exact component={InfoSessionTooEarly}/>
                    <Route path={EXPIRED} exact component={InfoSessionExpired}/>
                    <Route path={CONFIRM_PAYMENT} exact component={PaymentOptionModal}/>
                    <Route path={USSD_MODAL} exact component={USSDModal}/>

                    <Route path={LOGIN} exact component={Login}/>
                    <Route path={'/'} exact component={Register}/>
                    <Route path={ADMIN_DASHBOARD} exact component={AdminMainDashboard}/>
                    <Route path={SYSTEM_ADMIN_DASHBOARD} exact component={SystemAdminDashboard}/>

                    <Route path={PROFILE} exact component={Profile}/>
                    <Route path={PARTNER} exact component={Partner}/>
                    <Route path={HIRE_AN_ENGINEER} exact component={HiringPartner}/>
                    <Route path={RESCHEDULE_INTERVIEW} exact component={RescheduleInterviewModal}/>
                    <Route path={INTERVIEW_INVITE_SUCCESS} exact component={InterviewInviteSuccess}/>
                    <Route path={ENROLLMENT_DATA_SUBMITTED} exact component={EnrollmentFormSubmitted}/>
                    <Route path={ENROLL} exact component={UserEnrollmentForm}/>
                    <Route path={PAYMENT_AGREEMENT_FORM} exact component={PaymentAgreementForm}/>
                    <Route path={STAY_UPDATED} exact component={StayUpdatedPage}/>
                    <Route path={UPDATED_SUCCESSFULLY} exact component={StayUpdatedSuccessful}/>
                    <Route path={'/payment-confirmed'} exact component={PaymentConfirmationPage}/>
                    <Route path={'/payment-declined'} exact component={PaymentDeclinedPage}/>
                    <Route path={'/payment-attended'} exact component={PaymentAlreadyAttendedPage}/>
                    <Route path='/semicolonHome' component={() => {
                        window.location.href = 'https://semicolon.africa';
                        return null;

                    }}/>

                    <Route path={NOTFOUND} component={NotFoundPage}/>
                    <Route path='/semicolonBlog' component={() => {
                        window.location.href = 'https://medium.com/semicolon-insights';
                        return null;
                    }}/>
                    <Route path='/elderDashboard' component={() => {
                        window.location.href = 'https://elder.semicolon.africa/dashboard';
                        return null;
                    }}/>





                </Switch>
            </Router>
        </React.Fragment>
    );
}

// const PrivateRoute =({auth, component: Component, ...rest})=>{
//     return(
//         <Route
//             {...rest}
//             render={
//                 props=> auth? <Component:{...props}/>: <Redirect to={{pathname:"/login"}} />
//             }
//         />
//     )
// }

export default App;
