const URL = process.env.REACT_APP_BACKEND_URL
export const ELDER_URL = process.env.REACT_APP_ELDERDASHBOARDURL
export const allFormDataAPI = `${URL}/api/data/get/all`;  //referenceData fetch (Method get) no token required
export const getAllApplicants = `${URL}/api/elder/getAllApplicants`; //fetch all applicants (get method)
export const registerUserAPI = `${URL}/api/applicant/apply`; //apply (method post)
export const SubmitPartnerAPI = `${URL}/api/partner/apply`; //apply (method post)
export const SubmitHireEngineerRequestAPI = `${URL}/api/hireAnEngineer/request`; //apply (method post)
export const getAllInfoSessionList = `${URL}/api/infoSession/getInfoSessions`;
export const getAllAvailableInfoSessionList = `${URL}/api/infoSession/getAvailableInfoSessions`;
export const getAllAvailableInfoSessionForSelfInvite = `${URL}/api/infoSession/getAvailableInfoSessionsSelfInvite`;
export const wakeUpBackendServer = `${URL}/`;
export const selectInfoSessionAPI = infoSessionId => `${URL}/api/infoSession/selectInfoSession/${infoSessionId}`;

export const cloudinaryAPI = process.env.REACT_APP_CLOUDINARYAPI;
export const getInterviewObject = email => `${URL}/api/interviewInvite/getInterviewInvite/${email}`
export const getCompletedInterviewDetails = email => `${URL}/api/interview/getInterview/${email}`
//newly updated
export const loginUserAPI = `${URL}/api/user/login`; //login (method post) //changed from auth to user
export const getMyQuestionnaireAPI = `${URL}/api/questionnaire/getMyQuestionnaire`; //fetch single questionnaire (get method)
export const submitTestAPI = `${URL}/api/applicant/submitAptitudeTest`; //Submit test (method patch)
export const fetchUserAPI = `${URL}/api/applicant/`; //fetchUser Data (method get)
export const postFormDataAPI = `${URL}/api/questionnaire/submit`;  //questionnaire submit (Method Patch) //no longer require user id
export const sendInterviewComment = (applicantEmail, message) => `${URL}/api/interviewComment/createComment/${applicantEmail}/${message}/`;
export const sendInterviewDecision = (applicantEmail, decision) => `${URL}/api/elder/sendInterviewDecision/${applicantEmail}/${decision}/`;
export const inviteForInterview = `${URL}/api/interviewInvite/inviteApplicant`;
export const getInterviewSchedule = (applicantEmail) => `${URL}/api/interviewInvite/getInterviewInvite/${applicantEmail}/`
export const getAllInterviewComment = (applicantEmail) => `${URL}/api/elder/allInterviewComment/${applicantEmail}/`;
export const getApplicantByEmail = (applicantEmail) => `${URL}/api/elder/getApplicantByEmail/${applicantEmail}/`;
export const logoutUserAPI = `${URL}/api/user/logout`;  //logout API (Post method with token header)


export const createPasswordAPI = (uniqueToken) => `${URL}/api/user/password/${uniqueToken}`; //create password (patch method)
export const WithdrawApplicationAPI=(uniqueToken) => `${URL}/api/applicant/withdrawApplication/mail/${uniqueToken}`; //create password (patch method)
export const resetPassword = (email) => `${URL}/api/user/resetPassword/${email}`; //reset password (post mapping)
export const InfoSessionInviteAPI = (email, infoSession) => `${URL}/api/infoSession/selfInvite/${email}/${infoSession}`; //reset password (post mapping)
export const postProfilePicture = `${URL}/api/user/saveProfilePicture`; //save profile picture(patch mapping)
//newly created
export const startTestAPI = `${URL}/api/applicant/startTest`; //Start test (method post)
export const stopTestAPI = (email) => `${URL}/api/applicant/stopTest/${email}`; //Stop test (method post)
export const rescheduleInterview = (token) => `${URL}/api/interviewInvite/reschedule/${token}`; //Stop test (method post)
export const submitEnrollmentForm = `${URL}/api/native/submitEnrollmentForm`;
export const  sendPaymentDetail =`${URL}/api/native/uploadProofOfPayment`;
export const  submitPaymentAgreementForm =`${URL}/api/native/fillPaymentAgreement`;
//System Admin APIs
export const startApplicationsAPI = `${URL}/api/admin/startApplications`; //Start Application (method post)
export const stopApplicationsAPI = `${URL}/api/admin/stopApplications`; //Stop Application (method post)
export const createBatch = `${URL}/api/admin/createBatch`; //Create Batch (method post)
export const changeRoleAPI = `${URL}/api/admin/changeRole`; //Create Batch (method patch)
export const sendEmailAPI = `${URL}/api/admin/resendEmail`; //Create Batch (method patch)
export const RegisterApplicantsViaCSVAPI = `${URL}/api/admin/registerApplicantsViaCSV`; //Create Batch (method patch)
export const PurgeUserAPI = `${URL}/api/admin/delete/user/`; //Create Batch (method delete)
export const CreateInfoSession = `${URL}/api/infoSession/createInfoSession`; //Create infoSession (method post)
export const createDiscountCode = `${URL}/api/summerSchool/discountCode`; //Create infoSession (method post)
export const UpdateInfoSession = `${URL}/api/infoSession/updateInfoSession`; //update infoSession (method post)
export const AnnounceInfoSession = `${URL}/api/admin/sendInfoSessionEmail`; //update infoSession (method post)
export const SendInfoSessionInvitation = `${URL}/api/infoSession/sendInvitation`; //update infoSession (method post)
export const UndoApplicantTest =(email)=> `${URL}/api/admin/resetApplicantTest/${email}`; //update infoSession (method post)
export const GetUserDetails =(email)=> `${URL}/api/admin/getUser/${email}`; //update infoSession (method post)
export const submitNativeAgreementForm = (formType) => `${URL}/api/native/${formType}`
export const StayUpdatedAPI = `${URL}/api/lampLaunch/stayUpdated`
export const getLoanDetails = `${URL}/api/native/getLoanDetails`
export const agreeToLoanForm = `${URL}/api/native/fillLearnFundForm`
export const enableLoanFormFor = (email) => `${URL}/api/elder/enableLearnFundForm/${email}`; //update infoSession (method post)
export const deleteDiscountCode = (code) => `${URL}/api/summerSchool/discountCode/${code}`; //update infoSession (method post)


//No more in use
// const URL = `https://lampback.herokuapp.com`; //backend Root directory
// const URL = `https://lampbackdev.herokuapp.com`; //backend Root directory
// const URL = `https://lampbacktest.herokuapp.com`; //backend Root directory  enable before pushing
//
// const URL = `https://lampback-prod.herokuapp.com`; //backend Root directory //enable before building for production
// const URL ='http://localhost:9002';  //local root directory//
// export const cloudinaryAPI = `https://api.cloudinary.com/v1_1/semicolon-africa/image/upload`;

//
