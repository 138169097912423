import React from "react";
import {Avatar, withStyles} from "@material-ui/core";

const UserAvatar = withStyles({
    root: {
        width: '100%',
        height: '100%',
        fontSize: 60,
        background: '#960c0c',
    }
})(Avatar);


const UserDashboardAvatar = ({user}) => {
    const getProfilePicture = () => {
        if (!user.hasOwnProperty("profilePictureUrl")) {
            return (
                <UserAvatar>{user.firstName.slice(0, 1).toUpperCase()}</UserAvatar>
            );
        } else {
            return (
                <UserAvatar alt="..." src={user.profilePictureUrl}/>
            )
        }
    }
    return getProfilePicture()
}

export default UserDashboardAvatar;