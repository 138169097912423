const thirdPage = [
    {
        question: "What programming languages are you familiar with?",
        type: "drop down",
        options: ["JavaScript", "Java", "c++", "C#", "Swift",
            "Objective C", "Ruby", "Ruby on Rails", "None"],
        response: null
    }, {
        question: "What databases are you familiar with ?",
        type: "drop down",
        options: ["Hadoop", "Mongo DB", "Oracle", "MySQL",
            "Microsoft SQL", "None"],
        response: null
    }, {
        question: "What operating systems are you familiar with?",
        type: "drop down",
        options: ["Windows", "IOS", "Linux", "None"],
        response: null
    }, {
        question: "Why did you apply to this program?",
        type: "drop down",
        options: null,
        response: null
    }, {
        question: "What secondary school did you attend?",
        type: "text",
        options: null,
        response: null
    }, {
        question: "What are you doing currently?",
        type: "drop down",
        options: ["Working", "Studying", "NYSC", "Unemployed"],
        response: null
    }, {
        question: "What frameworks are you familiar with?",
        type: "drop down",
        options: ["Spring", "Django", "Vue.js", "loopback.js", "none"],
        response: null
    }, {
        question: "What technologies are you familiar with?",
        type: "drop down",
        options: ["Excel", "PowerPoint", "MicrosoftWord", "Photoshop", "CorelDraw", "none"],
        response: null
    }, {
        question: "What online courses have you attended?",
        type: "text",
        options: null,
        response: null
    }
];


export default thirdPage;