import React from 'react';
import UserDashBoardHeader from "./UserDashBoardHeader";
import '../../../css/userDashboard.css'
import UserDashboardBody from "./UserDashboardBody";
import UserDashboardSideBar from "./UserDashboardSideBar";
import UserDetails from "./UserDetails";
import UserDashboardHook from "../../../hooks/UserDashboardHook";

class UserDashboard extends UserDashboardHook {
    constructor(props) {
        super(props);
    }

    routeOrView = () => {
        const user = this.state.user;
        const userStatus = user.applicationStatus;
        if (userStatus !== "APPLIED")
            this.setState({...this.state, showUserDetails: true})
        else if (userStatus === "APPLIED")
            this.props.switchComponent("form");
    }

    render() {

        const {
            user,
            infoSessionId,
            infoSessionList,
            imageLoading,
            showUserDetails,
        } = this.state;
        const {applicantQuestionnaire: questionnaireDetails} = user
        return (
            <React.Fragment>
                <div className="myDashContainer">
                    <UserDashBoardHeader user={user} onOpen={this.openNav} imageLoading={imageLoading}/>
                    <div className="container dashboard-holder d-flex justify-content-center align-items-center">
                        <div className="dashboard-container d-flex">
                            <div className="dashboard-inner-container d-flex">
                                {(!showUserDetails) ? <UserDashboardBody
                                    routeOrView={this.routeOrView}
                                    user={user}
                                    infoSessionList={infoSessionList}
                                    infoSessionId={infoSessionId}
                                    startTest={this.startTest}
                                    switchComponent={this.props.switchComponent}
                                /> : <UserDetails user={user} userDetails={questionnaireDetails}
                                                  backToDashboard={this.backToDashboard}/>}
                                <div
                                    className="user-right d-flex flex-column justify-content-center align-items-center h-100">
                                    <div className="horizontal-divider"></div>
                                    <UserDashboardSideBar user={user}
                                                          infoSessionList={infoSessionList}
                                                          infoSessionId={infoSessionId}
                                                          uploadImage={this.uploadImage}
                                                          imageLoading={imageLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserDashboard;