import React, {Component} from 'react';
import {FilteredData} from "../components/user/test/testData/TestData";
import axios from "axios";
import {stopTestAPI, submitTestAPI} from "../components/routes/Api's";
import {remarkTest, saveTestToLocalStorage, stopTest} from "./FunctionalHooks";

class TestHook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            questions: FilteredData,
            email: "",
            score: 0,
            timeRemaining: 900,
            completed: false,
            userResponses:[],
            checkedValue:"",
            testObj: {
                userTest: FilteredData,
                // response:{}
                response: {
                    one: "",
                    two: "",
                    three: "",
                    four: "",
                    five: "",
                    six: "",
                    seven: "",
                    eight: "",
                    nine: "",
                    ten: "",
                    eleven: "",
                    twelve: "",
                    thirteen: "",
                    fourteen: "",
                    fifteen: "",
                    sixteen: "",
                    seventeen: "",
                    eighteen: "",
                    nineteen: "",
                    twenty: "",
                    twentyOne: "",
                    twentyTwo: "",
                    twentyThree: "",
                    twentyFour: "",
                    twentyFive: "",
                    twentySix: "",
                    twentySeven: "",
                    twentyEight: "",
                    twentyNine: "",
                    thirty: "",
                    thirtyOne: "",
                    thirtyTwo: "",
                    thirtyThree: "",
                    thirtyFour:"",
                    thirtyFive:"",
                    thirtySix:"",
                    thirtySeven:"",
                    thirtyEight:"",
                    thirtyNine:"",
                    forty:"",
                    fortyOne:"",
                    fortyThree:"",
                    fortyFour:"",
                    fortyFive:"",
                    fortySix:"",
                    fortySeven:"",
                    fortyEight:"",
                    fortyNine:"",
                    fifty:"",
                    fiftyOne:"",
                    fiftyTwo:"",
                    fiftyThree:"",
                    fiftyFour:"",
                    fiftyFive:"",

                },


            },
        }
    };

    componentDidMount() {
        setInterval(this.timer, 1000)
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        this.setState({email: userData.email});
        this.handleChange = this.handleChange.bind(this);
        this.setCheckedValue = this.setCheckedValue.bind(this);

    };


    timer = () => {
        if (this.state.timeRemaining > 0) {
            this.setState({timeRemaining: this.state.timeRemaining - 1})
        }
    };
setCheckedValue=(checkedValue)=>{
    this.checkedValue = checkedValue;
}
    resolveAnswers = (e) => {
        const {questions, page, score, timeRemaining, testObj, email, completed,userResponses} = this.state;
        const mappedQuestions = questions.map((question) => question.question);
        const {response} = this.state.testObj;
        const mappedAnswer = questions.map((answer) => answer.answer);
        const mappedId = questions.map((id) => id.id);
        var myUserResponses = userResponses;
        myUserResponses[page] = response[`${mappedId[page]}`];
        // console.log("from resolve answers " + myUserResponses)

        this.setState({userResponses: myUserResponses} );
        // console.log("user response in state is" + userResponses);
        if (page < mappedQuestions.length - 1) {
            this.setState({page: this.state.page + 1});
        }
        if (mappedAnswer[page] === response[`${mappedId[page]}`]) {
            this.setState({score: score + 1})
        }
        saveTestToLocalStorage(score, timeRemaining, testObj, email, completed);
        if (page === mappedQuestions.length - 1) {
            const isCompleted = true
            saveTestToLocalStorage(score, timeRemaining, testObj, email, isCompleted);
            this.submitTest()
        }
    };

    //
    // renderOptions = (dataOption) => {
    //     const {questions, page} = this.state;
    //     const mappedId = questions.map((id) => id.id);
    //     dataOption.map((op, index) => {
    //         return (
    //             <div key={index} className="animated">
    //                 <input defaultChecked={() => false} className="options" key={index} onChange={this.getValue}
    //                        type="radio" name={mappedId[page]} value={op}/>
    //                 <label>{op}</label>
    //             </div>
    //         )
    //     })
    // }
    backButton = (event) => {
        if (this.state.page >= 1) {
            const { page} = this.state;
            // const mappedQuestions = questions.map((question) => question.question);
            this.setState({page: page - 1})
            // this.setCheckedValue(this.state.userResponses[page])
            // this.handleChange()
        }
    };
    handleChange = (event) => {
        const {response} = this.state.testObj;
        this.setState({
            testObj: {userTest: FilteredData, response: {...response, [event.target.name]: event.target.value}}
        })
    };

    submitTest = () => {
        // const {testObj, score, timeRemaining, email, completed} = this.state;
        const user = JSON.parse(sessionStorage.getItem("userData"));
        const {testObj, timeRemaining, email} = JSON.parse(localStorage.getItem('userTest'));
        const scores = remarkTest(testObj);
        const isCompleted = true;
        this.submitTestToDB(scores, timeRemaining, testObj, email, isCompleted)
        this.props.history.push(`/test_successful/${user.id}`)
    };


    submitTestToDB = (score, timeRemaining, testObj, email, completed) => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        axios.patch(submitTestAPI,
            {
                score,
                timeRemaining,
                testObj,
                email,
                completed
            },
            {
                headers: headers
            }
        ).then(res => {
            if (res.data.success === true) {
                localStorage.removeItem("userTest");
                this.stopTest(email);

            }
        })
            .catch(err => {
                saveTestToLocalStorage(score, timeRemaining, testObj, email, completed);
                this.handleFailedTestSubmitError(err)
            });
    }


    stopTest = (email) => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        const userData = JSON.parse(sessionStorage.getItem("userData"));


        axios.post(stopTestAPI(email), {}, {
            headers: headers
        })
            .then(res => {

                sessionStorage.setItem("userData", JSON.stringify(res.data))

            })
            .catch(err => {
                this.handleFailedTestStopError(err, email, userData);
            })

    };


    handleFailedTestSubmitError = (e) => {
        if (e.response === undefined) {
        } else {

            switch (e.response.status) {
                case 401:
                case 403:
                    this.props.history.push('/login');

                    break;

                case 400:

                    break;
                default:
                    break;
            }
        }
    }
    handleFailedTestStopError = (e, email, user) => {

        if (e.response === undefined) {
            this.props.history.push(`/test_successful/${user.id}`)
            stopTest(email)
        } else {

            switch (e.response.status) {
                case 401:
                case 403:
                    this.props.history.push('/login');

                    break;

                case 400:

                    break;
                default:
                    break;
            }
        }
    }


}

export default TestHook;
