import React from 'react';
import {useParams} from 'react-router-dom';
import '../../css/modal.css'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DatePicker from "react-datepicker";
import TimeIcon from '../../images/Time.svg';
import DateIcon from '../../images/Date.svg';
import complaint from '../../images/complaint.svg';
import {ReactComponent as Invite} from "../../images/complaint.svg";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import {inviteForInterview} from "../routes/Api's";


export default function InterviewModal(props) {
    const [open, setOpen] = React.useState(false);
    const [inviteDate, setStartDate] = React.useState(null);
    const [inviteTime, setStartTime] = React.useState(null);
    const questionnaire = JSON.parse(sessionStorage.getItem('applicantQuestionnaire'))



    const sendInvite ={
        applicantEmail:"",
        optionalInvitationComment: "hi Chibuzor",
        interviewDate: "",
        interviewTime: "",
        interviewMeth: "phone"

    };
    const [inviteData, setInvite]=React.useState(sendInvite)
    const  applicant = props.email
     console.log()

    const admin =JSON.parse(sessionStorage.getItem('headers'))
    const handleOpen = () => {
             setOpen(true)


        // setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange =e=>{
        const {name,value} = e.target;
        setInvite({...inviteData,[name]:value})
    }
    function inviteApplicantForInterview() {
        let data ={
            applicantEmail:applicant.email,
            optionalInvitationComment: inviteData.optionalInvitationComment,
            interviewDate: (inviteDate.getDate()<10?"0"+inviteDate.getDate():inviteDate.getDate())+"-"+(inviteDate.getMonth()<10?"0"+(inviteDate.getMonth()+1):(inviteDate.getMonth()+1))+"-"+inviteDate.getFullYear(),
            interviewTime:  inviteTime.toString().substr(16,5),
            interviewMethod: inviteData.interviewMeth
        };
        console.log(data)



        axios.post(inviteForInterview,data,
            {
                headers:admin
            }).then(res=>{
            console.log(res.data)
        }).catch(err=>{
            console.log(err)
        });
        handleOpen();

    }


    return (

        <div>
            <div>
                {/*{questionnaire.applicationStatus==="INVITED_FOR_INTERVIEW"?}*/}

                <span className="invite  font-weight-bold " onClick={handleOpen}><label>
                    <Invite  className="invite-box"/>
                    {/*<div className="invite-box">  <img src={complaint} alt="..." /></div>*/}
                </label>Invite For Interview
                </span>
            </div>
            <Dialog className=""
                
                open={open}
                onClose={handleClose}>
                <div className="dialog-main dialog-width">



                    <div className="closeBox d-flex" onClick={handleClose}>
                        <h6  className="cancelsym close ">&times;</h6>
                        <h6 className="closetext"> Close</h6>
                    </div>
                    <div className="d-flex flex-column justify-content-center ml-3">
                        <DialogTitle>
                            <p className="dialog-heading">Interview schedule</p>
                            
                        </DialogTitle>
                        
                        <DialogContent>

                        <p className="dialog-des">Send an interview schedule request to this applicant via email.</p>
                        
                            <div className="row date-and-time-input-box ">
                                {/* <input className="date d-block" type="text" placeholder="Set New Date" onfocus="(this.type='date')"  name='date'/>  */}
                               <DatePicker className="time d-block" selected={inviteTime}  onChange={time => setStartTime(time)}
                               placeholderText="Set New Time"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="time"

                                           timeFormat="HH:mm"
                                dateFormat="h:mm aa" />
                                <img src={TimeIcon} alt="..." />
                            </div>

                            <div className="row date-and-time-input-box ">
                            {/* <i className="calender-icon "> </i> */}
                            <DatePicker className="date d-block" selected={inviteDate}  onChange={date => setStartDate(date)}
                               placeholderText="Set New Date" dateFormat='dd-MM-yyyy' />
                                <img src={DateIcon} alt="..." />
                            </div>
                        
                        <div className="row ">
                            <textarea name="optionalInvitationComment" onChange={handleChange} className="text invite-textarea" placeholder="Write a message(Optional)" />
                        </div>
                        {/* <button className="btn btn-secondary btn-lg modalbtn" onClick={handleClose}>Send Invite
                        </button> */}
                        < div className="d-flex">
                        <button className="w-15-ns d-block  cancelterms" onClick={handleClose}>Cancel</button>
                        <button className="w-15-ns d-block  closeterms" onClick={inviteApplicantForInterview}>Send invite</button>
                         {/* <br/><br/> */}
                        </ div>
                        </DialogContent>
                        
                    </div>
                    
                </div>
                
            </Dialog>
        </div>
    );

};


