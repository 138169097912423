export const switchComponent = (component) => {
    switch (component) {
        case "dashboard":
            const dashControlFlag = {
                showDashboard: true,
                showForm: false,
                showTest: false,
            };
            sessionStorage.setItem("controlFlag", JSON.stringify(dashControlFlag));
            break;
        case "form":
            const formControlFlag = {
                showDashboard: false,
                showForm: true,
                showTest: false,
            };
            sessionStorage.setItem("controlFlag", JSON.stringify(formControlFlag));
            break;
        case "test":
            const testControlFlag = {
                showDashboard: false,
                showForm: true,
                showTest: false,
            };
            sessionStorage.setItem("controlFlag", JSON.stringify(testControlFlag));
    }
}