import React, {Component} from 'react';
import {ReactComponent as SemLogo} from "../../images/semfulllogo.svg";
import DeskIll from "../../images/door.svg"
import '../../css/login.css'
import TextField from "@material-ui/core/TextField";
import styled from 'styled-components';
import axios from 'axios';
import {SubmitHireEngineerRequestAPI, wakeUpBackendServer} from "../routes/Api's";
import * as EmailValidator from 'email-validator'
import Autocomplete from "@material-ui/lab/Autocomplete";


const StyledTextField = styled(TextField)`
  label {
    color: #2B1C1C;
  }
  .MuiFormLabel-filled {
    color: #FF2E2E;
  }
  label.Mui-focused {
    color: #FF2E2E;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(150, 12, 12, 0.5);
      color: #FF2E2E;
    }
    &:hover fieldset {
      border-color: #FF2E2E;
    }
    &.Mui-focused fieldset {
      border-color: #FF2E2E;
      border-width: 2px
    }
  } input {
    color: #FF2E2E !important;
    font-weight: bold;
  }

    `;

const StyledTextArea = styled(TextField)`
  label {
    color: #2B1C1C;
  }
  .MuiFormLabel-filled {
    color: #FF2E2E;
  }
  label.Mui-focused {
    color: #FF2E2E !important;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(150, 12, 12, 0.5);
      color: #FF2E2E;
    }
    &:hover fieldset {
      border-color: #FF2E2E;
    }
    &.Mui-focused fieldset {
      border-color: #FF2E2E;
      border-width: 2px
    }
  } textarea {
    color: #FF2E2E;
    font-weight: bold;
    height: 70px !important
  }
`


class HiringPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            companyName: "",
            designation: "",
            skillSet: "",
            request: "",
        }
    }

    componentDidMount() {
        this.wakeUpServer();
    }

    wakeUpServer = () => {
        axios.get(wakeUpBackendServer);
    }
    handleSubmit = (e) => {

        e.preventDefault();
        this.disableButtonPress();
        if (this.applyValidation()) {

            this.state.skillSet = this.state.skillSet.skillSet.toString();
            console.log(this.state)
            axios.post(SubmitHireEngineerRequestAPI, this.state)
                .then(response => {
                    if (response.status === 201) {
                        this.props.history.push(`/partner_application_successful`);
                    }
                })
                .catch(error => {
                    this.handleError(error)

                })
        }

    }

    handleError = (e) => {
        const errMessage = document.querySelector("#errMessage");
        if (e === "Network Error") {
            errMessage.textContent = "Network connection issues from your provider, try again";
            this.enableButtonPress();
            return true;
        } else if (e === "ERR_INTERNET_DISCONNECTED") {
            errMessage.textContent = "Connect to the internet and try again";
            this.enableButtonPress();
            return true;
        } else {


            if (e.response === undefined) {
                errMessage.textContent = "Connect to the internet and try again";
                this.enableButtonPress();
            } else if (e.response.data.message === "Email exist") {
                errMessage.textContent = "Email already exists";
                this.enableButtonPress();
            } else {
                errMessage.textContent = e.response.data.message;
            }


        }

    }


    applyValidation = () => {
        const errMessage = document.querySelector("#errMessage");
        const {firstName, lastName, email, request, designation, skillSet, companyName} = this.state
        const check = email === "" || firstName === "" || lastName === "" || request === "" || designation === "" || companyName === ""
        const skillSetIsEmpty = skillSet.length === 0;


        if (check) {
            errMessage.textContent = "Please fill in missing fields"
            // setTimeout(emptyFields, 4000)
            this.enableButtonPress();
            return false
        } else if (skillSetIsEmpty) {
            errMessage.textContent = "Please select your preferred Skill Set"
            // setTimeout(emptyFields, 4000)
            this.enableButtonPress();
            return false
        } else if (!EmailValidator.validate(email)) {

            errMessage.textContent = `Please enter a valid email address`;
            this.enableButtonPress();
            // setTimeout(wrongMail,4000)
            return false
        } else {
            errMessage.textContent = "";
            return true
        }
    };


    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.handleSubmit();
        }
    };

    disableButtonPress = () => {
        this.btn.setAttribute("disabled", "disabled");
    };
    enableButtonPress = () => {
        this.btn.removeAttribute("disabled");

    };

    handleSkillSetChange = p => (e, values) => {
        switch (p) {

            case "skillSet":
                this.setState({skillSet: {...this.state.questionnaire, [p]: values}})
                break

        }
    }


    render() {
        const skillSetDropDown = ["Front-End Engineer", "Back-End Engineer", "UI/UX Designer", "Data Analyst", "Product Manager"]
        return (
            <div className="form-container w-100 d-flex">
                <div className="left-form-div">
                    <div className="left-content h-100 mx-auto">
                        <div className="d-flex site-logo">
                            <SemLogo width="200px"/>
                        </div>
                        <div className="form-holder d-flex justify-content-center align-items-center">
                            <div className="form w-100">
                                <h6 className="form-title mb-2 mt-lg-5 ">Hire from Semicolon</h6>
                                <form className="form" onSubmit={this.handleSubmit} autoComplete="off">
                                    <p id="errMessage" className="text-danger"></p>
                                    <div className="full-name row d-flex justify-content-around">
                                        <div className="col-md-6">
                                            <StyledTextField
                                                onChange={this.setOnChange}
                                                className="newField"
                                                fullWidth margin="normal" type="text"
                                                label="First Name " variant="outlined"
                                                name='firstName'

                                                // value={firstName}
                                                required
                                                // autoComplete={true}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <StyledTextField
                                                onChange={this.setOnChange}
                                                margin="normal" fullWidth type="text" label="Last Name "
                                                variant="outlined" name='lastName'
                                                // value={lastName}
                                                required
                                                // autoComplete={true}
                                                color={"secondary"} className="newField"
                                            />
                                        </div>
                                    </div>
                                    <StyledTextField className="newField" type="email"
                                                     name="email" required

                                                     onChange={this.setOnChange}
                                        // value={email}
                                        // autoComplete={true}
                                                     label="Email Address "
                                                     fullWidth variant="outlined" margin="normal"
                                                     color="secondary"
                                        // helperText="Enter a valid email"
                                        // autoComplete={}
                                    />
                                    <StyledTextField className="newField" type="text"
                                                     name="companyName" required
                                                     onChange={this.setOnChange}
                                        // value={companyName}

                                        // autoComplete={true}
                                        // value={password}
                                                     label="Company Name"
                                                     fullWidth variant="outlined" margin="normal"
                                                     color="secondary"

                                    />
                                    <StyledTextField className="newField" type="text"
                                                     name="designation" required
                                        // value={designation}
                                        // value={password}


                                                     onChange={this.setOnChange} label="Designation"
                                                     fullWidth variant="outlined" margin="normal"
                                                     color="secondary"

                                    />


                                    {/*<Select className="newField"*/}
                                    {/*        multiple*/}
                                    {/*        // type="text"*/}
                                    {/*                 name="skillSet" required*/}
                                    {/*    // value={designation}*/}
                                    {/*    // value={password}*/}
                                    {/*        onChange={this.handleChangeMultiple}*/}


                                    {/*                 // onChange={this.setOnChange}*/}
                                    {/*        label="Select Skill Set"*/}
                                    {/*                 fullWidth variant="outlined" margin="normal"*/}
                                    {/*                 color="secondary"*/}

                                    {/*/>*/}


                                    <Autocomplete
                                        multiple
                                        // freeSolo
                                        onChange={this.handleSkillSetChange("skillSet")}
                                        value={this.skillSet}
                                        options={skillSetDropDown}
                                        getOptionLabel={option => option}
                                        renderInput={params => (
                                            <TextField {...params} label={"Select Skill Set"}
                                                       margin="normal" variant="outlined"
                                                       color="secondary"
                                            />
                                        )}
                                    />


                                    <StyledTextArea className="newField" type="text"
                                                    name="request"
                                        // value={password}
                                                    onChange={this.setOnChange} multiline label="Enter your Request"
                                                    fullWidth variant="outlined" margin="normal"
                                                    color="secondary"
                                        // value={message}
                                                    required
                                        // autoComplete={true}

                                    />
                                    <br/>


                                    <button type="submit" onSubmit={this.handleSubmit} onKeyPress={this.onEnterPress}
                                            className="d-block w-100 p-3 my-3 bt" ref={btn => {
                                        this.btn = btn;
                                    }}>Send
                                    </button>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-ill-div login-right">
                    <div className="d-flex partner-btn justify-content-end align-items-start pr-5">
                        <a href="https://apply.semicolon.africa" className="btn btn-outline-danger">Become a Native</a>
                    </div>
                    <div className="form-img d-flex justify-content-center align-items-center">
                        <img alt="doorIll" src={DeskIll} width="500px"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default HiringPartner;