export const APPLICATION_SUCCESSFUL = `/application_successful`;
export const PARTNER_APPLICATION_SUCCESSFUL = `/partner_application_successful`;
export const PASSWORD_SUCCESSFUL = `/password_successful`;
export const RESET_PASSWORD_SUCCESSFUL = `/resetPassword_successful`;
export const INFOSESSION_INVITE_SUCCESSFUL = `/infoSession/invite_successful`;
export const INFOSESSION_SELF_INVITE = `/infoSession/selfInvite`;
export const TEST_SUCCESSFUL = `/test_successful/:id`;
export const FORM_SUBMITTED = `/formSubmitted/:id`;
export const CREATE_PASSWORD = `/createPassword/:user`;
export const WITHDRAW_APPLICATION = `/application/withdraw/:user`;
export const REDIRECT_FROM_ELDER = `/redirect/:userToken`;
export const RESET_PASSWORD = `/resetPassword`;
export const TEST = `/test/:id`;
export const USER_DASHBOARD = `/dashboard/`;
export const USER_DETAILS = '/online/profile:id';
export const LOGIN = `/login`;
export const REGISTER = `/`;
// export const APPLICANT_TABLE = `/dashboard/all-applicants`;
export const ADMIN_DASHBOARD = `/dashboard/all-applicants`;
export const INTERVIEW_SCHEDULED = `/dashboard/interview-scheduled`;
export const QUESTIONNAIRE = `/forms/:id`;
export const PROFILE = `/profile/:id`;
// export const INFO_SESSION = `/infoSession/:id`;
export const PARTNER = '/partner/join';
export const HIRE_AN_ENGINEER = '/hire-from-us';
export const SYSTEM_ADMIN_DASHBOARD = `/systemAdmin/:user`;
export const NOTFOUND = `*`;
export const TOOEARLY = `/error/infosession/too_early/:user`;
export const EXPIRED = `/error/infosession/expired/:user`;
export const RESCHEDULE_INTERVIEW =`/reschedule-interview/:id`;
export const INTERVIEW_INVITE_SUCCESS= '/invite-successful'
export const ADMISSION_OFFER_ACCEPT= '/admission/accepted_offer'
export const ADMISSION_OFFER_EXPIRED= '/admission/offer_expired'
export const ADMISSION_OFFER_DECIDED= '/admission/already_decided'
export const ADMISSION_OFFER_REJECTED ='/admission/offer_declined'
export const INTERVIEW_INVITE_ALREADY_INVITED = '/error/alreadyInvited/:id'
export const CONFIRM_PAYMENT ='/confirm-payment'
export const  USSD_MODAL ='/ussd'
export const ENROLL = '/enroll'
export const ENROLLMENT_DATA_SUBMITTED = '/enrollment_data/submitted'
export const WITHDRAW_SUCCESSFUL = '/application_withdrawn'
export const PAYMENT_AGREEMENT_FORM = '/agreement'
export const PAYMENT_SUCCESS = '/paymentSuccess'
export const UPDATED_SUCCESSFULLY = '/lampLaunch/subscribed'
export const STAY_UPDATED = '/lampLaunch/stay-updated'
// export default {APPLICATION_SUCCESSFUL}



