import React from 'react';
import {ReactComponent as SemLogo} from "../../images/semfulllogo.svg";
import password from "../../images/password.svg"
import '../../css/splash.css'
import TextField from "@material-ui/core/TextField";
import CreatePasswordClass from "../../hooks/CreatePasswordClass";
import SignInButton from "./SignInButton";

class CreatePassword extends CreatePasswordClass {

    render() {
        const {newPassword, confirmPassword} = this.state;
        return (
            <div className="form-container w-100 d-flex">
                <div className="left-form-div">
                    <div className="container d-flex justify-content-center align-items-center h-100">
                        <div className="d-flex left-content flex-column mx-auto pl-lg-5 h-100">
                            <div className="h-25 d-flex">
                                <div className="d-flex align-items-center" style={{marginBottom: 70 + 'px'}}>
                                    <SemLogo width="200px"/>
                                </div>
                            </div>
                            <div className="form-title"><h3>Create password</h3></div>
                            <div>
                                <div className="my-form m-0">
                                    <form onSubmit={this.submitPassword}>
                                        <TextField className="newField"
                                                   type="password" name="newPassword"
                                                   required={true} value={newPassword}
                                                   onChange={this.changeEvent} fullWidth
                                                   label="New Password" margin="normal"
                                                   variant="outlined" color="secondary"

                                        />
                                        <TextField className="newField"
                                                   type="password" name="confirmPassword"
                                                   required={true} value={confirmPassword}
                                                   onChange={this.changeEvent} fullWidth
                                                   label="Confirm Password" margin="normal"
                                                   variant="outlined" color="secondary"

                                        /><br/>
                                        <button className="d-block w-100 p-3 my-3 bt" ref={btn => {
                                            this.btn = btn;
                                        }} onSubmit={this.submitPassword} onKeyPress={this.onEnterPress}>Submit
                                        </button>
                                        <p id="err" className="text-danger"></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="right-ill-div login-right">
                    <SignInButton/>
                    <div className="col-md-12 ">
                        <img alt="password" src={password} width="500px"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreatePassword;