import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {get} from 'axios';
import '../../css/fulldiv.css'
import ProfileHeader from "./ProfileHeader";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import StudentIdDiv from "../admin/StudentIdDiv";
import Sidemenu from "./Sidemenu";
import FullInfo from "./FullInfo";
import Summary from "./Summary";
import {getApplicantByEmail} from "../routes/Api's";

class ApplicantProfile extends Component{
    constructor(props) {
        super(props);

        // this.state={
        //     applicant:{
        //         email:''
        //     },
        //     applicantDocument:{},
        //     questionnaire:{},
        // };
    }
    //  componentDidMount() {
    //     this.getApplicantInfo();
    // }
    //
    // getApplicantInfo() {
    //     const params = {id:this.props.email}
    //     console.log(this.props)
    //     const header =JSON.parse(sessionStorage.getItem('headers'))
    //     console.log(header)
    //     get(getApplicantByEmail(params.id),{headers:header})
    //         .then(res =>{
    //             console.log(res.data)
    //             this.setState({ applicant :res.data, applicantDocument: res.data.applicantDocument, questionnaire:res.data.applicantDocument.questionnaire });
    //
    //         });
    // }

    render() {
        const applicant = this.props.applicant
        const questionnaire = this.props.questionnaire
        const applicantDocument = this.props.applicantDocument
        console.log(questionnaire);
        // const { applicant,questionnaire } = this.state;
        // const {applicantDocument} = this .state;
        // sessionStorage.setItem("applicantProfile",JSON.stringify(applicant))
        // sessionStorage.setItem("applicantQuestionnaire",JSON.stringify(applicantDocument))



        return(
            <div>
                {/*<ProfileHeader />*/}
                {/*<FullDiv/>*/}

                <div className="">
                <div className=" profile-menu ml-3 mr-3 mb-5">

                    <Card className="profile-card  mt-5">
                        <CardContent className="ml-3">
                           <h3 className="summary-head"> Progress</h3>
                            <div className="row">
                                <div className="col app-status">
                                    <p>Application Status: Applicant</p>
                                </div>
                                <div className="col app-status">
                                    <p>Tuition Paid: NGN 50,000</p>

                                </div>

                            </div>
                        </CardContent>

                    </Card>

                <div className="row mt-4 ">
                    <div className="col-6">
                        <Card className="profile-card h-100 ">
                            <CardContent className="ml-3">
                                <h3 className="summary-head">Bio</h3>

                                <div className="row">
                                    <div className="">
                                        <div className="col my-label">
                                            <p>First Name</p>
                                        </div>
                                        <div className="col ">
                                            <p className="bio-data">{applicant.firstName}</p>
                                        </div>

                                    </div>
                                    <div className="col">
                                        <div className="col my-label">
                                            <p>Last Name</p>
                                        </div>
                                        <div className="col">
                                            <p className="bio-data">{applicant.lastName}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col ">
                                    <div className="row ">
                                        <p className="my-label">Email Address</p>
                                    </div>
                                    <div className="row">
                                        <p className="bio-data">{applicantDocument.email}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="">
                                        <div className="col">
                                            <p className="my-label">Gender</p>
                                        </div>
                                        <div className="col">
                                            <p className="bio-data">{applicant.gender}</p>
                                        </div>

                                    </div>
                                    <div className="col">
                                        <div className="col">
                                            <p className="my-label">AgeRange</p>
                                        </div>
                                        <div className="col">
                                            <p className="bio-data">{applicantDocument.ageRange}</p>
                                        </div>
                                    </div>



                                </div>
                            </CardContent>

                        </Card>
                    </div>

                    <div className="col-6 ">
                        <Card className="profile-card more-box ">
                            <CardContent className="ml-3">
                                <h3 className="summary-head"> More info</h3>

                                <div className="row">
                                    <div className="">
                                        <div className="col">
                                            <p className="my-label">State of Origin</p>
                                        </div>

                                        <div className="col">
                                            {questionnaire.stateOfOrigin===""?<p className="bio-data">Null</p>:
                                            <p className="bio-data">{questionnaire.stateOfOrigin}</p>}
                                        </div>

                                    </div>
                                    <div className="">
                                        <div className="col">
                                            <p className="my-label">State of Residence</p>
                                        </div>
                                        <div className="col">
                                            <p className="bio-data">{questionnaire.stateOfResidence}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="">
                                        <div className="col">
                                            <p className="my-label">Higher Institution</p>
                                        </div>
                                        <div className="col">
                                            {questionnaire.underGradInstitution===""
                                                ? <p className="bio-data">Null</p>

                                                :<p className="bio-data">{questionnaire.underGradInstitution}</p>}
                                        </div>

                                    </div>
                                    <div className="col">
                                        <div className="">
                                            <p className="my-label">Course of Study</p>
                                        </div>
                                        <div className="col">
                                            {questionnaire.underGradCourse===""?<p className="bio-data">Null</p>:
                                            <p className="bio-data">{questionnaire.underGradCourse}</p>}
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="">
                                        <div className="col">
                                            <p className="my-label">University Grade</p>
                                        </div>
                                        <div className="col">
                                            {questionnaire.underGradGrade===""?  <p className="bio-data">Null</p>:
                                            <p className="bio-data">{questionnaire.underGradGrade}</p>}
                                        </div>

                                    </div>
                                    <div className="">
                                        <div className="col">
                                            <p className="my-label">Marital Status</p>
                                        </div>
                                        <div className="col">
                                            <p className="bio-data">{questionnaire.maritalStatus}</p>
                                        </div>
                                    </div>

                                </div>
                            </CardContent>

                        </Card>
                    </div>
                    </div>


                </div>

                {/*<div className="col-3 card-class mr-auto">*/}
                {/*    <StudentIdDiv />*/}
                {/*</div>*/}
            </div>
            </div>
        );
    }

}
export default  ApplicantProfile;