import React from "react";
import {ReactComponent as BackIcon} from "../../images/BackLogo.svg";
import {ReactComponent as FrontIcon} from "../../images/open1.svg"
import '../../css/admin-main-dashboard.css'
import AdminDashboardClass from "../../hooks/AdminDashboardClass";
import './../../css/fulldiv.css'
import {ReactComponent as Filter} from '../../images/filter.svg'
import {ReactComponent as Export} from '../../images/export.svg'
import ExportToExcel from 'react-html-table-to-excel'

let frontIcon = document.querySelector('#open')

class SearchHeader extends AdminDashboardClass {
    render() {

        const {searched} = this.props
        return (
            <div className="container-fluid row d-flex  ">
                <div className="col-md-12 d-flex pt-md-3  pb-md-3 border-left-0 border-right-0 border-top-0 ">
                    <div className="col-md-3 styl ">
                        <BackIcon style={{stroke: "rgba(43, 28, 28, 0.3)", cursor: "pointer"}} className="testing"
                                  id="close" onClick={this.closeBar} width="50px"/>
                        <FrontIcon style={{stroke: "rgba(43, 28, 28, 0.3)", cursor: "pointer"}} className="tester"
                                   id="open" onClick={this.openBar} width="50px"/>
                    </div>
                    <div className=" col-md-9 d-flex tops ">
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control searchOut" placeholder="Search for keyword"
                                   onChange={searched}/>
                        </div>

                        <div className="col-md-5 d-flex justify-content-around svg-top">
                            {/* <div className="" style={{color:"#00A998"}}><Export className="svgs" /></div> */}
                            <div style={{width: "5px"}} className="d-flex">
                                <Export className="svgs"/>
                                <ExportToExcel
                                    id="test-table-xls-button"
                                    className="export"
                                    table="tabular"
                                    filename="applicants"
                                    sheet="tablexls"
                                    buttonText="Export"
                                />

                            </div>
                            <div className="" style={{color: "#00A998"}}><Filter className="svgs"/></div>
                        </div>
                        <div className="col d-flex">
                            <select style={{
                                color: "#960C0C",
                                height: "40px",
                                padding: "5px",
                                position: "relative",
                                bottom: "6px"
                            }}>
                                <option>Showing 10</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default SearchHeader