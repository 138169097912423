import React, {useState} from "react";
import UserDashBoardHeader from "../UserDashboard/UserDashBoardHeader";
import {makeStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import {agreeToLoanForm} from "../../routes/Api's";

const useStyles = makeStyles(theme => ({
    heading: {
        'font-weight': 'bold',
        'color': '#960C0C',
        'text-align': 'center'
    },
    parent: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: '100%',
        marginTop: '25vh'
    },
    formHolder: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        width: '50%',
        padding: '45px 55px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '10px 15px',
        },
    },
    btns: {
        maxWidth: 200,
        display: 'flex',
        justifyContent: 'space-between',
    },
    backBtn: {
        padding: '12px 17px',
        background: "#FFF",
        border: '1px solid #FF2E2E',
        transition: `all .5s ${theme.transitions.easing.easeInOut}`,
        color: '#FF2E2E',
        '&:hover': {
            background: '#FF2E2E',
            color: '#FFF'
        }
    },
    submit: {
        padding: '12px 17px',
        background: "#FF2E2E",
        color: '#FFF',
        border: '1px solid #FF2E2E',
        transition: `all .5s ${theme.transitions.easing.easeInOut}`,
        '&:hover': {
            borderColor: '#960C0C',
            background: '#960c0c',
            color: '#FFF'
        }
    },
}))

export default function LoanAgreementForm(props) {
    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const classes = useStyles(props)
    const {user} = props
    const handleSubmit = e => {
        if (!checked) return
        e.preventDefault()
        setDisabled(true)
        const headers = JSON.parse(sessionStorage.getItem('headers'))
        axios.post(agreeToLoanForm, {agreedToTerms: checked, parentName: 'N/A'}, {headers}).then(res => {
            sessionStorage.setItem('userData', JSON.stringify(res.data))
            props.switchComponent('dashboard')
        }).catch(e => {
            setDisabled(false)
            console.log(e, e.response)
        })
    }
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    function getDay(i) {
        const j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    const loanDetails = JSON.parse(sessionStorage.getItem('loanDetails'))
    const showIfParentConsentNeeded = () => {
        if (loanDetails.needParentsConsent) {
            return (
                <div>
                    <h5 className={classes.heading}>FOR PERSONS UNDER THE AGE OF 18.</h5>
                    <p>I {user.parentsName} of {user.address} being a Parent/ Guardian
                        to {loanDetails.fullLegalName} hereby admit to be bound by all the Clauses contained in this
                        agreement to the grant of loan to my child/ward during his course of training at Semicolon
                        Village Limited.</p>
                </div>
            )
        }
    }
    return (
        <>
            <UserDashBoardHeader user={props.user} imageLoading={false}/>
            <div className={classes.parent}>
                <div className={`shadow-3 ${classes.formHolder}`}>

                        <img src="https://res.cloudinary.com/semicolon-dev/image/upload/v1623443519/emailWithBarCodeImage/Artboard_6_1_2x_upuini.png"
                              alt={"Learn Space Logo"} width={178} height={35}/>
                    <h5 className={classes.heading}>LOAN AGREEMENT BETWEEN LEARN SPACE AND BORROWER</h5>
                    <p>This agreement is made this {getDay(new Date().getDay())} day
                        of {months[new Date().getMonth()]}, {new Date().getFullYear()} between <strong>Learn Space Village Limited, </strong>
                        an entity established and registered as a Private Company limited by shares under the Nigerian Law (hereinafter referred to as the <strong>Lender </strong>
                        on the one part and {loanDetails.fullLegalName} of {loanDetails.nativeHomeAddress}, (hereinafter
                        referred to as the <strong>Borrower</strong>) on the other part.
                    </p>
                        <p>
                        Whereas,  <strong>Learn Space Village Limited  </strong> seeks  to support individuals willing to seek
                        knowledge in various specialized fields of Learning by offering them timely financial assistance  as at when needed.
                        </p>
                        <p>
                        The Borrower has decided to pursue a one year intensive Software Engineering training program at
                        <strong> Semicolon Village Limited, 312, Herbert Macaulay Way, Sabo, Yaba, Lagos </strong>. At the request of the
                        Borrower and subject to the terms and conditions of this agreement set out below, the Lender has
                        agreed to advance Loan to the Borrower.
                        The Lender shall provide the Borrower with a loan facility
                        {/*of {loanDetails.amountToBorrow} ({loanDetails.amountInWords}) as agreed by the*/}
                        of up to the tune of Three Million Naira only (N3,000,000) as agreed by the
                        parties
                        herein.</p>
                    <h5 className={classes.heading}>
                        TERMS AND CONDITIONS
                    </h5>
                     <ol>
                        <li>For any applicant of this Loan sum to be qualified for same, such applicant must have been
                            offered admission to Semicolon Village Limited for the one year Software Engineering
                            training program.
                        </li>
                        <li>Such applicant must have commenced the process of his/her Loan application with Central Bank
                            of Nigeria and must be at the stage of loan disbursal.
                        </li>
                        <li>Upon the disbursal of the Central Bank of Nigeria loan to the Borrower, the Loan sum from
                            the Lender shall be immediately paid up by the Borrower.
                        </li>
                        <li>The Lender shall disburse the Loan amount to Semicolon Village Limited on behalf of the
                            Borrower.
                        </li>
                        <li>The Loan amount shall attract a compound interest of 15% per annum at the time of disbursal,
                            which shall be subject to review from time to time in line with the prevailing market condition (Treasury Bills) .</li>
                        <li>The interest shall begin to accrue from the moment the loan is granted.
                        </li>
                         <li>The Borrower shall immediately commence the repayment of the loan granted
                             together with all interest accrued one year from the period the loan was granted.
                        </li>
                         <li>If the Borrower fails to complete his course of study through any cause
                             whatsoever, the Borrower shall remain indebted to the Lender for the loan
                             and the accrued interest rate at every given point in time.
                         </li>
                        <li>If the Borrower gets employed before the disbursal of the Central Bank of
                            Nigeria loan, the Borrower agrees to a monthly deduction of an agreed sum
                            from his salary till the entire Loan sum is fully paid off.
                        </li>
                        <li>Parent / Guardian shall be required to process Loan for applicants under 18 years of age.
                        </li>
                    </ol>
                    <h5 className={classes.heading}>GENERAL PROVISIONS</h5>
                    <ol>
                        <li>
                            The parties agree that they shall use their best endeavors to negotiate and settle any
                            dispute or difference of opinion between them arising from or connected with this agreement
                            amicably.
                        </li>
                        <li>Any dispute which cannot be mutually resolved by the parties shall be referred to
                            Mediation.
                        </li>
                        <li>The Mediator shall be appointed by the parties.</li>
                        <li>The venue of Mediation shall be in Lagos state.</li>
                        <li>In case both parties cannot agree on a Mediator, the Clerk of the Court shall appoint one on
                            their behalf.
                        </li>
                    </ol>
                    {showIfParentConsentNeeded()}
                    <p>
                        NOTE:
                        Learn Space Village Limited (the Lender) shall have the right to assign and transfer his
                        rights to any person whether natural or juristic. Upon such, the borrower shall be duly
                        informed of such assignment.
                    </p>
                    {/*<p>If you have any questions or concerns about these terms and conditions, please contact us on <a*/}
                    {/*    href="mailto:info@semicolon.africa.com">info@semicolon.africa.com</a></p>*/}
                    <FormControlLabel
                        className={classes.checkbox}
                        label="The Native agrees to have carefully read through these terms and conditions and agrees to be bound by them."
                        control={<Checkbox checked={checked} onChange={() => setChecked(!checked)}
                                           name="tAndC"/>}
                    />
                    <div className="stamp-div">
                        <img alt={"stamp"}
                             src="https://res.cloudinary.com/semicolon-africa/image/upload/v1601575479/emailpng/small_seal_cropped_upejhj.png"/>
                        {/*<img alt={"stamp"} src={stamp}/>*/}
                    </div>
                    <div className={`${classes.btns} mt-5`}>
                        <button className={classes.backBtn} disabled={disabled}
                                onClick={() => props.switchComponent('dashboard')}>Go back
                        </button>
                        <button className={classes.submit} disabled={disabled} onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}