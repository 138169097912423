const EnrollmentData =[
    {
        question:'Years of work experience',
        options:["None",'Less than 2 years','2 - 4 years','5 - 6 years','7 - 8 years','9 - 10 years','11 - 15 years','More than 15 years']
    },
    {
        question: 'Do you have experience working in any of the following industries?',
        options: ["Information Technology (IT)",'Telecommunications','Financial services',
            'Consumer goods/retail',' Energy','Agriculture','Education','Public sector/Government',
            'Legal','Manufacturing','Engineering','Medical/Healthcare','Entertainment/Media','Arts',
            'Hospitality/Restaurants','Transportation',' Marketing/Advertising','Other']
    },
    {
    question: 'Years of experience working in IT',
        options: ["None",' Less than 2 years','2 - 4 years','5 - 6 years','7 - 8 years','9 - 10 years','11 - 15 years','More than 15 years']
    }


]
export default EnrollmentData;