import React, {Component} from 'react';
import '../../css/modal.css'

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {sendEmailAPI} from "../routes/Api's";

class FormCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            emailType: "",


        };
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.applyForm();
        }
    };


    render() {


        const emailTypeDropDown = {
            emailType: ['Password Reset Email', 'Password Create Email', 'Interview Invite Email', 'Info Session Invite', 'Admission offer email', 'Send to community email'],

        }
        let {email, emailType} = this.state;


        return (

            <div className="col-xl-3 col-md-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Resend Email</h6>
                    </div>
                    <div className="card-body">

                        <div className="form-group mt-3">
                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label="Email"
                                           variant="outlined" name='email' value={email}
                                           color={"secondary"} className="newField" required
                                />



                            <TextField onChange={this.setOnChange}

                                       select label="Email Type" name='emailType'
                                       variant="outlined" value={emailType} margin="normal"
                                       color={"secondary"} className="newField emailType" required
                            >
                                {emailTypeDropDown.emailType.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}

                            </TextField>


                        </div>
                        <button type="submit" className="w-100 d-block p-3 bt " onClick={this.sendEmail}>Resend Email</button>
                        <ToastContainer/>
                    </div>
                </div>


            </div>
        );
    };

    sendEmail = () => {
        const {email, emailType} = this.state;
        let emailTypeString;
        switch (emailType) {
            case ("Password Reset Email"):
                emailTypeString = "resetPassword";
                break
            case("Password Create Email"):
                emailTypeString = "createPassword";
                break
            case  ("Interview Invite Email"):
                emailTypeString = "interviewInviteMessage";
                break
            case ("Info Session Invite"):
                emailTypeString = "infoSessionMessage"
                break
            case("Admission offer email"):
                emailTypeString ="admissionOffer"
                break
            case("Send to community email"):
                emailTypeString ="sendToCommunity"
                break

            default:
                break


        }
        const data = "/?email=" + email + "&emailType=" + emailTypeString;

        axios.post(sendEmailAPI + data, "", {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success(emailType + " sent successfully");
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error("message sending failed")
                toast.error(err.response.data.message)
            })
        ;

    };


    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
}

export default FormCard;
