import React from "react";
import {Box, Button, Card} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import CheckIcon from "../../../images/check-circle.svg";
import HomeIcon from "../../../images/home.svg";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    root: {
        border: '1px solid #960C0C',
        borderRadius: 8,
        padding: 20,
        boxShadow: '0px 4px 8px rgba(108, 132, 120, 0.15)',
        width: '100%',
        height: 270,
    },
});

const RescheduleButton = withStyles({
    root: {
        minWidth: 150,
        color: '#FF2E2E',
        background: '#FFF',
        transition: 'all .5s ease',
        '&:hover': {
            background: '#FF2E2E',
            color: '#FFF',
        },
        marginTop: 60,
        marginBottom: 20,
        padding: 12,
        border: '2px solid #FF2E2E'
    },
    text: {
        textTransform: "none",
    }
})(Button);

const ScheduledInfoSession = ({infoSessionId, infoSessionList, handleReschedule}) => {

    const classes = useStyles();

    const infoSessionObject = infoSessionList.find(infoSession => infoSessionId === infoSession.infoSessionId);

    if (infoSessionObject === undefined)
        return "Loading...";

    return (
        <Box>
            <Card className={classes.root}>
                <Box display="flex" justifyContent="flex-end" style={{height: '10%'}}>
                    <img style={{height: 20, width: 20,}} src={CheckIcon} alt=""/>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-around" style={{height: '90%'}}>
                    <div className={classes.iconHome}>
                        <img style={{width: 25, height: 25,}} src={HomeIcon} alt=""/>
                    </div>
                    <Box>
                        <Typography gutterBottom variant="body1" style={{
                            color: 'rgba(43, 28, 28, 0.85)',
                            maxWidth: 100,
                        }}>
                            {convertTime(infoSessionObject.sessionTime)()}
                        </Typography>
                        <Typography gutterBottom variant="body2" style={{
                            color: 'rgba(43, 28, 28, 0.85)',
                        }}>
                            {convertDate(infoSessionObject.sessionDate)()}
                        </Typography>
                    </Box>
                    <Typography variant="h5" style={{
                        color: '#960C0C',
                        fontWeight: 'bold',
                        // maxWidth: 100,
                    }}>
                        {
                            infoSessionObject.mode === 'Online' ? 'Virtual Session' :
                                'Physical Session'}
                    </Typography>
                </Box>
            </Card>
            <RescheduleButton onClick={handleReschedule}>Reschedule</RescheduleButton>
        </Box>
    );
}

const convertDate = d => () => {
    const [day, month, year] = d.split("-");
    const dateObj = new Date(year, month - 1, day);
    return dateObj.toDateString();
}

const convertTime = t => () => {
    let [hour, min] = t.split(':');
    const mode = hour > 11 ? "PM" : "AM";

    if (hour > 12) {
        hour %= 12;
        if (hour < 10) {
            hour = "0" + hour;
        }
    }
    return hour + ":" + min + " " + mode;
}


export default ScheduledInfoSession;