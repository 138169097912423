
import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import '../../../../css/forms.css'

import secondPage from "../formData/secondPage";

class Form2 extends Component {

    render() {
        const {
            handleChange,

            whatsapp,
            twitter,
            instagram,
            tiktok,
            github,
            linkedin,
            facebook,
            telegram,
            alternatePhone,
            mostPreferredSocialNetwork,

        } = this.props


        return (
            <form onSubmit={this.onNext} className="row w-100">

                <div className="col-md-12">

                    <TextField
                        margin="normal" fullWidth type="number" label={secondPage[1].question}
                        onChange={handleChange('alternatePhone')}
                        inputProps={{
                            max: 999999999999999,
                        }}
                        variant="outlined" onInput={handleChange('alternatePhone')}
                        pattern="[0-9]*" value={alternatePhone} name="phone"
                        color={"secondary"} className="newText" required
                    />


                    {/*<TextField*/}
                    {/*    label={secondPage[1].question}*/}
                    {/*    color="secondary"*/}
                    {/*    className="newText"*/}
                    {/*    value={alternatePhone}*/}
                    {/*    onChange={handleChange('alternatePhone')}*/}
                    {/*    margin="normal"*/}
                    {/*    variant={"outlined"}*/}
                    {/*/>*/}
                </div>



                <div className="col-md-12">
                    <TextField
                        margin="normal"
                        select
                        label={secondPage[0].question}
                        value={mostPreferredSocialNetwork}
                        color="secondary"
                        onChange={handleChange("mostPreferredSocialNetwork")}
                        variant="outlined"
                    >
                        {secondPage[0].options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}

                    </TextField>
                </div>
                <br/>
                <div className="col-md-12">
                    <h3 style={{
                        fontStyle: "normal",
                        fontWeight: 800,
                        fontSize: "15px",
                        lineHeight: "22px",
                        color: "#960C0C"
                    }} className="text-danger text-left"> <strong>Social media details</strong> (all fields are optional)</h3>
                </div>



                <div className="col-md-6">
                    <TextField
                        label={"Facebook"}
                        color="secondary"
                        className="newText"
                        value={facebook}
                        onChange={handleChange('facebook')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>


                <div className="col-md-6">
                    <TextField
                        label={"Github"}
                        color="secondary"
                        className="newText"
                        value={github}
                        onChange={handleChange('github')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>




                <div className="col-md-6">
                    <TextField
                        label={"Instagram"}
                        color="secondary"
                        className="newText"
                        value={instagram}
                        onChange={handleChange('instagram')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>



                <div className="col-md-6">
                    <TextField
                        label={"LinkedIn"}
                        color="secondary"
                        className="newText"
                        value={linkedin}
                        onChange={handleChange('linkedin')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>


                <div className="col-md-6">
                    <TextField
                        label={"Telegram"}
                        color="secondary"
                        className="newText"
                        value={telegram}
                        onChange={handleChange('telegram')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>


                <div className="col-md-6">
                    <TextField
                        label={"Tiktok"}
                        color="secondary"
                        className="newText"
                        value={tiktok}
                        onChange={handleChange('tiktok')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>

                <div className="col-md-6">
                    <TextField
                        label={"Twitter"}
                        color="secondary"
                        className="newText"
                        value={twitter}
                        onChange={handleChange('twitter')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>


                <div className="col-md-6">
                    <TextField
                        label={"Whatsapp"}
                        color="secondary"
                        className="newText"
                        value={whatsapp}
                        onChange={handleChange('whatsapp')}
                        margin="normal"
                        variant={"outlined"}
                    />
                </div>

            </form>
        );
    }
}

export default Form2;