import React, {Component} from 'react';
import WhiteSplashHeader from "./WhiteSplashHeader";
import '../../../css/splash.css'
import {ReactComponent as Thumb} from '../../../images/thumbs-up-orangeorange.svg'
import TestModal from "../UserDashboard/TestModal";

class FormSubmitted extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const user = JSON.parse(sessionStorage.getItem("userData"))
        return (
            <div className="bac newback vh-90">
                <WhiteSplashHeader/>
                <div className="container  d-flex justify-content-around align-items-center mt-md-5 ">

                    <div className="box bg-white align-self-md-start col-md-4  br4">
                        <div className=" col-12 col-md-12 d-flex ">
                            <div className="thumb">
                                <Thumb/>
                            </div>
                        </div>

                        <div className="text-area">
                            <h1 className="submit-text">Amazing </h1>
                            <p className="p-text">Your questionnaire has been submitted. Click
                                the link below to take your aptitude test.
                            </p>
                            <span> <button className="next-button"><TestModal user={user}
                                                                              switchComponent={this.props.switchComponent}
                                                                              userDashCompProps={this.props.userDashCompProps}/></button> </span><br/>
                            <a href={`/dashboard/`}>
                                <button className="prev-button back-to-dashboard">Back to Dashboard</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default FormSubmitted;