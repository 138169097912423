import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {CancelIcon} from '../Icons/CancelIcon';
import PhoneCallIcon from "../../images/PhoneCallIcon.svg";
import CheckCircleIcon from "../../images/CheckCircleIcon.svg";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        color: '#2b1c1c',
        marginBottom: 25,
        fontSize: '1.75rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        }
    },
    audio: {
        fontWeight: "bold",
    },
    phone: {
        width: 38,
        height: 38,
        background: "rgba(255, 126, 29, 0.15)",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeAndDate: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 20,
        fontWeight: "bold",
    },
    label: {
        fontWeight: "bold",
        '& + p': {
            color: '#960C0C',
            fontSize: "1.2rem",
        }
    },
    dialog: {
        padding: "2rem",
        minWidth: 400,
        [theme.breakpoints.down('sm')]: {
            minWidth: 300,
        }
    },
    dialogTextBox: {
        width: 220,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 20,
    },
    button: {
        background: "#FF2E2E",
        color: "#FFF",
        border: '1px solid #FF2E2E',
        padding: "10px 12px",
        marginTop: 20,
        marginBottom: 10
    }
}));

function SimpleDialog(props) {
    const classes = useStyles();
    const {onClose, open, interviewObject} = props;

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <div className={classes.dialog}>
                <Button onClick={onClose} startIcon={<CancelIcon/>}> Close</Button>
                <div className={classes.dialogTextBox}>
                    <h2 id="simple-dialog-title" className={classes.title}>Interview details</h2>
                    <p className="my-4">A summary of your schedule.</p>
                    <div className="audio d-flex justify-content-around align-items-center">
                        <div className={classes.phone}>
                            <img src={PhoneCallIcon} alt=".."/>
                        </div>
                        <span
                            className={classes.audio}>{interviewObject.interviewMethod === "Offline" ? "Physical" : "Virtual"}</span>
                        <img src={CheckCircleIcon} alt="..."/>
                    </div>
                    <div className={classes.timeAndDate}>
                        <div className={classes.time}>
                            <h6 className={classes.label}>TIME</h6>
                            <p>{interviewObject.interviewTime}</p>
                        </div>
                        <div className={classes.date}>
                            <h6 className={classes.label}>DATE</h6>
                            <p>{interviewObject.interviewDate}</p>
                        </div>
                    </div>
                    {/*<button className={classes.button}>Reschedule</button>*/}
                </div>
            </div>
        </Dialog>
    );
}

// SimpleDialog.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     open: PropTypes.bool.isRequired,
//     selectedValue: PropTypes.string.isRequired,
// };

export default function InterviewInfoModal({user, interviewObject}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const pointerEvents = () => {
        const userStatus = user.applicationStatus;
        if (userStatus === "APPLIED" || userStatus === "QUESTIONNAIRE" || userStatus === "TESTED" || userStatus === "TESTING") {
            return "none"
        } else if (userStatus === "INVITED_FOR_INTERVIEW" || userStatus === "INTERVIEWED") {
            return "initial"
        } else {
            return "initial"
        }
    }

    const getInterviewButtonText = () => {
        const userStatus = user.applicationStatus;
        if (userStatus === "APPLIED" || userStatus === "QUESTIONNAIRE" || userStatus === "TESTED" || userStatus === "TESTING") {
            return "Pending";
        } else if (userStatus === "INVITED_FOR_INTERVIEW") {
            return "View Details";
        } else {
            return "View Details";
        }
    }

    return (
        <div>
            <p onClick={handleClickOpen} style={{
                pointerEvents: pointerEvents()
            }} className="m-0">
                {getInterviewButtonText()}
            </p>
            <SimpleDialog open={open} onClose={handleClose} user={user} interviewObject={interviewObject}/>
        </div>
    );
}
