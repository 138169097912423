import React, {Component} from 'react';
import '../../css/modal.css'
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import {
    createBatch,
    PurgeUserAPI,
    GetUserDetails,
    UndoApplicantTest,
    CreateInfoSession,
    UpdateInfoSession, SendInfoSessionInvitation, AnnounceInfoSession
} from "../routes/Api's";
import {toast, ToastContainer} from "react-toastify";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

class multiButtonCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "",
            link:"",
            sessionTime:"",
            sessionDate:"",
        };
    };

    createInfoSession = () => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        const  {mode,sessionTime,sessionDate, link}= this.state;

        console.log(this.state)


            axios.post(CreateInfoSession, {
                mode: mode,
                infoSessionLink: link,
                sessionTime: sessionTime,
                sessionDate: sessionDate
            }, {headers: headers})
                .then(res => {
                        toast.success('InfoSession created Successfully')
                        toast.success(res.data.message);
                    }
                ).catch(err => {
                // toast.error('Info Session creation failed')
                toast.error(err.response.data.message)

            });

    }


    updateInfoSession = () => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));
        const  {mode,sessionTime,sessionDate,link}= this.state;

        axios.post(UpdateInfoSession, {mode:mode, infoSessionLink:link, sessionTime:sessionTime, sessionDate:sessionDate} , {headers:headers})
            .then(res => {
                if (res.status === 200) {
                    toast.success( "InfoSession updated successfully");
                    toast.success(res.data.message);
                }
            }).catch(err => {
            toast.error(err.response.data.message)


        });
    }

    sendInvitation = () => {
        const headers = JSON.parse(sessionStorage.getItem("headers"));

        axios.get(AnnounceInfoSession, {} , {headers:headers})
            .then(res => {

                if (res.status === 200) {
                    toast.success( "InfoSession emails sent successfully");
                    toast.success(res.data.message);
                }
            }).catch(err => {
            toast.error(err.response.data.message)
        });
    }





    render() {


        return (

            <div className="col-xl-3 col-md-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">InfoSession Tasks</h6>
                    </div>
                    <div className="card-body">

                        <div className="form-group mt-3">
                            {/*<div className="col-md-6">*/}
                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label="Session Date dd-MM-yyyy "
                                           variant="outlined" name='sessionDate'
                                           color={"secondary"} className="newField" required
                                />
                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label="Session Time HH:mm "
                                           variant="outlined" name='sessionTime'
                                           color={"secondary"} className="newField" required
                                />
                                <TextField onChange={this.setOnChange}
                                           margin="normal" fullWidth type="text" label=" Info Session Url "
                                           variant="outlined" name='link'
                                           color={"secondary"} className="newField" required
                                />
                                {/* <TextField onChange={this.setOnChange}*/}
                                {/*           margin="normal" fullWidth type="text" label="mode -Online or Offline"*/}
                                {/*           variant="outlined" name='mode'*/}
                                {/*           color={"secondary"} className="newField" required*/}
                                {/*/>*/}

                            <RadioGroup aria-label="mode" name="mode"

                                        onChange={this.setOnChange} required>
                                <label className="mode">Info Session Mode </label>
                                <FormControlLabel value="Online" className="radioCircle" control={<Radio/>}
                                                  label="Online"/>
                                <FormControlLabel value="Offline" className="radioCircle" control={<Radio/>}
                                                  label="Offline"/>

                            </RadioGroup>



                        </div>
                        <button type="submit" className="btn w-30  p-2 bt border-dark " onClick={this.createInfoSession}>Create</button>
                        <button type="submit" className="btn w-30  p-2 bt border-dark" onClick={this.updateInfoSession}>Update</button>
                        <button type="submit" className="btn w-30 p-2 bt border-dark" onClick={this.sendInvitation}>Notify</button>
                        <ToastContainer/>

                    </div>
                </div>


            </div>
        );
    };

    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
}

export default multiButtonCard;
