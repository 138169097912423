import React from 'react';
import '../../css/modal.css'
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


export default function TermsConditionModal() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <p onClick={handleOpen} className="terms">Terms and Conditions</p>
            {/*<a href=" #" onClick={handleOpen}  className="terms">Terms and Conditions</a>*/}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                className="h-90"

            >
                <div>
                    <div className="col-sm-6 d-flex">
                        <a href="#" onClick={handleClose}> X Close</a>

                    </div>

                </div>
                <div className="termsheading scrollbar scrollbar-primary">
                    <DialogTitle id="scroll-dialog-title">SEMICOLON TERMS AND CONDITIONS</DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id="scroll-dialog-description"
                            className="termscontent"
                        >
                            <h6 id="scroll-dialog-title" className="font-weight-bold"> INTRODUCTION</h6>

                            <p id="simple-modal-description">

                                Welcome to Semicolon!
                                These are the Terms and Conditions governing the use of Semicolon Platforms
                                and the agreement that operates between You and Semicolon
                                Village Limited. These Terms and Conditions set out the rights and obligations
                                of all users regarding the use of our Platforms.
                            </p>
                            <h6 id="scroll-dialog-title" className="font-weight-bold"> DEFINITIONS</h6>

                            <p>
                                For the purposes of these Terms and Conditions:
                                <ul>
                                    <li>Affiliate means an entity that controls, is controlled by or is under
                                        common control with a party, where "control" means ownership of
                                        50% or more of the shares, equity interest or other securities
                                        entitled to vote for election of directors or other managing authority.
                                    </li>
                                    <li>Company (referred to as either "the Company", "We", "Us" or "Our" in
                                        this Agreement) refers to Semicolon Village Limited, 312
                                        Herbert Macaulay Way, Sabo, Yaba, Lagos.
                                    </li>
                                    <li>Country refers to: Nigeria</li>
                                    <li>Service (referred to as either “the Site” “the Platforms” refers
                                        to any Semicolon Website and LAMP).
                                    </li>
                                    <li>Terms and Conditions (also referred as "Terms") mean these Terms
                                        and Conditions that form the entire agreement between You and
                                        the Company regarding the use of the Service.
                                    </li>
                                    <li>Third-party Social Media Service means any services or content
                                        (including data, information, products or services) provided by a
                                        thirdparty that may be displayed, included or made available by the Service.
                                    </li>
                                    <li>Website refers to all Semicolon websites, including www.semicolon.africa
                                    </li>
                                    <li>User Account means a unique account created for you to access our platforms or
                                        parts of our platforms.
                                    </li>
                                    <li>Service Provider means any natural or legal person who processes the Information
                                        on behalf of the Company. It refers to third-party
                                        companies or individuals employed by the Company to facilitate the Service, to
                                        provide the Service on behalf of the Company, to
                                        perform services related to the Service or to assist the Company in analyzing
                                        how the Service is used.
                                    </li>
                                    <li>Cookies are small files that are placed on your computer, mobile device or any
                                        other device by a website, containing the details of
                                        Your browsing history on that website among its many uses.
                                    </li>
                                    <li> Usage Data refers to the Information collected automatically, either generated
                                        by the use of our platform or from the platform
                                        infrastructure itself. Data and Information will be used interchangeably for
                                        ease of reference.
                                    </li>
                                    <li>You means the individual accessing or using the Service, or the company,
                                        or other legal entity on behalf of which such individual is
                                        accessing or using the Service, as applicable.
                                    </li>
                                </ul>
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> ACCEPTING THE TERMS </h6>

                            <p>

                                In order to access our platforms, you must firstly agree to be bound by these
                                Terms and Conditions. You may not access our platforms if you do
                                not accept the Terms.<br/><br/>
                                You can accept the Terms by simply accessing our platforms. You understand
                                and agree that Semicolon will treat your use of our platforms as
                                acceptance of the Terms from that point onwards.<br/><br/>
                                You may not access our platforms and may not accept the Terms if (a)
                                you are not of legal age to form a binding contract with Semicolon, or (b)
                                you are a person who is either barred or otherwise legally prohibited
                                from receiving or using the Service under the laws of the country in which
                                you are resident or from which you access our platforms.
                            </p>
                            <h6 id="scroll-dialog-title" className="font-weight-bold"> PRIVACY POLICY </h6>

                            <p>

                                This Privacy Policy describes our policies and procedures on the collection, use, share,
                                sale and disclosure of the information you provide us, when
                                you use our platform.<br/><br/>
                                By continuing, you agree to the collection and use of your personal information in
                                accordance with this privacy policy.<br/><br/>

                                <ol type="1">
                                    <li>COLLECTION OF YOUR PERSONAL INFORMATION<br/><br/>
                                        <ol type="a">
                                            <li>Personal Information<br/>
                                                We may collect your personal Information when you create an account on
                                                our platforms, subscribe to our monthly newsletters,
                                                or submit an application to us.<br/><br/>
                                                Personal Information may include, but not limited to;<br/><br/>
                                                <ul>
                                                    <li>First Name and Last Name</li>
                                                    <li>Email Address</li>
                                                    <li>Phone Number</li>
                                                    <li>Physical Address</li>
                                                </ul>
                                            </li>
                                            <br/>
                                            <li>Application Information<br/><br/>
                                                If you submit an application to us, your personal Information will be
                                                collected and shared with our Admissions team for
                                                evaluation purposes. Our Admissions team will treat your application in
                                                a confidential manner.<br/><br/>
                                                We may use your application, and all information or materials included
                                                in your application for any Semicolon-related purpose
                                                such as;<br/><br/>
                                                <ul>
                                                    <li>Sharing information with our Partners</li>
                                                    <li>Tracking applicants and application trends</li>

                                                </ul>
                                            </li>
                                            <br/>
                                            <li>Communication Information<br/><br/>
                                                If you subscribe to our monthly newsletters or communicate with us, we
                                                may collect your name, contact information and the
                                                contents of any messages you send for marketing purposes.
                                            </li>
                                            <br/>
                                            <li>Personal Information We Collect Through Our Social Media Pages<br/><br/>
                                                We have pages on social media sites like Instagram, Facebook, Medium,
                                                Twitter and LinkedIn (“Social Media Pages”). When you
                                                interact with our Social Media Pages, we will collect Personal
                                                Information that you elect to provide to us, such as your contact
                                                details (“Social Information”).
                                            </li>
                                            <br/>
                                            <li>Personal Information We Receive Automatically from Your Use of the
                                                Service<br/><br/>
                                                When you visit, use, and interact with the Service, we may receive
                                                certain information about your visit, use, or interactions
                                                (“Technical Information”). For example, we may monitor the number of
                                                people that visit the Service, peak hours of visits, which
                                                page(s) are visited, the domains our visitors come from (e.g.,
                                                google.com, yahoo.com, etc.), and which browsers people use to
                                                access the Service (e.g., Chrome, Firefox, Microsoft Internet Explorer,
                                                etc.), broad geographical information, and navigation
                                                pattern. In particular, Technical Information includes the following,
                                                which is created and automatically logged in our systems:
                                            </li>
                                            <br/>
                                            <li>Log Information<br/><br/>
                                                Information that your browser automatically sends whenever you visit the
                                                Site (“log Information”). Log Information includes
                                                your Internet Protocol address, browser type and settings, the date and
                                                time of your request, and how you interacted with the
                                                Site.
                                            </li>
                                            <br/>
                                            <li>Device information<br/><br/>
                                                Includes name of the device, operating system, and browser you are
                                                using. Information collected may depend on the type of
                                                device you use and its settings.
                                            </li>
                                            <br/>
                                            <li>Usage Information<br/><br/>
                                                We collect information about how you use our Platforms, such as your
                                                Device’s Internet Protocol Address (IP Address), the types
                                                of content that you view or engage with, the features you use, the
                                                actions you take, and the time, frequency, and duration of
                                                your activities and other diagnostic Information.
                                            </li>
                                            <br/>
                                            <li>Tracking Technologies and Cookies<br/><br/>
                                                We use cookies and other similar tracking technologies to operate and
                                                administer our Platforms, gather usage Information on
                                                our Platforms, and improve your experience on it.<br/><br/>
                                                Tracking technologies used are beacons, tags, and scripts to collect and
                                                track information and to improve and analyze Our
                                                Service.<br/><br/>
                                                You can instruct Your browser to refuse all Cookies or to indicate when
                                                a Cookie is being sent. However, if You do not accept
                                                Cookies, you may not be able to use some parts of our
                                                Platforms.<br/><br/>
                                                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
                                                remain on your personal computer or mobile device when
                                                You go offline, while Session Cookies are deleted as soon as You close
                                                your web browser.<br/><br/>
                                                We use both session and persistent Cookies for the purposes set out
                                                below:<br/>
                                                <ul>
                                                    <li>Necessary / Essential Cookies <br/>
                                                        Type: Session Cookies <br/>
                                                        Administered by: Us <br/>
                                                    </li>
                                                </ul>
                                                <br/>
                                                Purpose:<br/><br/>
                                                These Cookies are essential to provide You with services available
                                                through the Platforms and to enable You to use some of its
                                                features. They help to authenticate users and prevent fraudulent use of
                                                user accounts. Without these Cookies, the services that
                                                You have asked for cannot be provided, and We only use these Cookies to
                                                provide You with those services.<br/><br/>

                                                <ul>
                                                    <li>Cookies Policy / Notice Acceptance Cookies <br/>
                                                        Type: Persistent Cookies <br/>
                                                        Administered by: Us <br/>
                                                    </li>
                                                </ul>
                                                <br/>

                                                purpose:<br/><br/>
                                                These Cookies identify if users have accepted the use of cookies on the
                                                Website.<br/><br/>

                                                <ul>
                                                    <li>Functionality Cookies <br/>
                                                        Type: Persistent Cookies <br/>
                                                        Administered by: Us <br/>
                                                    </li>
                                                </ul>
                                                <br/>
                                                purpose:<br/><br/>
                                                These Cookies allow us to remember choices You make when You use the
                                                Website, such as remembering your login details or
                                                language preference. The purpose of these Cookies is to provide You with
                                                a more personal experience and to avoid You having
                                                to re-enter your preferences every time You use the Platforms.<br/><br/>

                                                <ul>
                                                    <li>Tracking and Performance Cookies <br/>
                                                        Type: Persistent Cookies <br/>
                                                        Administered by: Third-Parties <br/>
                                                    </li>
                                                </ul>
                                                <br/>

                                                purpose:<br/><br/>
                                                These Cookies are used to track information about traffic to the Website
                                                and how users use the Website. The information
                                                gathered via these Cookies may directly or indirectly identify you as an
                                                individual visitor. This is because the information
                                                collected is typically linked to a pseudonymous identifier associated
                                                with the device you use to access the Website. We may
                                                also use these Cookies to test new advertisements, pages, features or
                                                new functionality of the Platform to see how our users
                                                react to them.<br/><br/>


                                            </li>
                                        </ol>
                                    </li>
                                    <li>USAGE OF YOUR PERSONAL INFORMATION<br/><br/>
                                        The Company may use your personal Information for the following purposes:
                                        <ul>
                                            <li>To provide and maintain our platforms, including to monitor the usage of
                                                our platforms.
                                            </li>
                                            <li>To manage your user account: to manage your registration as a user of
                                                the Service. The Personal Information You
                                                provide can give You access to different functionalities of the Platform
                                                that are available to You as a registered user
                                            </li>
                                            <li>To contact You: To contact You by email, telephone calls, SMS, or other
                                                equivalent forms of electronic communication,
                                                such as a mobile application's push notifications regarding updates or
                                                informative communications related to the
                                                functionalities, products or contracted services, including the security
                                                updates, when necessary or reasonable for
                                                their implementation.
                                            </li>
                                            <li>To provide You with news, and general information about our monthly
                                                events which we offer that are similar to those
                                                that you have already enquired about unless You have opted not to
                                                receive such information.
                                            </li>
                                            <li>To manage Your access: To attend and manage your access to Us.</li>
                                        </ul>
                                    </li>
                                    <br/>
                                    <li>SHARE AND SALE OF YOUR PERSONAL INFORMATION<br/><br/>
                                        In certain circumstances we may share or sell your Personal Information to third
                                        parties without further notice to you, unless required
                                        by the law, as set forth below:

                                        <ul>
                                            <li>Vendors and Service Providers: To assist us in meeting business
                                                operations needs and to perform certain services and
                                                functions, we may share Personal Information with vendors and service
                                                providers, including providers of hosting services,
                                                cloud services, and other information technology services providers,
                                                event management services, email communication
                                                software and email newsletter services, advertising and marketing
                                                services, and web analytics services. Pursuant to our
                                                instructions, these parties will access, process, or store Personal
                                                Information in the course of performing their duties to us.
                                            </li>
                                            <li>Business Transfers: We may share, sell, or transfer your personal
                                                Information in connection with, or during negotiations of,
                                                any merger, sale of Company assets, financing, or acquisition of all or
                                                a portion of our business to another Company. Your
                                                Personal Information and other information may be shared in the
                                                diligence process with counterparties and others assisting
                                                with the Transaction and transferred to a successor or affiliate as part
                                                of that Transaction along with other assets.
                                            </li>
                                            <li>Affiliates: We may share or sell your personal Information with our
                                                affiliates, in which case, we will require those affiliates
                                                to honor this privacy policy.
                                            </li>
                                            <li>Business Partners: We may share or sell your personal Information with
                                                our partners. By agreeing to our privacy policy, you
                                                give us access to sell your Information to our partner organizations and
                                                third parties.
                                            </li>
                                            <li>To manage Your access: To attend and manage your access to Us.</li>
                                        </ul>
                                    </li>
                                    <br/>
                                    <li>DISCLOSURE OF YOUR PERSONAL INFORMATION<br/><br/>
                                        <ul>
                                            <li>Business Transactions: If the Company is involved in a merger,
                                                acquisition or asset sale, Your Personal Information may be
                                                transferred. We may provide notice before Your Personal Information is
                                                transferred and becomes subject to a different
                                                Privacy Policy.<br/>
                                            </li>
                                            <li>Legal Requirements: If required to do so by law or in the good faith
                                                belief that such action is necessary to (i) comply with a
                                                legal obligation, including to meet national security or law enforcement
                                                requirements, (ii) protect and defend our rights or
                                                property, (iii) prevent fraud, (iv) act in urgent circumstances to
                                                protect the personal safety of users of the Services, or the
                                                public, or (v) protect against legal liability.<br/>
                                            </li>
                                        </ul>

                                    </li>
                                    <br/>
                                    <li>RETENTION OF YOUR PERSONAL INFORMATION<br/><br/>
                                        The Company will retain Your Personal Information only for as long as is
                                        necessary for the purposes set out in this Privacy Policy. We
                                        will retain and use Your Personal Information to the extent necessary to comply
                                        with our legal obligations (for example, if we are
                                        required to retain your Information to comply with applicable laws), resolve
                                        disputes, and enforce our legal agreements and policies.<br/><br/>
                                        The Company will also retain Usage Information for internal analysis purposes.
                                        Usage Information is generally retained for a shorter
                                        period of time, except when this Information is used to strengthen the security
                                        or to improve the functionality of Our Service, or We
                                        are legally obligated to retain this Information for longer time periods.<br/>

                                    </li>
                                    <br/>
                                    <li>SECURITY OF YOUR PERSONAL INFORMATION<br/><br/>
                                        The security of Your Personal Information is important to Us, but remember that
                                        no method of transmission over the Internet, or
                                        method of electronic storage is 100% secure. While We strive to use commercially
                                        acceptable means to protect Your Personal
                                        Information, we cannot guarantee its absolute security. We implore you to take
                                        necessary steps to do your part to protect your
                                        Information on our platforms such as, using strong passwords and not sharing
                                        your passwords.
                                    </li>
                                    <br/>
                                    <li>ANALYTICS <br/><br/>
                                        We may use third party service providers to monitor and analyze the use of our
                                        Platforms.
                                    </li>
                                    <br/>
                                    <li>DETAILED INFORMATION ON THE PROCESSING OF YOUR PERSONAL INFORMATION<br/><br/>
                                        Service Providers have access to Your Personal Information only to perform their
                                        tasks on Our behalf and are obligated not to disclose
                                        or use it for any other purpose.
                                    </li>
                                    <br/>
                                    <li>CHANGES TO THE PRIVACY POLICY<br/><br/>
                                        We may change this Privacy Policy at any time. When we do, we will post an
                                        updated version on this page, unless another type of
                                        notice is required by the applicable law. By continuing to use our Platforms or
                                        providing us with Personal Information after we have
                                        posted an updated Privacy Policy, or notified you by other means if applicable,
                                        you consent to the revised Privacy Policy and practices
                                        described in it.
                                    </li>
                                    <br/>
                                </ol>
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> USER REGISTRATION </h6>

                            <p>

                                You may be required to register with the Platforms. You agree to keep your
                                password confidential and will be responsible for all use of your
                                account and password. We reserve the right to remove, reclaim, or change a
                                username you select if we determine, in our sole discretion, that
                                such username is inappropriate, obscene, or otherwise objectionable.
                            </p>
                            <h6 id="scroll-dialog-title" className="font-weight-bold"> USER DATA </h6>
                            <p>

                                We will maintain certain data that you transmit to the Site for the purpose of
                                managing the Site, as well as data relating to your use of the Site.
                                Although we perform regular routine backups of data, you are solely responsible
                                for all data that you transmit or that relates to any activity you
                                have undertaken using the Site.<br/><br/>
                                You agree that we shall have no liability to you for any loss or corruption
                                of any such data, and you hereby waive any right of action against us
                                arising from any such loss or corruption of such data.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> INTELLECTUAL PROPERTY
                                RIGHTS </h6>
                            <p>

                                Unless otherwise indicated, the platforms are our proprietary property and
                                all source code, databases, functionality, software, website designs,
                                audio, video, text, photographs, and graphics on the Site (collectively,
                                the “Content”) and the trademarks, service marks, and logos contained
                                therein (the “Marks”) are owned or controlled by us or licensed to us,
                                and are protected by copyright and trademark laws and various other
                                intellectual property rights and unfair competition laws of Nigeria,
                                foreign jurisdictions, and international conventions.
                            </p>
                            <h6 id="scroll-dialog-title" className="font-weight-bold"> COPYRIGHT COMPLAINTS </h6>
                            <p>

                                Semicolon respects the intellectual property of others, and we ask our
                                users to do the same. If you believe that your work has been copied in a
                                way that constitutes copyright infringement, or that your intellectual
                                property rights have been otherwise violated, you should notify Semicolon
                                of your infringement claim and will process and investigate the alleged
                                infringement.<br/><br/>
                                A written notification of claimed copyright infringement should be addressed
                                to:<br/><br/>
                                Semicolon Village Limited<br/>
                                312 Herbert Macaulay Way<br/>
                                Sabo, Yaba<br/>
                                Lagos, Nigeria<br/>
                            </p>
                            <h6 id="scroll-dialog-title" className="font-weight-bold"> LINKS TO OTHER WEBSITES </h6>
                            <p>

                                Our Platforms may contain links to third-party websites or services that are not owned
                                or controlled by the Company.
                                The Company has no control over, and assumes no responsibility for, the content, privacy
                                policies,
                                or practices of any third-party web sites or
                                services. You further acknowledge and agree that the Company shall not be responsible or
                                liable,
                                directly or indirectly, for any damage or loss
                                caused or alleged to be caused by or in connection with the use of or reliance on any
                                such
                                content, goods or services available on or through any
                                such web sites or services.<br/><br/>
                                We strongly advise You to read the terms and conditions and privacy policies of any
                                third-party web sites or services that You visit.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> TERMINATION </h6>

                            <p>

                                We may terminate or suspend Your access immediately, without prior notice or liability,
                                for any reason whatsoever, including without limitation
                                if You breach these Terms and Conditions.<br/><br/>
                                Upon termination, your right to access our platforms will cease immediately.
                                Any payment made upon admission shall be non refundable.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> “AS IS” AND “AS AVAILABLE”
                                DISCLAIMER</h6>

                            <p>

                                Your use of our platforms is at your sole risk. Our platforms are provided on an “AS IS”
                                AND “AS AVAILABLE” basis. Semicolon expressly disclaims
                                all warranties of any kind, whether express, implied or statutory, including but not
                                limited to the implied warranties or merchantability, fitness
                                for a particular purpose, title and non-infringement.<br/><br/>
                                Semicolon makes no warranty that (i) the platforms will meet your requirements, (ii) the
                                platforms will be uninterrupted, timely, secure or error free, or (iii)
                                the results that may be obtained from the use of the platforms will be accurate or
                                reliable.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> LIMITATION OF LIABILITY</h6>

                            <p>

                                You expressly understand and agree that Semicolon will not be liable for any indirect,
                                incidental, special, consequential, exemplary damages, or
                                damages for loss of profits including but not limited to, damages for loss of goodwill,
                                use, data or other intangible losses (even if Semicolon has
                                been advised of the possibility of such damages), whether based on contract, tort,
                                negligence, strict liability or otherwise, resulting from: (i) the
                                use or the inability to use the site or any related information; (ii) unauthorized
                                access to or alteration of your transmissions or data; (iii) statements
                                or conduct of any third party (including users) on the site; or (iv) any other matter
                                relating to the site. in no event will Semicolon’s total liability
                                to you for all damages, losses or causes of action exceed Ten Thousand Naira
                                (N10,000.00).

                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> GOVERNING LAW</h6>

                            <p>

                                These Terms and Conditions and your use of our platforms are governed by and construed
                                in accordance with the laws of Lagos state and the
                                Federal Republic of Nigeria.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> DISPUTE RESOLUTION</h6>

                            <p>

                                To expedite resolution and control the cost of any dispute, controversy,
                                or claim related to these Terms and Conditions (each a "Dispute" and
                                collectively, the “Disputes”) brought by either you or us (individually,
                                a “Party” and collectively, the “Parties”), the Parties agree to first attempt
                                to negotiate any Dispute (except those Disputes expressly provided below)
                                informally for at least 28 days before initiating arbitration. Such
                                informal negotiations commence upon written notice from one Party to the other
                                Party.<br/><br/>
                                If the Parties are unable to resolve a Dispute through informal negotiations,
                                the Dispute will be finally and exclusively resolved by binding
                                arbitration.<br/><br/>
                                he arbitration shall be commenced and conducted according to the
                                Arbitration and Conciliation Act of Nigeria. The parties agree, that the
                                arbitration proceedings shall be conducted by a sole arbitrator to be
                                selected by the Chairman of Lagos State Court of Arbitration.<br/><br/>
                                The parties agree to bear the cost of arbitration independently.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> INDEMNIFICATION</h6>

                            <p>

                                You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
                                affiliates, and all of our respective officers, agents, partners,
                                and employees, from and against any loss, damage, liability, claim,
                                or demand, including reasonable attorneys’ fees and expenses, made by any
                                third party due to or arising out of: (1) use of the Site; (2) breach of
                                these Terms and Conditions; (3) any breach of your representations and
                                warranties set forth in these Terms and Conditions; (4) your violation
                                of the rights of a third party, including but not limited to intellectual property
                                rights; or (7) any overt harmful act toward any other user of the Site with
                                whom you connected via the Site.<br/><br/>
                                Notwithstanding the foregoing, we reserve the right, at your expense, to assume
                                the exclusive defense and control of any matter for which you
                                are required to indemnify us, and you agree to cooperate, at your expense, with
                                our defense of such claims. We will use reasonable efforts to
                                notify you of any such claim, action, or proceeding which is subject to this
                                indemnification upon becoming aware of it.
                            </p>
                            <h6 id="scroll-dialog-title" className="font-weight-bold"> ELECTRONIC COMMUNICATIONS,
                                TRANSACTIONS, AND SIGNATURES</h6>

                            <p>

                                Visiting the Site, sending us emails, and completing online forms constitute electronic
                                communications.
                                You consent to receive electronic
                                communications, and you agree that all agreements, notices, disclosures, and other
                                communications
                                we provide to you electronically, via email
                                and on the Site, satisfy any legal requirement that such communication be in
                                writing.<br/><br/>

                                YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
                                RECORDS,
                                AND TO ELECTRONIC DELIVERY OF
                                NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
                                SITE.<br/><br/>

                                You hereby waive any rights or requirements under any statutes, regulations, rules,
                                ordinances,
                                or other laws in any jurisdiction which require an
                                original signature or delivery or retention of non-electronic records, or to payments or
                                the
                                granting of credits by any means other than electronic
                                means.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> SEVERABILITY</h6>

                            <p>

                                If any provision of these Terms is held to be unenforceable or invalid, such provision
                                will be changed and interpreted to accomplish the objectives
                                of such provision to the greatest extent possible under applicable law and the remaining
                                provisions will continue in full force and effect.

                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> WAIVER</h6>

                            <p>

                                Except as provided herein, the failure to exercise a right or to require performance of
                                an obligation under this Terms shall not affect a party’s
                                ability to exercise such right or require such performance at any time thereafter nor
                                shall the waiver of a breach constitute a waiver of any
                                subsequent breach.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> CHANGES TO THESE TERMS AND
                                CONDITIONS</h6>

                            <p>

                                We reserve the right, at our sole discretion, to modify or replace these Terms at any
                                time. We will alert you about any changes by updating the
                                “Last updated” date of these Terms and Conditions, and you waive any right to receive
                                specific notice of each such change.<br/><br/>

                                It is your responsibility to periodically review these Terms and Conditions to stay
                                informed of updates. You will be subject to, and will be deemed
                                to have been made aware of and to have accepted, the changes in any revised Terms and
                                Conditions by your continued use of the platforms after
                                the date such revised Terms and Conditions are posted.
                            </p>

                            <h6 id="scroll-dialog-title" className="font-weight-bold"> CONTACT US</h6>

                            <p>

                                If you have any questions about these Terms and Conditions, you can contact us on
                                info@semicolon.africa.If you have any questions about these Terms and Conditions, you
                                can contact us on info@semicolon.africa.
                            </p>


                        </DialogContentText>
                    </DialogContent>
                    <button className="w-15-ns d-block  closeterms" onClick={handleClose}>Close</button>
                    <br/><br/>
                </div>
            </Dialog>
        </div>
    );
};
