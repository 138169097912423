import React, {Component} from 'react';
import '../../css/modal.css'

import {ToastContainer} from "react-toastify";


class ButtonCard extends Component {
    render() {


        return (


            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div
                                    className="text-xs font-weight-bold text-primary text-uppercase mb-1">{this.props.heading}</div>

                                <div className="form-group mt-3">
                                </div>
                                <button type="submit" ref={btn => {
                                    this.btn = btn;
                                }} onClick={this.props.buttonAction}
                                        className={this.props.buttonStyle}>{this.props.heading}</button>

                            </div>
                            <div className="col-auto">
                                {/*icon goes here*/}
                                {/*<i class="fas fa-calendar fa-2x text-gray-300"></i>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <ToastContainer/>
                </div>
            </div>
        );
    };
}

export default ButtonCard;
