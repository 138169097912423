import React, {Component} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Undergraduate from "../formData/Undergraduate";

class FormUnderGraduate extends Component {
    render() {
        const {
            handleChange,
            // onData,
            // handleNameChange,
            underGradInstitution,
            underGradDegreeType,
            underGradCourse,
            underGradGrade,
            underGradGradYear,
            formData,
            graduateLabelText
        } = this.props
        return (
            <div className="p-0 shad">
                <h4 style={{
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "26px",
                    /* identical to box height */

                    display: "flex",
                    alignItems: "center",

                    color: "#960C0C"
                }}>{graduateLabelText}</h4>
                <div>
                    <div>
                        <Autocomplete
                            freeSolo
                            autoSelect={true}
                            onChange={handleChange("underGradInstitution")}
                            // onInputChange={handleChange("underGradInstitution")}
                            value={underGradInstitution}
                            options={formData.higherInstitutions}
                            getOptionLabel={option => option.title || option}
                            renderInput={params => (
                                <TextField {...params} label={Undergraduate[0].question}
                                           margin="normal" variant="outlined"
                                           color="secondary"
                                           name="underGradInstitution"
                                           required
                                />
                            )}
                        />
                    </div>

                    <div className="d-flex">
                        <div className="col-md-4 p-0">
                            <TextField
                                margin="normal"
                                select
                                label={Undergraduate[1].question}
                                value={underGradDegreeType}
                                color="secondary"
                                onChange={handleChange("underGradDegreeType")}
                                variant="outlined"
                            >
                                {Undergraduate[1].options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </div>
                        <div className="col-md-8 pr-0">
                            <Autocomplete
                                freeSolo
                                autoSelect={true}
                                onChange={handleChange("underGradCourse")}
                                value={underGradCourse}
                                options={formData.courses}
                                getOptionLabel={option => option}
                                renderInput={params => (
                                    <TextField {...params} label={Undergraduate[2].question}
                                               margin="normal" variant="outlined"
                                               color="secondary"
                                               required
                                    />
                                )}
                            />
                        </div>

                    </div>
                </div>
                <div className="d-flex">
                    <div className="col-md-6 pl-0">
                        <TextField
                            margin="normal"
                            select
                            label={Undergraduate[3].question}
                            value={underGradGradYear}
                            color="secondary"
                            onChange={handleChange("underGradGradYear")}
                            variant="outlined"
                        >
                            {Undergraduate[3].options
                                .filter(option => !(option > new Date().getFullYear() + 4))
                                .map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}

                        </TextField>
                    </div>
                    <div className="col-md-6 pr-0">
                        <TextField
                            margin="normal"
                            select
                            label={Undergraduate[4].question}
                            value={underGradGrade}
                            color="secondary"
                            onChange={handleChange("underGradGrade")}
                            variant="outlined"
                        >
                            {Undergraduate[4].options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}

                        </TextField>
                    </div>

                </div>
            </div>
        );
    }
}

export default FormUnderGraduate;