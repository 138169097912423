import React, {Component} from 'react';
import {ReactComponent as SemLogo} from "../../images/semfulllogo.svg";
import DeskIll from "../../images/desk-illu.svg"
import '../../css/login.css'
import axios from 'axios';
import {TextField} from "@material-ui/core";
import SignInButton from "./SignInButton";
import * as EmailValidator from 'email-validator'
import {getAllAvailableInfoSessionForSelfInvite, InfoSessionInviteAPI} from "../routes/Api's";
import MenuItem from "@material-ui/core/MenuItem";


class InfoSessionInvite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            infoSession: "",
            infoSessionList: [],

        }
    }

    componentDidMount(){
       axios(getAllAvailableInfoSessionForSelfInvite,'')
            .then(res => {
                this.setState({infoSessionList: res.data});
            })
            .catch(err => {
                    this.handleError(err)
                }
            );

    }



     changeEvent = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            this.processRequestPasswordRequest();
        }
    };




    disableButtonPress = () => {
        this.btn.setAttribute("disabled", "disabled");
    };


    enableButtonPress = () => {
        this.btn.removeAttribute("disabled");

    };

    mailValidation = (email) => {
        if (!EmailValidator.validate(email)) {
            const errorMessage = document.querySelector("#errorMessage");
            errorMessage.textContent = `Please enter a valid email address`;
        } else {
            return true
        }

    };
    getInfoSessionId(infoSession) {
        const{infoSessionList} = this.state;

       return infoSessionList.find(session => session.sessionDetails === infoSession);
        // infoSessionList.filter(session => {
        //     return session.sessionDetails === infoSession
        // })
    }

    processRequestPasswordRequest = (e) => {

        const {email, infoSession} = this.state;
        console.log(infoSession);
      const infoSessionId =   this.getInfoSessionId(infoSession);
      console.log(infoSession);
      console.log(infoSessionId);
        e.preventDefault();
        this.disableButtonPress();

        if (this.mailValidation(email)) {
            axios.post(InfoSessionInviteAPI(email, infoSessionId.infoSessionId))
                .then(res => {

                    if (res.status === 200) {
                        // alert("Application Successful");'

                        this.props.history.push({
                            pathname: '/infoSession/invite_successful',
                            // props:{infoSession:this.state.infoSession}
                            state: {
                                infoSession: this.state.infoSession,
                                email: this.state.email
                            }
                        })

                    } else {
                        this.props.history.push({
                            pathname: '/infoSession/invite_successful',
                            // props:{infoSession:this.state.infoSession}

                            state: {
                                infoSession: this.state.infoSession,
                                email: this.state.email
                            }
                        })

                    }


                })
                .catch(error => {
                    const errMessage = document.querySelector("#errorMessage");
                    this.enableButtonPress();
                    if (error.response === undefined) {
                        errMessage.textContent = "Connect to the internet and try again";

                    } else if (error.response.status === 400) {
                        errMessage.textContent = "InfoSession expired, please choose another";
                    } else {
                        errMessage.textContent = error.response.data.message;
                    }
                })
            // .catch(err => {
            //     const error = document.querySelector("#errMessage");
            //
            //
            //     function emptyError() {
            //         error.textContent = ""
            //     }
            //
            //     setTimeout(emptyError, 5000)
            // });

        }
        // } else {
        //     this.enableButtonPress();
        //
        // }

    };

    render() {
        let {email, infoSessionList, infoSession} = this.state;
        console.log(infoSessionList)

        const infoSessionDropdown = {
            infoSessionType:[infoSessionList]
   // const infoSessionDropdown = {
   //          infoSessionType: ['Thursday, August 6, 2020; 12:00 noon', 'Saturday, August 8, 2020; 12:00 noon'],

        }

        // const  { classes } = this.props;
        return (
            <div className="form-container w-100 d-flex">

                <div className="left-form-div">
                    <div className="container d-flex justify-content-center align-items-center h-100">
                        <div className="d-flex left-content flex-column mx-auto pl-lg-5 h-100">
                            <div className="h-25 d-flex">
                                <div className="d-flex align-items-center" style={{marginBottom: 70 + 'px'}}>
                                    <SemLogo width="200px"/>
                                </div>
                            </div>
                            <div className="welmsg welreset"><h3>Information Session</h3>
                                <h6> Please choose your preferred Session</h6>
                            </div>

                            <div className="my-form m-0">
                                <form onSubmit={this.processRequestPasswordRequest}>

                                    <TextField className="newField" type="email"
                                               name="email" value={email}
                                               onChange={this.changeEvent} label="Email Address "
                                               fullWidth variant="outlined" margin="normal"
                                               color="secondary"
                                               required
                                    />


                                    <TextField onChange={this.changeEvent}

                                               select label="Preferred InfoSession" name='infoSession'
                                               variant="outlined" value={infoSession} margin="normal"
                                               color={"secondary"} className="newField emailType" required
                                    >


                                    {infoSessionList.map((infoSession) => (
                                            <MenuItem key={infoSession.infoSessionId} value={infoSession.sessionDetails}>
                                                {infoSession.sessionDetails}
                                            </MenuItem>
                                        ))}

                                    </TextField>


                                    <p id="errorMessage" className="text-danger"></p>
                                    <button className="d-block w-100 p-3  bt bigbt " ref={btn => {
                                        this.btn = btn;
                                    }} onSubmit={this.processRequestPasswordRequest} onKeyPress={this.onEnterPress}>
                                        <p>Receive Invitation Email</p></button>
                                    <p id="err" className="text-danger"></p>
                                </form>


                                {/*<h2 className="forget"> Remember your password? <Link to={`/login`}*/}
                                {/*                                                      className="terms"> Log In </Link>*/}
                                {/*</h2>*/}


                            </div>

                        </div>
                    </div>
                </div>
                <div className="right-ill-div login-right">
                    <SignInButton/>
                    <div className="form-img d-flex justify-content-center align-items-center">
                        <img alt="doorIll" src={DeskIll} width="500px"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfoSessionInvite;