import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import '../../css/fulldiv.css'
import Sidemenu from "./Sidemenu";
import StudentIdDiv from "../admin/StudentIdDiv";
import Summary from "./Summary";
import FullInfo from "./FullInfo";
import ApplicantProfile from "./ApplicantProfile";
import {get} from "axios";
import {getApplicantByEmail} from "../routes/Api's";
import TestInfo from "./TestInfo";
import {consoleLog} from "../admin/consoleLog";


class FullDiv extends Component {
    constructor(props) {
        super(props);

        this.state={
            applicant:{
                email:''
            },
            applicantDocument:{},
            questionnaire:{},
            aptitudeTest:{},

        };

    }

    componentDidMount() {

        this.getApplicantInfo();

    }

    getApplicantInfo() {
        const email = this.props.match.params.id
        const header =JSON.parse(sessionStorage.getItem('headers'))
        console.log(header)
        get(getApplicantByEmail(email),{headers:header})
            .then(res =>{
                console.log(res.data)
                this.setState({ applicant :res.data, applicantDocument: res.data.applicantDocument, questionnaire:res.data.applicantDocument.questionnaire,aptitudeTest: res.data.applicantDocument.aptitudeTest});

            });
    }

    render() {
        const test = this.state.applicantDocument.aptitudeTest
        let testData;
        if(test){
            testData = test.testObj.userTest
        }
        console.log(testData);

        // const path = this.props.match.path
        // const url = this.props.match.url
        return (
            <React.Fragment >
                <Router>
                    <div className="full container-fluid row shadow-2">
                        <div className="top-card"> </div>
                        <div className="col-3 mb-3 side-menu text-center mr-auto">
                            <Sidemenu email={this.props.match.params.id}/>
                        </div>
                        <div className="col-6">
                            {/*<Router>*/}
                                <Switch>
                                    <Route path="/profile/full-info"  component={() => <FullInfo  applicant={this.state.applicant} questionnaire={this.state.questionnaire} applicantDocument={this.state.applicantDocument}/>} />
                                    <Route path="/profile/test"  component={() => <TestInfo   testData={testData} />}/>
                                    {/*<Route path="/profile/:id/full-info" component={() => <FullInfo email={this.props.match.params.id} applicant={this.state.applicant} questionnaire={this.state.questionnaire} applicantDocument={this.state.applicantDocument}/>} />*/}
                                    <Route path="/summary"  component={Summary} />
                                    <Route path="" exact>
                                        <div className="">
                                            <ApplicantProfile applicant={this.state.applicant} questionnaire={this.state.questionnaire} applicantDocument={this.state.applicantDocument}/>
                                        </div>
                                    </Route>

                                </Switch>
                            {/*</Router>*/}
                        </div>
                        {/*<div className="col mb-5 profile-menu ">*/}

                        {/*    <ApplicantProfile email={this.props.match.params.id}/>*/}
                        {/*</div>*/}
                        <div className="col-3 card-class mr-auto">
                            <StudentIdDiv applicant={this.state.applicant} questionnaire={this.state.questionnaire}/>
                        </div>
                    </div>
                </Router>
            </React.Fragment>

        );
    }
}

export default withRouter(FullDiv);