import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import '../../css/fulldiv.css';
// import {ReactComponent as BackSpace} from "bootstrap-icons/icons/backspace.svg";
import {ReactComponent as SummaryIcon} from "../../images/summary-icon.svg";
import {ReactComponent as FullInfo} from "../../images/fullinfo.svg";
import {ReactComponent as AptitudeLogo} from "../../images/Aptitude-Test.svg";
import {ReactComponent as ActivityLogo} from "../../images/ActivityLog.svg";
import {ReactComponent as BackLogo} from "../../images/BackLogo.svg";
import {withRouter} from "react-router";


class Sidemenu extends Component {

   addActive = (e) => {
       e.preventDefault();
       console.log(e.target);
       const activeIndicators = document.querySelectorAll('.active-indicator');
       console.log(activeIndicators);

       activeIndicators.forEach((activeIndicator) => {
           activeIndicator.classList.remove('active-indicator-fill');
       });
       activeIndicators[0].classList.add('active-indicator-fill');
    };
    toggleProfileMenu =(e) => {

    };
    render() {
        
        const {toggleProfileMenu, addActive} = this;
        return (
            <div className="" >
                <div onClick={addActive} className="col-12 mb-5 p-0 d-flex justify-content-between">
                    <span className=" ">
                        <SummaryIcon onClick={toggleProfileMenu} width="50px" className="ml-1"/></span>
                    <Link to={`/profile/${this.props.email}`}>Summary</Link>
                    <div className="active-indicator"></div>
                </div>
                <div className="col-12 mb-5 p-0 d-flex justify-content-between">
                    <span className=" "><FullInfo width="50px" className="ml-1"/></span>
                    <Link to={`/profile/full-info`}><span>Full Info</span></Link>
                    <div className="active-indicator"></div>
                </div>
                <div className="col-12 mb-5 p-0 d-flex justify-content-between">
                    <label className=" "><AptitudeLogo width="50px" className="ml-1"/></label>
                    <Link to="/profile/test"><span>Aptitude Test</span></Link>
                    <div className="active-indicator"></div>
                </div>
                <div className="col-12 mb-5 p-0 d-flex justify-content-between">
                    <label className=" "><ActivityLogo width="50px" className="ml-1"/></label>
                    <Link to="/profile/activity-log"><span>Activity Log</span></Link>
                    <div className="active-indicator"></div>
                </div>
                <div className="col-12 p-0 d-flex justify-content-between">
                    <label className=" "><BackLogo width="30px" className="ml-1"/></label>
                    <Link to="/dashboard"><span className="mr-5">Back To Dashboard</span></Link>
                </div>
            </div>
        );
    }
}

export default withRouter(Sidemenu);