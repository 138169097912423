import React, {Component} from 'react';

class SignInButton extends Component {
    render() {
        return (
            <div className="d-flex partner-btn justify-content-end pr-5 align-items-start">
                <a href="/login">
                    <button className="btn btn-danger">Log In</button>
                </a>
            </div>
        );
    }
}

export default SignInButton;