import React, {Component} from 'react';
import MenuBar from "../../images/bars-solid.svg";

class Hamburger extends Component {
    render() {
        return (
            <div className="hamburger-button d-none">
                <div className="hamburger d-flex justify-content-center align-items-center" onClick={this.props.onOpen}>
                    <img src={MenuBar} alt="" width="30px" height="30px"/></div>
            </div>
        );
    }
}

export default Hamburger;