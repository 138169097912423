import React, {Component} from 'react';
import '../../css/modal.css'
import './fileUploadCard.css'
import axios from 'axios';
import {Progress} from 'reactstrap';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RegisterApplicantsViaCSVAPI} from "../routes/Api's";


class FormCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null
        };

    };

    componentDidMount() {
    //    const {user, headers} = this.state;

    }

    render() {




        return (

            <div className="col-xl-3 col-md-6 mb-4">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Upload Applicant CSV</h6>
                    </div>
                    <div className="card-body">

                        {/*    <div class="container">*/}
                        {/*<div class="row">*/}
                        <div className="offset-md3 col-md6">
                            <div className="form-group files">
                                <label>Upload your CSV</label>
                                <input type="file" name="file" className="form-control" id="file" accept=".csv"
                                       onChange={this.onChangeHandler}/>

                            </div>
                            <div className="form-group">

                                <Progress max="100" color="success"
                                          value={this.state.loaded}>{Math.round(this.state.loaded)}%</Progress>

                            </div>
                            <button type="button" className="w-100 d-block p-3 bt "
                                    onClick={this.onClickHandler}> Upload
                            </button>
                        </div>

                        <div className="form-group">
                            <ToastContainer/>
                        </div>
                    </div>
                </div>
            </div>


        );
    };


    onClickHandler = () => {
        const data = new FormData()
        data.append('file', this.state.selectedFile)
        axios.post(RegisterApplicantsViaCSVAPI, data, {

            headers: JSON.parse(sessionStorage.getItem("headers")),

        }).then(res => {
            toast.success('upload success')
            toast.success(res.data.message);
        })
            .catch(err => {
                toast.error('upload fail')
                toast.error(err.data)
            })
        ;

    };
    onChangeHandler = event => {

        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })

    };

    ProgressEvent = () => {
        this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),

        })
            .then(res => { // then print response status
                console.log(res.statusText)
            })
    };

    setOnChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
}

export default FormCard;
