const Undergraduate = [
    {
        question: "What higher institution did you attend?",
        type: "text",
        options: null,
        response: null
    }, {
        question: "Degree Type",
        type: "Text",
        options: ['OND', 'HND', 'BA', 'BEng', 'BSc', 'BTech'],
        response: null
    }, {
        question: "Course Studied",
        type: "Text",
        options: null,
        response: null
    }, {
        question: "Year of Graduation",
        type: "drop down",
        options: [],
        response: null
    }, {
        question: "Graduation Grade",
        type: "drop down",
        options: ["First Class", "Second Class Upper", "Second Class Lower", "Third Class",
            "Distinction", "Upper Credit", "Lower Credit", "Pass"
        ],
        response: null
    }

]

const currentYear = new Date().getFullYear()
for (let year = currentYear + 5; year > 1995; year--) {
    Undergraduate[3].options.push(year.toString())
}

export default Undergraduate