import React, {Component} from 'react';
import '../../css/modal.css'
import MultiButtonCard from "./multiButtonCard";
import DiscountCodeCreator from "./DiscountCodeCreator";
// import ChangeRoleCard from "./changeRoleCard";
// import FormCard from "./FormCard";
// import InfoSessionNewCard from "./InfoSessionNewCard";



class formsArray extends Component {


    render() {


        return (
            <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">

                    <div className="container-fluid">

                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        </div>


                        <div className="row">


                            <MultiButtonCard/>
                            <DiscountCodeCreator/>




                        </div>
                        <div className="form-group">

                        </div>
                    </div>
                </div>
            </div>


        );
    };
}


export default formsArray;
