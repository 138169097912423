import React, {Component} from 'react';
import Pen from '../../../images/penill.svg';
import ComplaintIcon from '../../../images/complaint.svg';
import CommentIcon from '../../../images/CommentIcon.svg';
import InfoSession from '../InfoSession/InfoSessionModal';
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import UserDashboardAvatar from "./UserDashboardAvatar";
import EyeIcon from "../../../images/EyeIcon.svg";

class UserDashboardSideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    closeNav = () => {
        document.querySelector('.user-right').classList.remove('user-right-in-view');
        document.querySelector('body').classList.remove('side-bar-pause');
    };


    getProfilePicture = () => {
        const {user} = this.props;
        if (!user.hasOwnProperty("profilePictureUrl")) {
            return (
                <Avatar>{user.firstName.slice(0, 1).toUpperCase()}</Avatar>
            );
        } else {
            return (
                <Avatar alt="..." src={user.profilePictureUrl}/>
            )
        }
    }

    render() {
        const {
            user,
            uploadImage,
            imageLoading,
            infoSessionId,
            infoSessionList,
        } = this.props;

        const logOut = () => {
            sessionStorage.removeItem("userData")

        }

        const {firstName, lastName, applicationStatus, applicationId, trait} = user;
        const token = JSON.parse(sessionStorage.getItem("token"));

        const status = applicationStatus.split('_').join(" ");

        return (
            <div className="side-bar w-100 d-flex flex-column align-items-center justify-content-around">
                <div className="close-btn d-sm-none w-100 d-flex justify-content-around"><p style={{cursor: "pointer"}}
                                                                                            onClick={this.closeNav}>Close
                    X</p><a href="/login" onClick={logOut}>Log out</a></div>
                <div className="applicant-details w-100 d-flex flex-column justify-content-around align-items-center">
                    <p className="applicant-id m-0">{applicationId}</p>
                    <label className="applicant-image" htmlFor="file-input">
                        <Badge
                            style={{
                                width: '100%',
                                height: '100%',
                                opacity: imageLoading ? .5 : 1,
                            }}
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={<Avatar alt="..." src={Pen}/>}
                        >
                            <UserDashboardAvatar user={user}/>
                        </Badge>
                    </label>
                    <input id="file-input" type="file" name='image' accept="image/*" onChange={uploadImage}
                           style={{display: 'none'}}/>
                    <div className="applicant-name">
                        <h4 className="m-0">{`${firstName} ${lastName}`}</h4>
                    </div>
                    <div className="applicant-status">
                        <h5 className="m-0 p-2">{status}</h5>
                    </div>
                </div>
                <div className="user-actions w-100 pl-5 pl-sm-4 mx-auto d-flex flex-column h-25 justify-content-start">

                    <a href={`https://mbti.semicolon.africa/redirect/${token}`} target="_blank"
                       rel="noopener noreferrer"
                       className="log-out-box mt-3 d-flex">
                        {/*className="link-to-site-box mt-3 d-flex ">*/}
                        <div className="complaint-icon d-flex justify-content-center">
                            <img src={EyeIcon} className="dropdown-icon" alt=""/>
                        </div>
                        <span>{trait!== ""? "Show MBTI result": "Take MBTI test"}</span>
                    </a>


                    {this.toAttendInfoSession(user, infoSessionId, infoSessionList, status)}


                    <a href="https://www.semicolon.africa" target="_blank"
                       rel="noopener noreferrer"
                       className="link-to-site-box mt-3 d-flex ">
                        <div className="complaint-icon d-flex justify-content-center">
                            <img src={ComplaintIcon} className="dropdown-icon" alt=""/>
                        </div>
                        <span>Go to main site</span>
                    </a>
                    {/*<a href="https://medium.com/semicolon-insights" target="_blank"*/}
                    {/*   rel="noopener noreferrer"*/}
                    {/*   className="log-out-box mt-3 d-flex">*/}
                    {/*    <div className="log-out-icon d-flex justify-content-center">*/}
                    {/*        <img src={EyeIcon} alt="..."/>*/}
                    {/*    </div>*/}
                    {/*    <span>Read our blog</span>*/}
                    {/*</a>*/}
                </div>
            </div>
        );
    }

    toAttendInfoSession = (user, infoSessionId, infoSessionList, status) => {
        if ((status === "APPLIED" || status === "QUESTIONNAIRE" || status === "TESTING" ||
            status === "TESTED" || status === "INVITED FOR INTERVIEW" || status === "ACCEPTED INTERVIEW" || status === "INTERVIEWING")) {
            return (
                <span className="link-to-site-box mt-3 mb-0 d-flex" style={{cursor: "pointer",}}
                      onClick={this.closeNav}>
                        <div className="comment-icon d-flex justify-content-center">
                            <img src={CommentIcon} alt="..."/>
                        </div>
                        <span>
                            <InfoSession
                                user={user}
                                infoSessionId={infoSessionId}
                                infoSessionList={infoSessionList}
                            />
                        </span>
                    </span>
            )
        }
    }
}

export default UserDashboardSideBar;