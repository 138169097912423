import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import axios from "axios";
import {startTestAPI} from "../../routes/Api's";


// let isEnabled= true;
const useStyles = makeStyles({
    title: {
        fontWeight: 'bold',
        color: '#2b1c1c',
        marginBottom: 25,
        fontSize: '1.75rem',
    },
    note: {
        maxWidth: 300,
        fontWeight: 'bold',
    },
    list: {
        color: '#960c0c',
        fontWeight: 'bold',
        paddingLeft: '15px !important',
        marginBottom: 30,
        '& > li': {
            marginBottom: 20,
            fontSize: 12
        }
    },
    cancel: {
        padding: '8px 10px',
        border: '2px solid #FF2E2E',
        background: '#FFF',
        color: '#FF2E2E',
        transition: 'all .5s ease',
        '&:hover': {
            background: '#FF2E2E',
            color: '#FFF',
        },
    },
    start: {
        padding: '8px 10px',
        background: '#FF2E2E',
        color: '#FFF',
        border: '2px solid #FF2E2E',
        transition: 'all .5s ease',
        '&:hover': {
            background: '#960C0C',
            borderColor: '#960C0C',
        },
        '& > a': {
            color: 'inherit',
            '&:hover': {
                color: 'inherit',
            }
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 200
    },

});

function SimpleDialog(props) {
    const classes = useStyles();
    const {onClose, open, startTest} = props;


    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <div className="p-4 p-sm-5">
                <h2 id="simple-dialog-title" className={classes.title}>Start your aptitude test</h2>
                <div className={classes.note}>
                    <ul className={classes.list}>
                        <li>Takes approximately 15MINS.</li>
                        <li>You have only ONE ATTEMPT to take this test.</li>
                        <li>Please ensure you have a stable internet connection.</li>
                        <li>DO NOT leave test page or close your browser.</li>
                    </ul>
                </div>
                <div className="error-message">
                    <p id={"errMessage"} className="text-danger"></p>
                </div>
                <div className={classes.buttons}>
                    <button onClick={onClose} className={classes.cancel}>Cancel</button>
                    <button className={classes.start} onClick={startTest}>Start Test</button>
                </div>
            </div>
        </Dialog>
    );
}


export default function TestModal(props) {
    const {user, switchComponent} = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const startTest = async (e) => {
        const target = e.target;
        disableButton(target);

        const headers = JSON.parse(sessionStorage.getItem("headers"));
        await axios.post(startTestAPI, {}, {headers: headers})
            .then(res => {
                if (res.status === 200) {
                    switchComponent("test");
                }
            }).catch(err => {
                handleError(err, target)
                //enableButton(target);
            })
    }

    const disableButton = (target) => {
        target.style.background = "#960C0C";
        target.style.borderColor = "#960C0C";
        target.setAttribute("disabled", "disabled");
    }

    const enableButton = (target) => {

        target.style.background = "#FF2E2E";
        target.style.borderColor = "#FF2E2E";
        target.removeAttribute("disabled");
    }

    const handleError = (err, target) => {
        function displayError() {
            eMessage.textContent = ""
        }

        const eMessage = document.querySelector("#errMessage");
        if (err.response === undefined) {
            eMessage.textContent = "Check your network connection and try again";
            setTimeout(displayError, 4000);
            enableButton(target)

            // startTest(e);
        } else {

            switch (err.response.status) {
                case 401:
                case 403:
                    eMessage.textContent = "Session expired please re-login";
                    setTimeout(displayError, 10000);
                    props.userDashCompProps.history.push('/login')


                    break;
                case 400:
                    eMessage.textContent = "Already tested please re-login";
                    setTimeout(displayError, 10000);
                    handleClose()
                    props.userDashCompProps.history.push('/login')

                    break;
                default:
                    break;
            }
        }
    }


    function getTestText() {
        const userStatus = user.applicationStatus;
        if (userStatus === "APPLIED") {
            return "Pending";
        } else if (userStatus === "QUESTIONNAIRE") {
            return "Take Test"
        } else if (userStatus === "TESTING") {
            return "TESTING"
        } else {
            return "COMPLETED"
        }
    }


    return (
        <div>
            <p onClick={handleClickOpen} style={{
                pointerEvents: (user.applicationStatus === "QUESTIONNAIRE") ? "initial" : "none"
            }} className="m-0">
                {getTestText()}
            </p>
            <SimpleDialog open={open} onClose={handleClose} startTest={startTest}/>

        </div>
    );
}

