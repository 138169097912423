import React, {Component} from 'react';
import UserDashBoardHeader from "../UserDashboard/UserDashBoardHeader";
import '../../../css/forms.css'
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {Checkbox} from "@material-ui/core";
import axios from 'axios';
import stamp from "../../../images/stamp.png"
import {submitPaymentAgreementForm} from "../../routes/Api's";

class PaymentAgreementForm extends Component{

        state={
            paymentPlan:'',
            monthlyStartMonth:'',
            quarterlyStartMonth:'',
            paymentStartMonth:'',
            iunderstandTerms:false,
            iagreeToTerms:false
        }

    monthlyStartMonth=`${this.props.user.cohortDetails.cohortStartDate.month}`
    quarterlyStartMonth=`${this.props.user.cohortDetails.cohortStartDate.month}`
    fee =`${this.props.user.cohortDetails.tuitionFee}`.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")


    monthlyPaymentAmount = (`${this.props.user.cohortDetails.tuitionFee}`/12).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    quarterlyPaymentAmount = (`${this.props.user.cohortDetails.tuitionFee}`/4).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")


    handleChange = (event) => {
            this.setState({[event.target.name]:event.target.value})
    };
    componentDidMount() {
        this.setState({...this.state,monthlyStartMonth:this.monthlyStartMonth,quarterlyStartMonth:this.quarterlyStartMonth})
        console.log(this.paymentEndMonth("SEPTEMBER",3))

    }
    paymentEndMonth(startMonth,type){
        let months = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE", "JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER"];
        let month = months.indexOf(startMonth)
        let endMonth = (month + type);
        let oneYearFromNow = new Date();
        let yearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear()+1)
        if(endMonth >= 12){
            endMonth-=12;
            let newEnd = months[endMonth] + " "+oneYearFromNow.getFullYear();
            return newEnd;
        }else{
            let newEnd = months[endMonth] +" "+yearFromNow.getFullYear();
            return newEnd;
        }



    }
    handleCheckBox =e=>{
        this.setState({[e.target.name]:e.target.checked})
    };
    monthDropDown={
        startMonth:["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE",
            "JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER"]
    };
    validateAgreement=()=>{
        const errMessage = document.querySelector("#errMessage");
        const{iagreeToTerms, iunderstandTerms,paymentPlan}=this.state

        if( !iagreeToTerms){
            errMessage.textContent="Please check the terms and condition box"
            return false;

        }else if(!iunderstandTerms){
            errMessage.textContent="Please check the terms and condition box"
            return false;
        }else if(paymentPlan===''){
            errMessage.textContent="Please choose Payment Plan"
            return false;
        }
        else{
            return true;
        }
    };
    convertToPaymentStartMonth=()=>{
        const{monthlyStartMonth,quarterlyStartMonth}= this.state;
        if (monthlyStartMonth !== '') {
            this.state.paymentStartMonth = monthlyStartMonth;
        } else if (quarterlyStartMonth !== '') {
            this.state.paymentStartMonth = quarterlyStartMonth;

        }
    }
    submitAgreementForm=(e)=>{
        e.preventDefault();
        if(this.validateAgreement()) {

            const {
                monthlyStartMonth, quarterlyStartMonth, paymentStartMonth,
                paymentPlan, iagreeToTerms, iunderstandTerms
            } = this.state
            console.log(this.state)
            const headers = JSON.parse(sessionStorage.getItem("headers"));
           // this.convertToPaymentStartMonth();
            let data = {
                paymentPlan: this.state.paymentPlan,
                paymentStartMonth: this.props.user.cohortDetails.cohortStartDate.month,
                iunderstandTerms: this.state.iunderstandTerms,
                iagreeToTerms:this.state.iagreeToTerms,
                tuitionFee: this.props.user.cohortDetails.tuitionFee
            }
            axios.post(submitPaymentAgreementForm, data, {headers: headers}).then(res => {
                console.log(res)
                sessionStorage.setItem("userData", JSON.stringify(res.data))
                this.props.switchComponent('dashboard')
            }).catch(err => {
                console.log(err.response)
            })
        }
}


    render() {
        let user = JSON.parse(sessionStorage.getItem("userData"));
        let imageURL = user.profilePictureUrl;
        let {paymentPlan,monthlyStartMonth,quarterlyStartMonth,iunderstandTerms,iagreeToTerms}=this.state

        return (
            <div>
                <div className="justify-content-center p-4">
                    <div className="container-fluid mb-5">
                        <UserDashBoardHeader user={user} onOpen={this.openNav} imageURL={imageURL}/>
                    </div>
                    <div className="col-md-5 vh-79 personalCon shadow-3 ">
                        <div>
                            <h3 style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "20px",
                                lineHeight: "26px",
                                color: "#960C0C"
                            }} className="text-danger text-center">  TUITION PAYMENT PLAN</h3>
                        </div>
                        <div className="agreementDiv">
                            <div className=''>
                                <p className="agreementText">The purpose of this tuition payment plan is to design a schedule of
                                    installments towards your tuition due to Semicolon. The payment plan
                                    is a privilege, which may be revoked for cause. Under either payment
                                    plans, all payments are due and payable in full upon receipt of notification.<br/><br/>
                                    By signing this tuition payment plan, the STUDENT agrees to pay all fees stated in this plan.</p>
                            </div>
                            <div className="" onSubmit={this.submitAgreementForm}>
                                <div className=''>
                                    <h3 style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "19px",
                                        lineHeight: "24px",
                                        color: "#960C0C"
                                    }} className="text-danger ">Kindly tick your preferred mode of payment in the boxes below</h3>
                                </div>
                                <RadioGroup value={paymentPlan} onChange={this.handleChange}  name="paymentPlan">
                                    <div className="">
                                        <FormControlLabel value='FULL_PAYMENT' control={<Radio/>} label={'Full Payment Plan'}/>
                                        <div className="planText">
                                            <p>STUDENT promises to pay the total fees of {this.fee} only prior to the beginning
                                                of classes at Semicolon Village. This excludes any additional special fees,
                                                miscellaneous charges, or other amounts due.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="">
                                        <FormControlLabel value='MONTHLY' control={<Radio/>} label={' Monthly Payment Plan'}/>
                                        <div className="planText">
                                            <p>STUDENT applies for the Monthly Payment Plan and promises to pay the sum of  {this.monthlyPaymentAmount +" "}
                                                 for each month’s installment in twelve (12) installments from {this.props.user.cohortDetails.cohortStartDate.year} {this.monthlyStartMonth +" "} through { " "+ this.paymentEndMonth(this.monthlyStartMonth,11)}
                                            </p>
                                        </div>
                                        {/*<div className=' w-50 agreementInput planText' >*/}
                                        {/*    <TextField onChange={this.handleChange}*/}
                                        {/*               select label="Start Month"    name='monthlyStartMonth'*/}
                                        {/*               variant="outlined" value={monthlyStartMonth} margin="normal"*/}
                                        {/*               color={"secondary"} className="newField age"*/}
                                        {/*    >*/}
                                        {/*        {this.monthDropDown.startMonth.map((option) => (*/}
                                        {/*            <MenuItem key={option} value={option}>*/}
                                        {/*                {option}*/}
                                        {/*            </MenuItem>*/}
                                        {/*        ))}*/}
                                        {/*</TextField>*/}

                                        {/*</div>*/}
                                    </div>
                                    <div className="">
                                        <FormControlLabel value='QUARTERLY' control={<Radio/>} label={'Quarterly Payment Plan'}/>
                                        <div className="planText">
                                            <p>STUDENT applies for the quarterly payment plan and promises to pay the sum of {this.quarterlyPaymentAmount +" "}
                                                only for each quarter’s installment in four (4) installments from {this.props.user.cohortDetails.cohortStartDate.year} {this.quarterlyStartMonth +" "}, through { " "+ this.paymentEndMonth(this.quarterlyStartMonth,11)}
                                            </p>
                                        </div>
                                        {/*<div className='w-50 agreementInput planText' >*/}
                                        {/*    <TextField onChange={this.handleChange}*/}

                                        {/*               select label="Start Month" name='quarterlyStartMonth'*/}
                                        {/*               variant="outlined" value={quarterlyStartMonth} margin="normal"*/}
                                        {/*               color={"secondary"} className="newField age" required disabled={true}*/}
                                        {/*    >*/}
                                        {/*        {this.monthDropDown.startMonth.map((option) => (*/}
                                        {/*            <MenuItem key={option} value={option}>*/}
                                        {/*                {option}*/}
                                        {/*            </MenuItem>*/}
                                        {/*        ))}*/}

                                        {/*    </TextField>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="">
                                        <FormControlLabel value='CUSTOMIZED' control={<Radio/>} label={' Customized Payment Plan'}/>
                                        <div className="planText">
                                            <p>STUDENT applies for the customized payment plan and promises to make staggered payments during the period of training, until the
                                                total cost of training is paid up.
                                            </p>
                                        </div>
                                    </div>

                                </RadioGroup>
                                <div className=''>
                                    <h3 style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "19px",
                                        lineHeight: "24px",
                                        color: "#960C0C"
                                    }} className="text-danger ">Terms and Conditions</h3>
                                </div>
                                <div className="paymentTerms">
                                    <ul>
                                        <li>
                                            The student understands and agrees that all payment installments will be made on time,
                                            that is, payments will be made on or before the due date established. Your entire monthly
                                            tuition must be paid in full by the last business day of every month.
                                        </li>
                                        <li>
                                            Tuition payments received are first applied against the oldest outstanding balance.
                                        </li>
                                        <li>
                                            Failure to make payments on or before the due date may affect the student’s admission status
                                            in Semicolon. Semicolon may in its discretion terminate the Student’s admission. In the event
                                            of a termination, the student will not be entitled to reimbursement for already paid tuition fees.
                                        </li>

                                    </ul>
                                </div>
                                <div className='d-flex'>
                                    <FormControlLabel className='d-flex'  control={<Checkbox checked={iunderstandTerms} onChange={this.handleCheckBox} name="iunderstandTerms"/> } />
                                    <span className= "paymentCheck d-flex">I have read and understood all the terms and conditions herein.</span>
                                </div>
                                <div className='d-flex'>
                                    <FormControlLabel className='d-flex'  control={<Checkbox checked={iagreeToTerms} onChange={this.handleCheckBox} name="iagreeToTerms"/>} />
                                    <span className= "paymentCheck d-flex"> I agree that the terms and conditions are binding on me.</span>
                                </div>
                                <p id={"errMessage"} className="text-danger"></p>
                                <br/><br/>
                                <div className="stamp-div">
                                    <img alt={"stamp"} src="https://res.cloudinary.com/semicolon-africa/image/upload/v1601575479/emailpng/small_seal_cropped_upejhj.png"/>
                                    {/*<img alt={"stamp"} src={stamp}/>*/}
                                </div>

                                <div className="d-flex mt-5">
                                    <button className="w-15-ns d-block  cancelTerms" onClick={() => this.props.switchComponent('dashboard')}>Go back </button>
                                    <div className="d-flex ">
                                    <button className="w-15-ns   closeterms"  onClick={this.submitAgreementForm}>Submit</button>
                                    </div>
                                    <br/><br/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}export default PaymentAgreementForm