import React from 'react';
import QuestionnaireLogo from '../../../images/QuestionnaireLogo.svg';
import EnrollmentIcon from '../../../images/EnrollmentIcon.svg';
import TestLogo from "../../../images/TestLogo.svg";
import InterviewLogo from "../../../images/InterviewLogo.svg"
import ButtonArrow from "../../../images/ButtonArrow.svg";
import MySlider from './ProgressSlider';
import UserDashboardHook from "../../../hooks/UserDashboardHook";
import TestModal from "./TestModal";
import InterviewInfoModal from "../InterviewInfoModal";
import {withRouter} from "react-router-dom";
import ProgressSliderForAdmitted from "./ProgressSliderForAdmitted";
import PaymentOptionModal from "./PaymentOptionModal";
import PaymentAgreementForm from "../Enrollment/PaymentAgreementForm";
import ProgressSliderForLoanForm from "./ProgressSliderForLoanForm";
import axios from "axios";
import {getLoanDetails} from "../../routes/Api's";

class UserDashboardBody extends UserDashboardHook {

    render() {
        const {
            user,
            routeOrView,
        } = this.props;
        const userStatus = user.applicationStatus;
        let thumbs = Array.from(document.querySelectorAll('.MuiSlider-mark'));
        if (userStatus === 'APPLIED') {
            this.showDone(thumbs, thumbs[0]);
        } else if (userStatus === 'QUESTIONNAIRE') {
            this.showDone(thumbs, thumbs[1]);
        } else if (userStatus === 'TESTED' || userStatus === '' || userStatus === 'INTERVIEWING' || userStatus === 'INVITED_FOR_INTERVIEW' || userStatus === 'INTERVIEWED' || userStatus === 'OFFERED_ADMISSION' || userStatus === 'ACCEPTED_INTERVIEW') {
            this.showDone(thumbs, thumbs[2]);
        }  else if ((userStatus === "ACCEPTED_ADMISSION" && user.nativeState === "PAYMENT_AGREEMENT") || userStatus === 'ENROLLED' || user.nativeState === 'CHECKED_IN') {
            this.showDone(thumbs, thumbs[5])
        } else if (userStatus === 'ACCEPTED_ADMISSION' && (user.nativeState === 'ENROLLMENT_FORM' || user.nativeState === 'PAID_TUITION')) {
            this.showDone(thumbs, thumbs[4])
        } else if(userStatus === 'ACCEPTED_ADMISSION') {
            this.showDone(thumbs, thumbs[3])
        } else if (user.nativeState === 'PAYMENT_AGREEMENT' || (userStatus === 'NATIVE' && !user.canFillTenancyAgreementForm)) {
            this.showDone(thumbs, thumbs[6])
        } else if (user.nativeState === 'NATIVE_AGREEMENT' || user.nativeState === 'TENANCY_AGREEMENT') this.showDone(thumbs, thumbs[7])
        else if (user.nativeState === 'NATIVE') {
            if (user.canFillTenancyAgreementForm) {
                this.showDone(thumbs, thumbs[7])
            }
            if (user.canFillLoanAgreement) {
                this.showDone(thumbs, thumbs[8])
            }
        }

        return (
            <div onLoad={this.onloadData} className="row h-100 user-left m-0">
                <div className="horizontal-divider"></div>
                <div className="col-md-12 d-flex justify-content-center dashboard-content-scrollable">
                    <div className="progressBar h-100">
                        {this.getSlider()}
                    </div>
                    <div className="row dashboard-content">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 greeting mb-5">
                                    <h3 className="mb-0">Welcome back, {user.firstName}</h3>
                                </div>
                            </div>

                            <div className="row-md-4 questionnaire-row d-flex">
                                <div className="questionnaire-icon">
                                    <div className="icon">
                                        <img src={QuestionnaireLogo} alt="logo" width="25px" height="25px"/>
                                    </div>
                                </div>
                                <div className="text-and-btn d-flex justify-content-between align-items-center">
                                    <div className="text text-nowrap">
                                        <div className="steps-and-time d-flex justify-content-between">
                                            <p className="steps">STEP 1</p> <span>.</span> <p>10MINS <span
                                            className="apx"><em>(APPROX.)</em></span></p>
                                        </div>
                                        <h6>Let’s get to know you</h6>
                                    </div>
                                </div>
                                <div className="dashboard-btn">
                                    <span className="mb-0" onClick={routeOrView}>
                                        <button style={this.btnStyles("QUESTIONNAIRE")}
                                                className="questionnaire-btn  m-0 px-3">
                                            {userStatus === "APPLIED" ? "Fill Form" : "VIEW"} <span
                                            className="btn-arrow">{(userStatus === 'APPLIED') ?
                                            <img src={ButtonArrow} alt="..."/> : ''}</span>
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <div className="row-md-4 test-row d-flex">
                                <div className="test-icon">
                                    <div className="icon">
                                        <img src={TestLogo} alt="logo" width="25px" height="25px"/>
                                    </div>
                                </div>
                                <div className="text-and-btn d-flex justify-content-between align-items-center">
                                    <div className="text text-nowrap">
                                        <div className="steps-and-time d-flex justify-content-between">
                                            <p className="steps">STEP 2</p> <span>.</span> <p>10MINS <span
                                            className="apx"><em>(APPROX.)</em></span></p>
                                        </div>

                                        <h6>Show us what you got</h6>

                                    </div>
                                </div>
                                <div className="dashboard-btn">
                                    <button style={this.btnStyles("TEST")}
                                            className="questionnaire-btn d-flex align-items-center justify-content-center  m-0">
                                        <TestModal switchComponent={this.props.switchComponent} user={user}
                                                   userDashCompProps={this.props}/>
                                        <span
                                            className="btn-arrow">{(userStatus === 'QUESTIONNAIRE') ?
                                            <img src={ButtonArrow} alt="..."/> : ''}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="row-md-4 interview-row d-flex">
                                <div className="interview-icon">
                                    <div className="icon">
                                        <img src={InterviewLogo} alt="logo" width="25px" height="25px"/>
                                    </div>
                                </div>
                                <div className="text-and-btn d-flex justify-content-between align-items-center">
                                    <div className="text text-nowrap">
                                        <div className="steps-and-time">
                                            <p className="steps">STEP 3</p>
                                        </div>
                                        <h6>{this.getInterviewText()}</h6>
                                    </div>
                                </div>
                                <div className="dashboard-btn">
                                    <button
                                        style={this.btnStyles("INTERVIEW")}
                                        className="questionnaire-btn m-0 py-1 text-nowrap">
                                        <InterviewInfoModal user={user} interviewObject={this.state.interviewObject}/>
                                    </button>
                                </div>
                            </div>
                            {this.getDisplayForOfferedAdmission()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getDisplayForOfferedAdmission() {
        const {user} = this.props

        function getEnrollmentButtonText() {
            const { nativeState, canFillEnrollmentForm, applicationStatus: userStatus } = user
            if ((nativeState === "ACCEPTED_ADMISSION" || nativeState === 'PAID_TUITION') && (!canFillEnrollmentForm) || (userStatus === 'ACCEPTED_ADMISSION' && nativeState === 'ACCEPTED_ADMISSION')) {
                return 'Pending'
            } else if (nativeState === 'PAID_TUITION' && canFillEnrollmentForm) {
                return 'Fill Form'
            } else if (nativeState === 'ENROLLMENT_FORM' || nativeState === 'PAYMENT_AGREEMENT' || nativeState === 'CHECKED_IN' || userStatus === 'NATIVE' || nativeState === 'NATIVE_AGREEMENT' || nativeState === 'TENANCY_AGREEMENT') {
                return 'COMPLETED'
            }
        }

        function getPaymentAgreementFormText() {
            const { nativeState, applicationStatus: userStatus } = user
            if (nativeState === "ACCEPTED_ADMISSION" || nativeState === "PAID_TUITION" || nativeState === 'ENROLLMENT_FORM') return "Pending"
            if (nativeState === "CHECKED_IN") return "Fill form"
            if (nativeState === "PAYMENT_AGREEMENT" || userStatus === 'NATIVE' || nativeState === 'NATIVE_AGREEMENT' || nativeState === 'TENANCY_AGREEMENT') return "COMPLETED"
        }

        function getNativeFormButtonText() {
            const { nativeState, applicationStatus: userStatus } = user
            if (nativeState === "PAYMENT_AGREEMENT") return "Fill form"
            else if (nativeState === 'NATIVE_AGREEMENT' || userStatus === 'NATIVE' || nativeState === 'TENANCY_AGREEMENT') return "COMPLETED"
            else if (nativeState !== "PAYMENT_AGREEMENT")
                return "Pending"
        }

        function getTenancyFormButtonText() {
            const {nativeState, canFillTenancyAgreementForm} = user
            if (!canFillTenancyAgreementForm) return "Disabled"
            else if ((nativeState === 'CHECKED_IN' || nativeState === 'PAYMENT_AGREEMENT' || nativeState === 'ENROLLMENT_FORM') && canFillTenancyAgreementForm) return "Pending"
            else if (nativeState === 'NATIVE_AGREEMENT' && canFillTenancyAgreementForm) return "Fill form"
            else if ((nativeState === 'NATIVE' && canFillTenancyAgreementForm) || nativeState === 'TENANCY_AGREEMENT') return "COMPLETED"
        }

        if (user.nativeState !== 'PENDING')
            return (
                <React.Fragment>
                    <div className="row-md-4 interview-row d-flex">
                        <div className="payment-icon">
                            <div className="icon">
                                <img src={TestLogo} alt="logo" width="25px" height="25px"/>
                            </div>
                        </div>
                        <div className="text-and-btn d-flex justify-content-between align-items-center">
                            <div className="text text-nowrap">
                                <div className="steps-and-time">
                                    <p className="steps">STEP 4</p>
                                </div>
                                <h6>Kindly upload proof of payment</h6>
                            </div>
                        </div>
                        <div className="dashboard-btn">
                            <button
                                style={this.btnStyles("PAYMENT")}
                                className="questionnaire-btn m-0 py-1 text-nowrap">
                                <PaymentOptionModal/>
                            </button>
                        </div>
                    </div>
                    <div className="row-md-4 interview-row d-flex">
                        <div className="enrollment-icon">
                            <div className="icon">
                                <img src={EnrollmentIcon} alt="logo" width="25px" height="25px"/>
                            </div>
                        </div>
                        <div className="text-and-btn d-flex justify-content-between align-items-center">
                            <div className="text text-nowrap">
                                <div className="steps-and-time">
                                    <p className="steps">STEP 5</p>
                                </div>
                                <h6>Fill the enrollment form</h6>
                            </div>
                        </div>
                        <div className="dashboard-btn">
                            <button
                                onClick={() => this.props.switchComponent('enrollmentForm')}
                                style={this.btnStyles("ENROLLMENT")}
                                className="questionnaire-btn m-0 py-1 px-3 text-nowrap">
                                {getEnrollmentButtonText()}
                            </button>
                        </div>
                    </div>
                    <div className="row-md-4 interview-row d-flex">
                        <div className="interview-icon">
                            <div className="icon">
                                <img src={InterviewLogo} alt="logo" width="25px" height="25px"/>
                            </div>
                        </div>
                        <div className="text-and-btn d-flex justify-content-between align-items-center">
                            <div className="text text-nowrap">
                                <div className="steps-and-time">
                                    <p className="steps">STEP 6</p>
                                </div>
                                <h6>Payment Agreement Form</h6>
                            </div>
                        </div>
                        <div className="dashboard-btn">
                            <button
                                style={this.btnStyles("PAYMENT_AGREEMENT")}
                                onClick={() => {
                                    this.props.switchComponent('paymentAgreementForm')
                                }}
                                className="questionnaire-btn m-0 py-1 px-3 text-nowrap">
                                {getPaymentAgreementFormText()}
                            </button>
                        </div>
                    </div>
                    <div className="row-md-4 interview-row d-flex">
                        <div className="enrollment-icon">
                            <div className="icon">
                                <img src={EnrollmentIcon} alt="logo" width="25px" height="25px"/>
                            </div>
                        </div>
                        <div className="text-and-btn d-flex justify-content-between align-items-center">
                            <div className="text text-nowrap">
                                <div className="steps-and-time">
                                    <p className="steps">STEP 7</p>
                                </div>
                                <h6>Fill native form</h6>
                            </div>
                        </div>
                        <div className="dashboard-btn">
                            <button
                                onClick={() => this.props.switchComponent('nativeForm')}
                                style={this.btnStyles("NATIVE_FORM")}
                                className="questionnaire-btn m-0 py-1 px-3 text-nowrap">
                                {getNativeFormButtonText()}
                            </button>
                        </div>
                    </div>
                    <div className="row-md-4 pb-5">
                        <div className="row-md-4 interview-row d-flex">
                            <div className="enrollment-icon">
                                <div className="icon">
                                    <img src={EnrollmentIcon} alt="logo" width="25px" height="25px"/>
                                </div>
                            </div>
                            <div className="text-and-btn d-flex justify-content-between align-items-center">
                                <div className="text text-nowrap">
                                    <div className="steps-and-time">
                                        <p className="steps">STEP 8</p>
                                    </div>
                                    <h6>Fill tenancy agreement form</h6>
                                </div>
                            </div>
                            <div className="dashboard-btn">
                                <button
                                    onClick={user.canFillTenancyAgreementForm ? () => this.props.switchComponent('tenancyForm') : () => {}}
                                    style={this.btnStyles("TENANCY")}
                                    className="questionnaire-btn m-0 py-1 px-3 text-nowrap">
                                    {getTenancyFormButtonText()}
                                </button>
                            </div>
                        </div>
                        {this.showLoan()}
                    </div>
                </React.Fragment>
            )
    }

    getSlider() {
        const {user} = this.props
        // const canFillForm = () => user.canFillLoanAgreement
        if (user.nativeState !== 'PENDING') {
            if (user.canFillLoanAgreement) {
                return <ProgressSliderForLoanForm user={user}/>
            } else return (
                <ProgressSliderForAdmitted user={user}/>
            )
        } else {
            return (
                <MySlider user={user}/>
            )
        }
    }

    showLoan() {
        const {user} = this.props
        function getLoanFormButtonText() {
            const {nativeState, canFillLoanAgreement} = user
            if (!canFillLoanAgreement) return "Disabled"
            else if ((nativeState === 'CHECKED_IN' || nativeState === 'PAYMENT_AGREEMENT' || nativeState === 'ENROLLMENT_FORM') && canFillLoanAgreement) return "Pending"
            else if ((nativeState === 'NATIVE_AGREEMENT' && canFillLoanAgreement) || (nativeState === 'TENANCY_AGREEMENT' && canFillLoanAgreement)) return "Fill form"
            else if (nativeState === 'NATIVE' && canFillLoanAgreement || nativeState === 'LEARN_FUND') return "COMPLETED"
        }
        let loading = false
        const fetchAgreement = () => {
            loading = true
            axios.get(getLoanDetails, {headers: this.state.headers}).then(res => {
                loading = false
                sessionStorage.setItem('loanDetails', JSON.stringify(res.data))
                this.props.switchComponent('loanForm')
            }).catch(e => {
                console.log(e, e.response)
                loading = false
            })
        }
        if (user.canFillLoanAgreement) return (
            <div className="row-md-4 pb-5">
                <div className="row-md-4 interview-row d-flex">
                    <div className="enrollment-icon">
                        <div className="icon">
                            <img src={EnrollmentIcon} alt="logo" width="25px" height="25px"/>
                        </div>
                    </div>
                    <div className="text-and-btn d-flex justify-content-between align-items-center">
                        <div className="text text-nowrap">
                            <div className="steps-and-time">
                                <p className="steps">STEP 9</p>
                            </div>
                            <h6>Fill loan agreement form</h6>
                        </div>
                    </div>
                    <div className="dashboard-btn">
                        <button
                            disabled={loading}
                            onClick={user.canFillLoanAgreement ? () => fetchAgreement() : () => {}}
                            style={this.btnStyles("LOAN")}
                            className="questionnaire-btn m-0 py-1 px-3 text-nowrap">
                            {getLoanFormButtonText()}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(UserDashboardBody);