import React, {Component} from 'react';
import UserDashboard from "./UserDashboard/UserDashboard";
import Forms from "./forms/newForms/Forms";
import Test from "./test/Test";
import FormSubmitted from "./SplashScreens/FormSubmitted";

import {submitAnyPreviousTest} from "../../hooks/FunctionalHooks";

import axios from "axios";
import {
    allFormDataAPI,
    getAllAvailableInfoSessionList,
    getInterviewObject,
    getInterviewSchedule,
} from "../routes/Api's";
import UserEnrollmentForm from "./Enrollment/UserEnrollmentForm";
import PaymentAgreementForm from "./Enrollment/PaymentAgreementForm";
import TenancyForm from "./Enrollment/TenancyForm";
import NativeForm from "./Enrollment/NativeForm";
import LoanAgreementForm from "./Enrollment/LoanAgreementForm";

export default class DisplayManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDashboard: true,
            showForm: false,
            showTest: false,
            showFormSplash: false,
            showEnrollmentForm: false,
            showPaymentAgreementForm: false,
            showTenancyForm: false,
            showNativeForm: false,
            showLoanForm: false,
            user: {},
            headers: "",
        };
        this.state.user = JSON.parse(sessionStorage.getItem("userData"));
        this.state.headers = JSON.parse(sessionStorage.getItem("headers"));
    }


    componentDidMount() {
        submitAnyPreviousTest();
        const {user, headers} = this.state;

        const applicantStatus = user.applicationStatus;

        this.loadAvailableInfoSessions();



        if (applicantStatus === "APPLIED") {
            axios.get(allFormDataAPI).then(res => {
                sessionStorage.setItem('formData', JSON.stringify(res));
            });
        }

        if (user.hasOwnProperty("applicantQuestionnaire"))
            this.setState({questionnaireDetails: user.applicantQuestionnaire});

        if (user.hasOwnProperty("infoSessionId")) {
            this.setState({
                infoSessionId: user.infoSessionId,
            })
        }

        if (applicantStatus !== "APPLIED" && applicantStatus !== "QUESTIONNAIRE" && applicantStatus !== "TESTED" && applicantStatus !== "" && applicantStatus !== undefined) {

            if(applicantStatus==="INVITED_FOR_INTERVIEW"){
                axios(getInterviewObject(user.email), {headers: headers})
                    .then(res => {
                        this.setState({interviewObject: res.data})

                    })
                    .catch(err => this.handleError(err))
            }
            else{

            axios(getInterviewSchedule(user.email), {headers: headers})
                .then(res => {
                    this.setState({interviewObject: res.data})

                })
                .catch(err => this.handleError(err))
        }
    }
    }

    loadAvailableInfoSessions = () => {

        const headers = JSON.parse(sessionStorage.getItem("headers"));
        axios(getAllAvailableInfoSessionList, {headers: headers})
            .then(res => {
                this.setState({infoSessionList: res.data});
            })
            .catch(err => {
                    this.handleError(err)
                }
            );


    }

    handleError = (err) => {


        switch (err.status) {
            case 401:
            case 403:
                this.props.history.push("/login")
                break;
            case 400:

                break;
            default:
                break;
        }
    }

    switchDisplay = (component) => {
        switch (component) {
            case "dashboard":
                this.setState({
                    ...this.state,
                    showDashboard: true,
                    showForm: false,
                    showTest: false,
                    showFormSplash: false,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: false,
                    showTenancyForm: false,
                    showNativeForm: false,
                    showLoanForm: false,
                })
                break;
            case "form":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: true,
                    showTest: false,
                    showFormSplash: false,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: false,
                    showTenancyForm: false,
                    showNativeForm: false,
                    showLoanForm: false,
                })
                break;
            case "test":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: false,
                    showTest: true,
                    showFormSplash: false,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: false,
                    showTenancyForm: false,
                    showNativeForm: false,
                    showLoanForm: false,
                })
                break;
            case "formSplash":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: false,
                    showTest: false,
                    showFormSplash: true,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: false,
                    showTenancyForm: false,
                    showNativeForm: false,
                    showLoanForm: false,
                })
                break;
            case "enrollmentForm":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: false,
                    showTest: false,
                    showFormSplash: false,
                    showEnrollmentForm: true,
                    showPaymentAgreementForm: false,
                    showTenancyForm: false,
                    showNativeForm: false,
                    showLoanForm: false,
                })
                break
            case "paymentAgreementForm":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: false,
                    showTest: false,
                    showFormSplash: false,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: true,
                    showTenancyForm: false,
                    showNativeForm: false,
                    showLoanForm: false,
                })
                break
            case "tenancyForm":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: false,
                    showTest: false,
                    showFormSplash: false,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: false,
                    showTenancyForm: true,
                    showNativeForm: false,
                    showLoanForm: false,
                })
                break;
            case "nativeForm":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: false,
                    showTest: false,
                    showFormSplash: false,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: false,
                    showTenancyForm: false,
                    showNativeForm: true,
                    showLoanForm: false,
                })
                break
            case "loanForm":
                this.setState({
                    ...this.state,
                    showDashboard: false,
                    showForm: false,
                    showTest: false,
                    showFormSplash: false,
                    showEnrollmentForm: false,
                    showPaymentAgreementForm: false,
                    showTenancyForm: false,
                    showNativeForm: false,
                    showLoanForm: true
                })
                break
            default:
                break;
        }
    }


    displayComponent = () => {
        const {user, showDashboard, showForm, showTest, showFormSplash, showEnrollmentForm, showPaymentAgreementForm, showTenancyForm, showNativeForm, showLoanForm} = this.state;

        if (showDashboard) {
            return <UserDashboard switchComponent={this.switchDisplay}/>
        }
        if (showForm) {
            return <Forms history={this.props.history} switchComponent={this.switchDisplay}/>
        }
        if (showTest) {
            return <Test history={this.props.history}/>
        }
        if (showFormSplash) {
            return <FormSubmitted switchComponent={this.switchDisplay} userDashCompProps={this.props}/>
        }
        if (showEnrollmentForm) {
            return <UserEnrollmentForm switchComponent={this.switchDisplay} user={this.state.user} />
        }
        if (showPaymentAgreementForm) return <PaymentAgreementForm user={this.state.user} switchComponent={this.switchDisplay} />
        if (showTenancyForm) return <TenancyForm user={user} switchComponent={this.switchDisplay}/>
        if (showNativeForm) return <NativeForm user={user} switchComponent={this.switchDisplay}/>
        if (showLoanForm) return <LoanAgreementForm user={user} switchComponent={this.switchDisplay}/>
    }

    render() {
        return this.displayComponent();
    }

}

 // DisplayManager;